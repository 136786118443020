import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../API';
import { BookItem, Button, HeaderScroll, ImageFallback, LoadingHeader } from '../component';

var BoxsetAdd = props=>{
	var { colors, dark } = useTheme();
	var { boxset_id, image, series_name, boxset_number, boxset_part } = (props.route && props.route.params) ? props.route.params : {};
	var [disabledBooks, setDisabledBooks] = useState([]);
	var [boxset, setBoxset] = useState(null);
	var [added, setAdded] = useState(false);
	var [sending, setSending] = useState(false);

	var styles = StyleSheet.create({
		containerMeta: {
			fontSize: 20,
			color: 'gray',
			textAlign: 'center',
			marginTop: -10
		},
		cover: {
			width: 200,
			height: 300,
			backgroundColor: dark ? colors.card : colors.border,
			alignSelf: 'center',
			borderRadius: 9,
			marginBottom: 10,
			marginTop: 20,
			resizeMode: 'contain'
		},
		footer: {
			backgroundColor: colors.card,
			flexDirection: 'row', 
			alignItems: 'center',
			paddingHorizontal: 15,
			paddingTop: 10,
			paddingBottom: 5,
		},
		volumeCount: {
			color: colors.text,
			fontSize: 20,
			fontWeight: 'bold',
			flex: 1,
		},
		confirmButton: {
			width: 160,
		},
		header: {
			fontWeight: 'bold',
			color: colors.text,
			fontSize: 32,
			textAlign: 'center',
			marginTop: 10,
		},
		disabledText: {
			fontSize: 20,
			color: colors.text,
			fontWeight: 'bold',
			textAlign: 'center',
			padding: 18,
			backgroundColor: colors.card
		},
	})

	useEffect(()=>{
		API.getBoxset(boxset_id, true, true).then(res=>{
			if(res.error) return Alert.alert('Error', res.message_client);
			setBoxset(res.data);
			setDisabledBooks(res.data.volumes.filter(a=>a.owned).map(a=>a.manga_id));
		}).catch(err=>{
			return Alert.alert('Error', 'Unexpected error loading boxset (LCL-1)');
		})
	}, []);

	var disableBook = (book)=>{
		return ()=>{
			if(sending || book.owned) return;
			var db = [...disabledBooks];
			var dix = db.findIndex(a=>a==book.manga_id);
			if(dix==-1){
				db.push(book.manga_id);
			}else{
				db.splice(dix, 1);
			}
			setDisabledBooks(db);
		}
	}

	var addBoxset = ()=>{
		if(sending) return;
		var text = 'You\'re about to add this boxset'
		var owned = boxset.volumes.filter(a=>a.owned).length;
		if((disabledBooks.length-owned)>0){
			text += ' with some volumes disabled.';
		}else{
			text += ' to your manga library.'
		}
		Alert.alert('Add Boxset', text, [
			{ text: 'Cancel', style: 'cancel' },
			{ text: 'Add Boxset', style: 'default', onPress: doAddBoxset}
		])
	}

	var doAddBoxset = ()=>{
		setSending(true);
		API.addBoxset(boxset_id, disabledBooks).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			setAdded(true);
		}).catch(err=>{
			setSending(false);
			return Alert.alert('Error', 'Unexpected error adding boxset to your library (LCL-1)');
		})
	}

	var ownedVolumes = boxset ? boxset.volumes.filter(a=>a.owned) : [];
	var validVolumes = !boxset ? [] : boxset.volumes.filter(a=>!a.owned && disabledBooks.indexOf(a.manga_id)==-1);

	return (
		<View style={StyleSheet.absoluteFill}>
			<HeaderScroll
				headerText='Add Boxset'
				containerText={series_name}
				containerTextStyle={{ textAlign: 'center' }}
				bottomColor={boxset ? colors.card : null}
				containerTextMeta={'Boxset'}
				footer={!boxset ? null : (
					ownedVolumes.length==boxset.volumes.length ? (
						<Text style={styles.disabledText}>You already own this boxset!</Text>
					) : added ? (
						<Text style={styles.disabledText}>Boxset added!</Text>
					) : validVolumes.length>0 ? (
						<View style={styles.footer}>
							<Text style={styles.volumeCount}>
								{validVolumes.length}
								<Text style={{ color: dark ? '#666' : '#999', fontSize: 16 }}>{disabledBooks.length>0 ? ` / ${boxset.volumes.length} ` : ' '}</Text>
								Volume{(validVolumes.length==1 ? '' : 's')}
							</Text>
							<Button text={disabledBooks.length>0 ? `Add ${validVolumes.length} Volume${validVolumes.length==1 ? '' : 's'}` : 'Add Boxset'} onPress={addBoxset} style={styles.confirmButton} loading={sending} />
						</View>
					) : (
						<Text style={styles.disabledText}>No volumes selected</Text>
					)
				)}
			>
				{boxset_number || boxset_part ? (
					<Text style={styles.containerMeta}>
						Boxset {boxset_number || ''}
						{boxset_part ? ` Part ${boxset_part}` : ''}
					</Text>
				) : null}
				<ImageFallback url={`${image}/original.jpg`} style={styles.cover} fallbackText={series_name} fallbackTextStyle={{ fontSize: 20, paddingHorizontal: 10 }} />
				{boxset ? (
					<View>
						<Text style={styles.header}>Volumes</Text>
						<Text style={[styles.containerMeta, { marginTop: 0 }]}>{boxset.volumes.length} Books</Text>
						{boxset.volumes && boxset.volumes.length>0 ? boxset.volumes.sort((a,b)=>a.volume_start-b.volume_start).map(a=>{
							var selected = disabledBooks.findIndex(b=>b==a.manga_id)>-1;
							return (
								<BookItem
									series
									onPress={disableBook(a)}
									grayed={selected || !!a.owned}
									grayedText={selected || !!a.owned}
									book={{
										name: boxset.series_name,
										image: a.image,
										volume_start: a.volume_start,
										volume_end: a.volume_end,
										metadata: [
											a.owned ? 'Already owned' : (selected ? 'Not adding' : null)
										],
									}} 
									metadataStyle={{ color: a.owned ? 'gray' : '#DE3A12' }}
									key={`book-${a.manga_id}`} 
								/>
							)
						}) : (
							<LoadingHeader loading={false} icon="sad-tear" text='This boxset has no volumes' />
						)}
					</View>
				) : (
					<LoadingHeader text='Loading boxset' />
				)}
			</HeaderScroll>
		</View>
	)
}

export default BoxsetAdd;