import React, { useState } from 'react';
import { StyleSheet, ScrollView, Text, View, Animated, FlatList, KeyboardAvoidingView, Platform } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view'
import PropTypes from 'prop-types';
import { useTheme, useNavigation } from '@react-navigation/native';
import { HeaderBackButton } from '@react-navigation/elements'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

const HeaderScroll = props=>{
	var { colors, dark } = useTheme()
	var navigation = useNavigation();
	var scrollMax = props.scrollMax || 30+(props.containerTextMeta ? 20 : 0);
	var scrollMin = (props.scrollMin || 0)+(props.containerTextMeta ? 20 : 0);
	var [scrollY] = useState(new Animated.Value(0));
	var insets = useSafeAreaInsets();
	
	var headerColor = scrollY.interpolate({
		inputRange: [scrollMin, scrollMax],
		extrapolate: 'clamp',
		outputRange: [colors.background, colors.card]
	});

	var navState = navigation.getState();
	var headerBack = !props.root && navigation && navState.type=='stack' && navState.routes.length>1 && navigation.canGoBack();
	var scrollEvent = Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } }}], {useNativeDriver : false });

	var navigationBack = ()=>{
		if(!headerBack) return
		navigation.goBack();
	}

	const styles = StyleSheet.create({
		scrollStyle: {
			width: '100%',
			paddingHorizontal: 10,
			paddingBottom: insets.bottom,
			maxWidth: 600,
			alignSelf: 'center',
		},
		containerText: {
			color: colors.text,
			fontSize: 32,
			fontWeight: 'bold',
			// marginBottom: 10,
		},
		header: {
			minHeight: props.modal ? 55 : 45,
			marginTop: 0,
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: headerColor,
			borderBottomColor: scrollY.interpolate({
				inputRange: [scrollMin, scrollMax],
				extrapolate: 'clamp',
				outputRange: [colors.background, colors.border]
			}),
			borderBottomWidth: StyleSheet.hairlineWidth
		},
		headerText: {
			color: scrollY.interpolate({
				inputRange: [scrollMin+15, scrollMax],
				extrapolate: 'clamp',
				outputRange: ['rgba(0, 0, 0, 0)', colors.text]
			}),
			transform: [{
				translateY: scrollY.interpolate({
					inputRange: [scrollMin+12, scrollMax],
					outputRange: [10, 0],
					extrapolate: 'clamp'
				})
			}],
			fontSize: 16,
			alignSelf: 'center',
			fontWeight: '600'
		},
		headerAnimated: {
			flex: 1,
			transform: props.headerShift!==false ? [{
				translateY: scrollY.interpolate({
					inputRange: [0, 120],
					outputRange: [10, 0],
					extrapolate: 'clamp'
				})
			}] : null
		},
		sideHeader: {
			width: headerBack ? 70 : (props.headerWidth || 50),
			height: 35,
			marginHorizontal: 5,
			marginVertical: 0,
		},
		containerTextMeta: {
			color: 'gray',
			fontSize: 18,
			marginBottom: 0,
			textAlign: 'center',
		}
	});

	return (
		<View style={[{ flex: 1 }, props.style]}>
			<SafeAreaView style={{ backgroundColor: headerColor }} />
			<Animated.View style={styles.header}>
				{headerBack ? (
					<HeaderBackButton onPress={navigationBack} labelVisible={true} label='Back' tintColor={colors.primary} style={styles.sideHeader} />
				) : props.headerLeft===null ? null : (
					<View style={[styles.sideHeader, {  }]}>
						{props.headerLeft}
					</View>
				)}
				<View style={{ flex: 1 }}>
					{props.header ? (
						props.headerAlwaysShown!==false ? typeof props.header === 'function' ? props.header(scrollY, scrollMin, scrollMax) : props.header : (
							<Animated.View style={styles.headerAnimated}>
								{typeof props.header === 'function' ? props.header(scrollY, scrollMin, scrollMax) : props.header}
							</Animated.View>
						)
					) : (
						<Animated.Text style={[styles.headerText, props.headerTextStyle]} numberOfLines={1}>{props.headerText || props.containerText}</Animated.Text>
					)}
				</View>
				{props.headerRight===null ? null : (
					<View style={[styles.sideHeader, { alignItems: 'flex-end' }]}>
						{props.headerRight}
					</View>
				)}
			</Animated.View>
			<KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS=='android' ? 'height' : 'padding'}>
				{props.flatListItems && props.flatListRenderItem && props.flatListKeys ? (
					<FlatList
						contentContainerStyle={[styles.scrollStyle, { height: 'auto' }]}
						refreshControl={props.refreshControl}
						onScroll={scrollEvent}
						scrollEventThrottle={15}
						data={props.flatListItems}
						renderItem={props.flatListRenderItem}
						keyExtractor={props.flatListKeys}
						keyboardDismissMode={props.keyboardDismissMode || 'on-drag'}
						scrollIndicatorInsets={{ right: 1 }}
						{...props.flatListProps}
						stickyHeaderIndices={props.stickyIndices}
						ListFooterComponent={<View style={{ marginBottom: 20 }}>
							{props.flatListFooter}
						</View>}
						ListHeaderComponent={
							<View>
								{props.containerTextShown!==false ? (
									<Text style={[styles.containerText, { marginBottom: 0 }, props.containerTextStyle]}>{props.containerText || props.headerText}</Text>
								) : null}
								{props.flatListHeader}
							</View>
						}
					/>
				) : props.scroll!==false ? (
					<ScrollView 
						contentContainerStyle={[styles.scrollStyle, props.scrollStyle]} 
						refreshControl={props.refreshControl}
						onScroll={scrollEvent}
						scrollEventThrottle={15}
						scrollIndicatorInsets={{ right: 1 }}
						stickyHeaderIndices={props.stickyIndices}
						keyboardDismissMode={props.keyboardDismissMode || 'on-drag'}
					>
						{props.containerTextMeta ? (
							<Text style={styles.containerTextMeta}>{props.containerTextMeta}</Text>
						) : null}
						{props.containerTextShown!==false ? (
							<Text style={[styles.containerText, props.containerTextStyle]}>{props.containerText || props.headerText}</Text>
						) : null}
						{props.children}
						{Platform.OS=='android' ? (
							<View style={{ height: 20 }} />
						) : null}
					</ScrollView>
				) : (
					<View style={{ flex: 1 }}>
						{props.containerTextShown!==false ? (
							<Text style={[styles.containerText, props.containerTextStyle]}>{props.containerText || props.headerText}</Text>
						) : null}
						{props.children}
					</View>
				)}
			</KeyboardAvoidingView>
			{props.footer}
			{props.bottomColor ? (
				<SafeAreaView style={{ backgroundColor: props.bottomColor }} />
			) : null}
		</View>
	)
}

HeaderScroll.propTypes = {
	style: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	headerLeft: PropTypes.any,
	header: PropTypes.any,
	headerAlwaysShown: PropTypes.bool,
	headerRight: PropTypes.any,
	headerText: PropTypes.string,
	headerTextStyle: PropTypes.object,
	headerTextShown: PropTypes.bool,
	headerShift: PropTypes.bool,
	containerTextMeta: PropTypes.string,
	containerText: PropTypes.string,
	containerTextStyle: PropTypes.object,
	containerTextShown: PropTypes.bool,
	refreshControl: PropTypes.element,
	scrollMin: PropTypes.number,
	scrollMax: PropTypes.number,
	onScroll: PropTypes.any,
	onScroll: PropTypes.func,
	flatListHeader: PropTypes.element,
	flatListItems: PropTypes.array,
	flatListRenderItem: PropTypes.func,
	flatListProps: PropTypes.object,
	flatListKeys: PropTypes.func,
	flatListFooter: PropTypes.element,
	stickyIndices: PropTypes.array,
	scroll: PropTypes.bool,
	scrollStyle: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	root: PropTypes.bool,
	bottomColor: PropTypes.string,
	footer: PropTypes.element
}

export default HeaderScroll;
