import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { HeaderScroll, Button } from '../component';
import Markdown from 'react-native-markdown-display';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';

var Changelog = props=>{
	var { colors, dark } = useTheme();
	var { bottom } = useSafeAreaInsets();
	var { title, contents, version, onClose } = props.route.params;

	var styles = StyleSheet.create({
		subtitle: {
			color: 'gray',
			textAlign: 'center',
			marginBottom: 10
		},
		footer: {
			height: 65+bottom,
			padding: 10,
			backgroundColor: colors.card
		}
	});

	useEffect(()=>{
		const unsubscribe = props.navigation.addListener('beforeRemove', () => {
			if(onClose) onClose();
		});
		return unsubscribe;
	})

	return (
		<SafeAreaView style={{ flex: 1 }} edges={['left', 'right']}>
			<HeaderScroll
				headerText={title}
				style={{ flex: 1 }}
				root
				containerTextStyle={{ textAlign: 'center', marginBottom: 0 }}
				scrollStyle={{
					paddingHorizontal: 0
				}}
			>
				{version ? <Text style={styles.subtitle}>Version {version}</Text> : null}
				<Markdown style={{
					body: {
						color: colors.text,
						fontSize: 16,
						paddingHorizontal: 15,
					},
					heading1: {
						marginTop: 20,
						fontWeight: 'bold'
					},
					heading2: {
						marginTop: 20,
						fontWeight: 'bold'
					},
					heading3: {
						marginTop: 10,
						fontWeight: 'bold'
					},
					code_block: {
						backgroundColor: colors.card,
						borderWidth: 0,
					},
					image: {
						// width: '100%',
						// height: 200,
						// backgroundColor: 'red',
						// alignItems: 'center',
					}
				}}>
					{contents}
				</Markdown>
			</HeaderScroll>
			<View style={styles.footer}>
				<Button text="Close" onPress={props.navigation.goBack} />
			</View>
		</SafeAreaView>
	)
}

export default Changelog;