import React from 'react';
import { StyleSheet, Text, View, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';

const StatsContainer = props=>{
	var { colors } = useTheme()
	var onPress = props.onPress || null;
	var width = (props.statWidth || 140);

	const styles = StyleSheet.create({
		container: {
			borderRadius: 9,
			backgroundColor: colors.card,
			paddingVertical: 5,
			marginRight: 10,
			overflow: 'visible',
			width: width
		},
		contentContainer: {
			flexDirection: 'row',
			overflow: 'visible',
			paddingRight: 5
		},
		label: {
			color: colors.text,
			textAlign: 'center',
			fontSize: 16,
			textTransform: 'capitalize',
			color: 'gray'
		},
		value: {
			color: colors.text,
			fontSize: 24,
			fontWeight: 'bold',
			textAlign: 'center',
			paddingVertical: 5
		},
		metric: {
			paddingBottom: 10,
			fontWeight: 'bold',
			color: colors.text
		}
	})

	if(!props.data || !Array.isArray(props.data) || props.data.length==0){
		return <View />;
	}

	var RenderItem = (data)=>{
		var { item, index } = data;
		return <View style={[styles.container, props.containerStyle, (props.data.length-1)==index ? { marginRight: 0 } : {}]}>
			<Text style={[styles.label, item.labelStyle]} numberOfLines={1}>{item.label}</Text>
			<Text style={[styles.value, item.valueStyle]} numberOfLines={1}>{item.value}</Text>
			{item.metric ? (
				<Text style={[styles.label, styles.metric, item.metricStyle]} numberOfLines={1}>{item.metric}</Text>
			) : null}
		</View>
	}
	
	return (
		<View style={props.style}>
			<FlatList
				data={props.data}
				renderItem={RenderItem}
				keyExtractor={(i, ix)=>(`stat-${ix}`)}
				horizontal={true}
				snapToStart={true}
				snapToInterval={width-10}
				snapToAlignment={'center'}
				decelerationRate={0}
				showsHorizontalScrollIndicator={false}
			/>
		</View>
	)
}

StatsContainer.propTypes = {
	style: PropTypes.object,
	onPress: PropTypes.func,
	loading: PropTypes.bool,
	data: PropTypes.array,
	statWidth: PropTypes.number,
	containerStyle: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	])
}

export default StatsContainer;
