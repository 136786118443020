import React, { useEffect, useState } from 'react';
import { useTheme } from '@react-navigation/native';
import { Text, StyleSheet, SafeAreaView, View, Alert, ScrollView, KeyboardAvoidingView, Keyboard } from 'react-native';
import API from '../API';
import Button from '../component/Button';
import Input from '../component/Input';
import Validator from '../Validator';

export default (props)=>{
	var { onLogin, email, apple_auth, google_auth, ask_email } = props.route.params;
	var [register, setRegister] = useState(props.route.params.register);
	var [emailInput, setEmailInput] = useState(email);
	var [usernameInput, setUsernameInput] = useState('');
	var [passwordInput, setPasswordInput] = useState('');
	var [repeatPasswordInput, setRepeatPasswordInput] = useState('');
	var [loading, setLoading] = useState(false);
	const { colors } = useTheme();

	var external_auth = (google_auth || apple_auth);
	
	useEffect(()=>{
		var bfremove = null;
		if(loading){
			bfremove = props.navigation.addListener('beforeRemove', (e) => {
				e.preventDefault();
			});
		}
		props.navigation.setOptions({
			gestureEnabled: !loading,
			headerTitle: !register ? 'Login' : 'Register',
			headerBackTitleVisible: false
		})
		return ()=>{
			if(bfremove) bfremove();
		};
	}, [loading, register])
	
	var exit = ()=>{
		props.navigation.goBack();
	}

	var forgotPassword = ()=>{
		props.navigation.push('ForgotPassword');
	}
	
	var createAccount = ()=>{
		var form = {};
		
		if(external_auth){
			if(ask_email){
				form.email = { type: 'email', prompt: 'Invalid email address.' };
			}
		}else{
			form.password = { type: 'minLength', value: register ? 8 : 5, prompt: `Password must be at least ${register ? 8 : 5} characters.` };
			if(register) form.repeat_password = { type: 'equals', value: passwordInput, prompt: 'The passwords don\'t match.' };
		}
		form.username = { type: 'regex', value: /^[a-z]([a-z0-9\-_]{3,15})$/gi, prompt: 'Invalid username, must be alphanumerical, dash or underscore, 4-16 characters.' }

		var { valid, prompt } = Validator({
			email: emailInput,
			username: usernameInput,
			password: passwordInput,
			repeat_password: repeatPasswordInput
		}, form);

		if(!valid){
			if(prompt.length==1){
				return Alert.alert('Error', prompt[0].prompt);
			}
			return Alert.alert('Error', prompt.map(a=>('- '+a.prompt)).join('\n'));
		}

		var fn = null;
		if(apple_auth){
			fn = API.registerApple(apple_auth, usernameInput, emailInput);
		}else if(google_auth){
			fn = API.registerGoogle(google_auth, usernameInput, emailInput);
		}else if(register){
			fn = API.register(emailInput, usernameInput, passwordInput)
		}else{
			fn = API.login(usernameInput, passwordInput)
		}
		setLoading(true);
		fn.then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			if(onLogin) onLogin();
		}).catch(err=>{
			setLoading(false);
		})
	}
	
	return (
		<SafeAreaView style={StyleSheet.absoluteFill}>
			<KeyboardAvoidingView enabled={true} behavior={'padding'} keyboardVerticalOffset={-100} style={styles.container}>
				<ScrollView style={{ flex: 1 }}>
					{register ? (
						<Text style={[styles.title, { color: colors.text, marginTop: 20 }]}>Join the</Text>
					) : (
						<Text style={[styles.title, { color: colors.text, marginTop: 20 }]}>Login to the</Text>
					)}
					<Text style={[styles.title, { color: colors.text, marginBottom: 20 }]}>manga mountain.</Text>
					<Input label='Username' value={usernameInput} onChangeText={setUsernameInput} autoCorrect={false} />
					{register && ask_email && (
						<Input label='Email' editable={external_auth && email ? false : true} value={emailInput} onChangeText={setEmailInput} autoCapitalize={'none'} autoCorrect={false} keyboardType={'email-address'} />
					)}
					{!external_auth ? (
						<View>
							<Input label='Password' value={passwordInput} onChangeText={setPasswordInput} password={true} />
							{register && (
								<Input label='Repeat password' value={repeatPasswordInput} onChangeText={setRepeatPasswordInput} password={true} />
							)}
						</View>
					) : (
						<Text style={{ textAlign: 'center', color: colors.text, marginTop: 12, fontWeight: 'bold', fontSize: 16 }}>You're signing in with {apple_auth ? 'Apple' : (google_auth ? 'Google' : 'an external service')}.</Text>
					)}
					{!register && !external_auth ? (
						<Button style={{ height: 25, marginTop: 10 }} color='transparent' textColor={colors.primary} text="Forgot password" onPress={forgotPassword} />
					) : null}
					{!external_auth ? (
						<Button style={{ backgroundColor: 'transparent', height: 25, marginTop: 10 }} onPress={()=>{ setRegister(!register) }}>
							<Text style={{ fontWeight: 'normal', color: colors.text }}>{register ? 'Already registered?' : 'Not registered?'}</Text>
							<Text style={{ color: colors.primary }}>{' '}{register ? 'Sign in' : 'Create an account'}</Text>
						</Button>
					) : null}
				</ScrollView>
			</KeyboardAvoidingView>
			<View style={styles.footer}>
				<Button text={register ? 'Create account' : 'Login'} onPress={createAccount} loading={loading} />
				<Button text={'Cancel'} onPress={exit} style={{ marginTop: 10, height: 40, backgroundColor: 'transparent' }} color={loading ? 'transparent' : colors.text} textColor={colors.text} />
			</View>
		</SafeAreaView>
	)
}
								
const styles = StyleSheet.create({
	container: {
		maxWidth: 500,
		width: '100%',
		alignSelf: 'center',
		flex: 1,
		paddingHorizontal: 15,
		paddingBottom: 10,
	},
	title: {
		fontSize: 32,
		paddingHorizontal: 10,
		fontWeight: 'bold',
	},
	footer: {
		paddingHorizontal: 10,
		marginBottom: 20,
		maxWidth: 500,
		width: '100%',
		alignSelf: 'center',
	},
	buttonContainer: {
		padding: 10
	}
})