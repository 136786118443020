import React, { Component } from 'react';
import App from './src/App';
import { LogBox } from 'react-native';

LogBox.ignoreLogs([
  'Non-serializable values were found in the navigation state',
  'Can\'t perform a React state update on an unmounted component',
  'RCTBridge required dispatch_sync to load RNGestureHandlerModule',
]);

class Entry extends Component {
	render(){
		return <App />
	}
}

export default Entry;	