import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, Alert, RefreshControl } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { LoadingHeader, BookItem } from '../../component';
import API from '../../API';
import { getAllIndexes } from '../../Util';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { firstBy } from 'thenby'

var AdminBoxsets = props=>{
	var { colors, dark } = useTheme();
	var [loggedUser, setLoggedUser] = useState(null);
	var [boxsets, setBoxsets] = useState(null);
	var [loading, setLoading] = useState(false);
	var { showActionSheetWithOptions } = useActionSheet();

	var getData = (query=null, offset=0)=>{
		setLoading(true);
		API.getRecentBoxsets(query, offset, 50).then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			setBoxsets(res.data);
		}).catch(err=>{
			setLoading(false);
			Alert.alert('Error', 'Unexpected error getting boxsets (LCL-1)');
		});
	}

	useEffect(()=>{
		getData();
		API.getLogin().then(setLoggedUser);
	}, []);

	if(boxsets===null) return <LoadingHeader text={'Loading boxsets'} />
	if(boxsets.length==0) return <LoadingHeader loading={false} text={'No boxsets found'} />

	var styles = StyleSheet.create({
		
	})

	var viewBoxset = boxset=>{

	}

	var doComplete = (boxset_id, completed)=>{
		API.completeBoxset(boxset_id, completed).then(res=>{
			if(res.error) return Alert.alert('Error', res.message_client);
			var vols = [...boxsets];
			var vix = vols.findIndex(a=>a.boxset_id==boxset_id);
			vols[vix].completed = completed;
			setBoxsets(vols);
		}).catch(err=>{
			return Alert.alert('Error', 'Unexpected error marking boxset as complete (LCL-1)');
		});
	}

	var editBoxset = boxset=>{
		return props.navigation.push('EditBoxset', {
			boxset_id: boxset.boxset_id,
			onEdit: data=>{
				var bx = [...boxsets];
				for(var i of bx){
					if(i.boxset_id==data.boxset_id){
						i.books = data.volumes.length;
						i.series_id = data.series_id;
						i.series_name = data.series_name;
						i.boxset_number = data.boxset_number;
						i.boxset_part = data.boxset_part;
					}
				}
				setBoxsets(bx);
			},
			onComplete: boxset_id=>{
				doComplete(boxset_id, true);
			}
		});
	}

	var blacklistBoxset = boxset=>{
		Alert.alert(`${boxset.blacklist ? 'Unblacklist' : 'Blacklist'} Boxset`, `${boxset.blacklist ? 'Unblacklist' : 'Blacklist'} ${boxset.boxset_title}?`, [
			{ text: 'Cancel', style: 'cancel' },
			{ text: 'Continue', style: 'destructive', onPress: ()=>{
				API.blacklistBoxset(boxset.boxset_id, !boxset.blacklist).then(res=>{
					if(res.error) return Alert.alert('Error', res.message_client);
					var vols = [...boxsets];
					var vix = vols.findIndex(a=>a.boxset_id==boxset.boxset_id);
					vols[vix].blacklist = !boxset.blacklist;
					setBoxsets(vols);
				}).catch(err=>{
					return Alert.alert('Error', 'Unexpected error blacklisting boxset (LCL-1)');
				});
			}}
		]);
	}

	var markComplete = boxset=>{
		Alert.alert('Mark complete', `Mark ${boxset.boxset_title} as complete?`, [
			{ text: 'Cancel', style: 'cancel' },
			{ text: 'Continue', style: 'default', onPress: ()=>{
				doComplete(boxset.boxset_id, !boxset.completed);
			}}
		]);
	}

	var changeCover = boxset=>{
		props.navigation.push('ChangeCover', {
			isbn: boxset.isbn,
			manga_name: boxset.boxset_title,
		});
	}

	var showBoxset = boxset=>{
		return ()=>{
			if(!loggedUser) return;
			var options = [
				{ title: 'View Boxset', access: 1, onPress: viewBoxset },
				!boxset.blacklist ? { title: 'Edit Boxset', access: 100, onPress: editBoxset } : null,
				!boxset.blacklist ? { title: 'Change Cover', access: 50, onPress: changeCover } : null,
				!boxset.blacklist ? { title: boxset.completed ? 'Unmark Complete' : 'Mark Complete', access: 100, onPress: markComplete } : null,
				{ title: boxset.blacklist ? 'Unblacklist' : 'Blacklist', access: 100, destructive: !boxset.blacklist, onPress: blacklistBoxset },
			];
			if(options.length==0) return;
			options = options.filter(a=>a && (!a.access || a.access<=(loggedUser ? loggedUser.user_type : 1)));
			showActionSheetWithOptions({
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
				destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
			}, ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress(boxset);
			});
		}
	}

	return (
		<FlatList
			style={StyleSheet.absoluteFill}
			contentContainerStyle={{ paddingHorizontal: 10 }}
			data={boxsets.sort(firstBy('blacklist', 'asc').thenBy('completed', 'asc').thenBy('date_created', 'desc'))}
			keyExtractor={a=>a.boxset_id}
			refreshControl={<RefreshControl refreshing={loading} onRefresh={()=>getData()} />}
			renderItem={({ item })=>(
				<BookItem 
					imageScale={item.blacklist || item.completed ? 1 : 1.4}
					onPress={showBoxset(item)}
					grayedText={item.blacklist ? true : false}
					grayed={item.blacklist ? true : false}
					metadataStyle={item.blacklist ? { color: 'red' } : null}
					successText={item.completed ? 'Completed' : null}
					book={{
						name: item.completed ? item.series_name : item.boxset_title,
						volume_start: item.volume_start,
						volume_end: item.volume_end,
						image: item.image,
						metadata: item.blacklist ? ['Blacklisted'] : [
							`${item.books} Book${item.books==1 ? '' : 's'}`,
							(item.boxset_number ? `Set: ${item.boxset_number} ` : '')+(item.boxset_part ? `Part: ${item.boxset_part}` : '').trim(),
							`[${item.series_id}] ${item.series_name}`,
						]
					}}
				/>
			)}
		/>
	)
}

export default AdminBoxsets;