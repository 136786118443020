import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, FlatList, Alert } from 'react-native';
import API from '../API';
import { Input, SettingItem } from '../component';
import { useTheme } from '@react-navigation/native';

export default function(props){
	var { colors, dark } = useTheme();
	var { search_api, values, title, multiple, onSelect, onLongPress: propsLongPress } = props.route.params;
	var inputRef = useRef();
	var [searchValue, setSearchValue] = useState('');
	var [searchItems, setSearchItems] = useState(null);
	var [searching, setSearching] = useState(false);
	var [searchTimeout, setSearchTimeout] = useState(null);
	var [selectedItems, setSelectedItems] = useState(values.filter(a=>a.selected).map(a=>({
		value: a.value,
		text: a.text
	})));
	var [items, setitems] = useState(values.map(a=>({
		icon: a.icon,
		value: a.value,
		text: a.text,
		onPress: a.onPress,
		metadata: a.metadata,
	})));
	
	useEffect(()=>{
		props.navigation.setOptions({
			headerTitle: title || 'Search'
		});
		if(onSelect && multiple) onSelect(selectedItems);
		inputRef.current?.focus();
	}, [inputRef]);

	var onSelectItem = (item)=>{
		return ()=>{
			if(multiple){
				var selit = [...selectedItems];
				var itix = selit.findIndex(a=>a.value==item.value);
				if(itix==-1){
					selit.push(item);
				}else{
					selit.splice(itix, 1);
				}
				setSelectedItems(selit);
				if(onSelect){
					onSelect(selit)
				}
			}else{
				if(onSelect) onSelect(item)
				if(item.onPress){
					var res = item.onPress(item, props.navigation.goBack);
					if(res!==false){
						props.navigation.goBack();
					}
				}else{
					props.navigation.goBack();
				}
			}
		}
	}

	var onLongPress = (item)=>{
		return ()=>{
			if(!propsLongPress) return;
			propsLongPress(item);
		}
	}

	var onSearch = (val)=>{
		setSearchValue(val);
		if(searchTimeout) clearTimeout(searchTimeout);
		if(val.length<3){
			setSearching(false);
			setSearchItems(null);
			return;
		}
		setSearching(true);
		setSearchTimeout(setTimeout(()=>{
			API.post(search_api.url, { [search_api.query || 'query']: val }).then(res=>{
				setSearching(false);
				if(res.error) Alert.alert(res.message_client);
				var value_key = search_api.fields ? search_api.fields.value : 'value';
				var text_key = search_api.fields ? search_api.fields.text : 'text';
				var meta_key = search_api.fields ? search_api.fields.metadata : null;
				var badge_key = search_api.fields ? search_api.fields.badge : null;
				var searchItems = [];
				for(var i of res.data){
					var iVal = i[value_key], iTxt = i[text_key];
					if(typeof iVal === 'undefined') continue;
					var d = {
						value: iVal,
						text: iTxt
					}
					if(meta_key){
						d['metadata'] = i[meta_key];
					}
					if(badge_key){
						d['badge'] = i[badge_key];
					}
					searchItems.push(d);
				}
				setSearchItems(searchItems);
			}).catch(err=>{
				setSearching(false);
			})
		}, 500));
	}

	var selectedItemValues = selectedItems.map(a=>a.value);
	var shownItems = (searchItems || items);
	for(var i of selectedItems){
		var fix = shownItems.findIndex(a=>a.value==i.value);
		if(fix==-1){
			shownItems.push(i);
		}
	}

	return (
		<FlatList
			data={shownItems.sort((a,b)=>a.text-b.text)}
			keyExtractor={(a, i)=>`search-${a.value}-${i}`}
			keyboardDismissMode={'on-drag'}
			ListHeaderComponent={search_api ? (
				<Input inputRef={inputRef} height={40} containerStyle={{ borderRadius: 100 }} icon={'search'} placeholder='Search' value={searchValue} onChangeText={onSearch} loading={searching} autoCorrect={false} onClear={()=>inputRef.current?.focus()} />
			) : null}
			renderItem={({ item, index })=>(
				<SettingItem 
					icon={item.icon} 
					badge={item.badge} 
					metadata={item.metadata ? [item.metadata] : null} 
					text={item.text || item.value} 
					onPress={onSelectItem(item)} 
					onLongPress={onLongPress(item)}
					iconRight={selectedItemValues.indexOf(item.value)!=-1 ? 'check' : null} 
					styleIconRight={{ fontSize: 20 }}
				/>
			)}
		/>
	)
}