import React from 'react';
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import Button from './Button';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';

var LoadingHeader = props=>{
	var { colors, dark } = useTheme();
	
	const styles = StyleSheet.create({
		container: {
			marginTop: 20
		},
		header: {
			textAlign: 'center', 
			fontSize: 20, 
			fontWeight: 'bold', 
			color: 'gray', 
			marginTop: 8
		},
		retry: {
			height: 32,
			width: 150,
			marginTop: 15,
			backgroundColor: colors.border
		}
	});

	return (
		<View style={[styles.container, props.style]}>
			{props.error || props.loading===false ? (
				<FontAwesome5 solid={props.iconSolid} name={props.icon || 'exclamation-circle'} color={'gray'} size={50} style={{ textAlign: 'center' }} />
			) : (
				<ActivityIndicator color={colors.text} size={'large'} />
			)}
			<Text style={styles.header}>{props.error ? props.error : (props.text || 'Loading')}</Text>
			{props.retry && props.error ? (
				<Button text={props.retryButtonText || 'Retry'} onPress={props.onRetry} style={[styles.retry, props.retryButtonStyle]} color={'gray'} />
			) : null}
		</View>
	)
}
				
LoadingHeader.propTypes = {
	text: PropTypes.string,
	error: PropTypes.string,
	onRetry: PropTypes.func,
	retry: PropTypes.bool,
	retryButtonText: PropTypes.string,
	retryButtonStyle: PropTypes.object,
	style: PropTypes.object,
	icon: PropTypes.string,
	iconSolid: PropTypes.bool,
	loading: PropTypes.bool
}
				
export default LoadingHeader;