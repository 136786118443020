import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, SafeAreaView, KeyboardAvoidingView, ScrollView, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { Button, Input } from '../component';
import { validateEmail } from '../Util';
import API from '../API';

var ForgotPassword = props=>{
	var { colors, dark } = useTheme();
	var [loading, setLoading] = useState(false);
	var [email, setEmail] = useState('');
	var [waitCode, setWaitCode] = useState(false);
	var [code, setCode] = useState('')
	var [newPassword, setNewPassword] = useState('');
	var [newPasswordRepeat, setNewPasswordRepeat] = useState('');

	useEffect(()=>{
		props.navigation.setOptions({
			title: 'Forgot Password'
		})
	}, []);

	var exit = ()=>{
		props.navigation.goBack();
	}

	var recovery = ()=>{
		if(!validateEmail(email)) return Alert.alert('Email is invalid');
		setLoading(true);
		API.recoverAccount(email).then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('Email sent', 'We\'ve sent a code to the the account\'s email. Enter the code in this screen and set the account\'s new password.')
			setWaitCode(true);
		}).catch(err=>{
			setLoading(false);
			return Alert.alert('Error', 'Unexpected error sending recovery email (LCL-1)');
		})
	}

	var sendCode = ()=>{
		if(code.length<6) return Alert.alert('Invalid code', 'Code must be at least 6 characters');
		if(newPassword.length<8) return Alert.alert('Invalid new password', 'The new password must be at least 8 characters long.');
		if(newPassword!=newPasswordRepeat) return Alert.alert('Invalid new password', 'The new passwords do not match.');
		setLoading(true);
		API.sendRecoverCode(email, code, newPassword).then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('New password set', 'The code is valid and the new password has been set for this account. You can login with the new password.');
			exit();
		}).catch(err=>{
			setLoading(false);
			return Alert.alert('Error', 'Unexpected error setting new password (LCL-2)');
		})
	}

	var styles = StyleSheet.create({
		title: {
			fontSize: 32,
			paddingHorizontal: 0,
			fontWeight: 'bold',
			color: colors.text,
			textAlign: 'center',
			marginTop: 20,
		},
		disclamer: {
			textAlign: 'center',
			marginVertical: 10
		},
		container: {
			flex: 1,
			paddingHorizontal: 15,
			maxWidth: 500,
			width: '100%',
			alignSelf: 'center',
		},
		footer: {
			paddingHorizontal: 10,
			marginBottom: 20,
			maxWidth: 400,
			width: '100%',
			alignSelf: 'center',

		}
	})

	return (
		<SafeAreaView style={StyleSheet.absoluteFill}>
			<KeyboardAvoidingView enabled={true} behavior={'padding'} keyboardVerticalOffset={0} style={styles.container}>
				<ScrollView style={{ flex: 1 }}>
					<Text style={styles.title}>Recover Account</Text>
					{!waitCode ? (
						<View>
							<Text style={styles.disclamer}>In order to recover your account, we'll send you a code to the email address that you registered when you created your account.</Text>
							<Input label='Email' value={email} onChangeText={setEmail} editable={!loading && !waitCode} keyboardType={'email-address'} autoCapitalize={'none'} autoCorrect={false} />
						</View>
					) : (
						<View>
							<Text style={styles.disclamer}>
								Enter the code sent to the email address that you registered when you created your account and set a new password for this account.
							</Text>
							<Input label='Code' value={code} onChangeText={setCode} editable={true} autoCapitalize={'none'} autoCorrect={false} />
							<Input label='New password' value={newPassword} onChangeText={setNewPassword} password />
							<Input label='Repeat new password' value={newPasswordRepeat} onChangeText={setNewPasswordRepeat} password />
						</View>
					)}
				</ScrollView>
			</KeyboardAvoidingView>
			<View style={styles.footer}>
				{!waitCode && <Button text={'I already have a code'} onPress={()=>setWaitCode(true)} color={dark ? colors.card : colors.border} style={{ marginBottom: 10, height: 40 }} textColor={colors.text} />}
				<Button text={waitCode ? 'Set new password' : 'Send recovery email'} onPress={waitCode ? sendCode : recovery} loading={loading} />
				<Button text={'Cancel'} onPress={exit} style={{ marginTop: 10, height: 40, backgroundColor: 'transparent' }} color={loading ? 'transparent' : colors.text} textColor={colors.text} />
			</View>
		</SafeAreaView>
	)
}

export default ForgotPassword;