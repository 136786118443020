import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { HeaderScroll, Input, Button } from '../../component';
import API from '../../API';

var ChangeUsername = props=>{
	var { onUsernameChange } = (props.route.params || {});

	var { colors, dark } = useTheme();
	var [user, setUser] = useState(null);
	var [sending, setSending] = useState(false);
	var [username, setUsername] = useState('');

	useEffect(()=>{
		API.getLogin().then(u=>{
			setUser(u);
			setUsername(u.username);
		});
	}, []);

	var styles = StyleSheet.create({
		usernameLabel: {
			fontWeight: 'bold',
			fontSize: 14,
			marginBottom: 5,
			textAlign: 'center',
			color: dark ? colors.text : '#404040'
		},
		usernameText: {
			fontSize: 24,
			color: colors.text,
			textAlign: 'center',
		},
		submitButton: {
			width: 150,
			borderRadius: 100,
			alignSelf: 'center',
			height: 40,
			marginTop: 10
		},
	})

	if(!user) return null;
	
	var changeUsername = ()=>{
		if(username.length<4 || username.length>15) return Alert.alert('Error', 'Username must be between 4 and 15 characters.');
		Alert.alert('Are you sure you want to change your username?', null, [
			{ text: 'Cancel', style: 'cancel' },
			{ text: 'Continue', style: 'destructive', onPress: ()=>{
				setSending(true);
				API.changeUsername(username).then(res=>{
					setSending(false);
					if(res.error) return Alert.alert('Error', res.message_client);
					try{
						API.getUser(user.user_id, false, false);
					}catch(e){}
					Alert.alert(`Your username has been changed to ${username}!`);
					setUser({
						...user,
						username
					});
					if(onUsernameChange) onUsernameChange(user.user_id, username);
				}).catch(err=>{
					return Alert.alert('Error', 'Unexpected error changing username (LCL-1)');
				})
			}}
		])
	}

	return (
		<HeaderScroll
			headerText='Change Username'
		>
			<Text style={styles.usernameLabel}>Current Username</Text>
			<Text style={styles.usernameText}>{user.username}</Text>
			<Input label='Username' value={username} onChangeText={setUsername} maxLength={15} />
			<Button style={styles.submitButton} text="Submit" onPress={changeUsername} loading={sending} />
		</HeaderScroll>
	)
}

export default ChangeUsername;