import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert, ActivityIndicator } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { BookItem, LoadingHeader } from '../../component';
import API from '../../API';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { getAllIndexes } from '../../Util';
import moment from 'moment';

const LOAD_AMOUNT = 50;

var RecentManga = props=>{
	var [books, setBooks] = useState([])
	var [loading, setLoading] = useState(false)
	var [loggedUser, setLoggedUser] = useState(null)
	var [loadingBook, setLoadingBook] = useState(null);
	var [loadingMoreEnd, setLoadingMoreEnd] = useState(false);
	var { showActionSheetWithOptions } = useActionSheet();

	var getManga = (offset, count)=>{
		API.getRecentManga(offset, count).then(res=>{
			setLoading(false);
			if(res.error){
				Alert.alert('Error', res.message_client);
				if(!books || books.length==0){
					return props.navigation.goBack();
				}
			}
			if(res.data.length<LOAD_AMOUNT){
				setLoadingMoreEnd(true);
			}

			if(offset==0){
				setBooks(res.data);
			}else{
				setBooks([
					...books,
					...res.data
				]);
			}
		}).catch(err=>{
			setLoading(false);
			Alert.alert('Error', 'Unexpected error getting recent manga (LCL-1)');
			if(!books || books.length==0){
				return props.navigation.goBack();
			}
		})
	}

	var viewManga = book=>{
		return props.navigation.push('Book', book);
	}

	var editManga = book=>{
		return props.navigation.push('EditManga', {
			manga_id: book.manga_id
		})
	}

	var changeCover = book=>{
		props.navigation.push('ChangeCover', {
			manga_id: book.manga_id,
			manga_name: book.manga_name,
		});
	}

	var blacklistBook = async book=>{
		var bks = [...books];
		for(var i of bks){
			if(i.manga_id==book.manga_id){
				var blacklist = !i.blacklisted;
				setLoadingBook(book.manga_id);
				var res = await API.blacklistBook(book.manga_id, blacklist);
				if(res.error){
					return Alert.alert('Error', res.message_client);
				}else{
					i.blacklisted = blacklist;
				}
				setLoadingBook(null);
				break;
			}
		}
		setBooks(bks);
	}

	var showOptions = book=>{
		return ()=>{
			if(!loggedUser) return;
			var options = [
				{ title: 'View Manga', access: 1, onPress: viewManga },
				{ title: 'Edit Manga', access: 100, onPress: editManga },
				{ title: 'Change Cover', access: 50, onPress: changeCover },
				{ title: book.blacklisted ? 'Unblacklist' : 'Blacklist', access: 100, destructive: !book.blacklisted, onPress: blacklistBook },
			];
			if(options.length==0) return;
			options = options.filter(a=>(!a.access || a.access<=(loggedUser ? loggedUser.user_type : 1)));
			showActionSheetWithOptions({
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
				destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
			}, ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress(book);
			});
		}
	}

	var loadMore = ()=>{
		if(loading || loadingMoreEnd) return;
		setLoading(true);
		getManga(books.length, LOAD_AMOUNT);
	}

	useEffect(()=>{
		getManga(0, LOAD_AMOUNT);
		API.getLogin().then(setLoggedUser);
	}, []);

	if(!books || books.length==0){
		return <LoadingHeader text='Loading manga...' />
	}
	return (
		<FlatList
			data={books}
			keyExtractor={item=>`recent-${item.manga_id}`}
			onEndReached={loadMore}
			removeClippedSubviews={true}
			onEndReachedThreshold={0.3}
			ListFooterComponent={(
				loading && !loadingMoreEnd ? (
					<View style={{ marginTop: 20 }}>
						<ActivityIndicator color={'gray'} size={'large'} />
					</View>
				) : (
					<View style={{ height: 30 }} />
				)
			)}
			renderItem={({ item, index })=>(
				<BookItem 
					book={{
						...item,
						metadata: [
							`Series: [${item.series_id}] ${item.series_name}`,
							`Publisher: ${item.publisher_name}`,
							`Added: ${moment.unix(item.date_created).format('YYYY-MM-DD HH:mm')}`,
							item.blacklisted ? 'Blacklisted' : null,
						]
					}}
					grayed={item.blacklisted}
					grayedText={item.blacklisted}
					onPress={showOptions(item)} 
					loading={loadingBook==item.manga_id}
					series
				/>
			)}
		/>
	)
}

export default RecentManga;