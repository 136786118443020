function validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

function validateField(rule, val) {
	switch(rule.type){
		case 'empty': 
			return typeof val !== 'undefined' && val !== null && val.length>0;
		case 'minLength':
			return typeof val !== 'undefined' && val !== null && val.length>=(rule.value || 1)
		case 'maxLength': return rule.value && val && val.length<=rule.value;
		case 'email': return val && validateEmail(val);
		case 'equals': return val == rule.value;
		case 'regex': return val && rule.value && rule.value.test(val);
		default: return true;
	}
}

function validateForm(obj, rules) {
	var form_valid = true, form_prompt = [];
	for(var i in rules){
		var val = obj[i];
		if(Array.isArray(rules[i])){
			for(var rule of rules[i]){
				var valid = validateField(rule, val);
				if(!valid){
					form_valid = valid;
					form_prompt.push({ field: i, prompt: rule.prompt });
				}
			}
		}else{
			var valid = validateField(rules[i], val);
			if(!valid){
				form_valid = valid;
				form_prompt.push({ field: i, prompt: rules[i].prompt });
			}
		}
	}
	return { valid: form_valid, prompt: form_prompt };
}

export default validateForm;