import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useTheme } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { FontAwesome5 } from '@expo/vector-icons';

var Icon = props=>{
	var { colors, dark } = useTheme();

	var styles = StyleSheet.create({
		icon: {
			fontSize: props.size,
			textAlign: 'center',
			alignSelf: 'center',
		},
		icon2: {
			fontSize: props.size/1.3,
			color: '#CCCCCC',
			shadowColor: "#151717",
			shadowOffset: {
				width: 0,
				height: 0,
			},
			shadowOpacity: 1,
			shadowRadius: 3,

			elevation: 5,
			position: 'absolute',
			right: -6,
			bottom: -3,
		}
	})

	return (
		<View style={props.style}>
			<FontAwesome5 name={props.icon} size={props.size} color={props.color} solid={props.solid} style={styles.icon} />
			{props.icon2 ? (
				<FontAwesome5 name={props.icon2} size={props.size} color={props.color} solid={props.solid} style={styles.icon2} />
			) : null}
		</View>
	)
}

Icon.propTypes = {
	icon: PropTypes.string,
	icon2: PropTypes.string,
	color: PropTypes.string,
	solid: PropTypes.bool,
	size: PropTypes.number,
	style: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
}

export default Icon;