import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert, TouchableOpacity, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { LoadingHeader, Button, LoadingCover } from '../../component';
import { FontAwesome5 } from '@expo/vector-icons';
import API from '../../API';
import moment from 'moment';

const SELECTED_COLORS = {
	dark: '#2F7A2F',
	light: '#9EF29E',
}

var PublisherRedirect = props=>{
	var { colors, dark } = useTheme();
	var { publisher, onRedirect } = props.route.params;
	var [publishers, setPublishers] = useState(null);
	var [selectedPublisher, setSelectedPublisher] = useState(null);
	var [reverse, setReverse] = useState(false);
	var [sending, setSending] = useState(false);

	var selectedColor = SELECTED_COLORS[dark ? 'dark' : 'light'];

	var styles = StyleSheet.create({
		publisherContainer: {
			backgroundColor: colors.card,
			marginHorizontal: 15,
			borderRadius: 9,
			padding: 10,
			borderWidth: 3,
			// borderColor: colors.border
		},
		publisherName: {
			color: colors.text,
			fontSize: 20,
			marginBottom: 5,
		},
		publisherMetadata: {
			color: 'gray',
		}
	})

	var getPublishers = ()=>{
		API.getRedirectPublishers(publisher.publisher_id).then(res=>{
			if(res.error){
				Alert.alert('Error', res.message_client);
				return props.navigation.goBack();
			}
			setPublishers(res.data.options);
		}).catch(err=>{
			Alert.alert('Error', 'Unexpected error loading publisher redirect (LCL-1)');
			return props.navigation.goBack();
		})
	}

	var redirect = ()=>{
		var from, to;
		from = reverse ? selectedPublisher : publisher;
		to = reverse ? publisher : selectedPublisher;
		if(!from || !to) return;
		Alert.alert('Redirect publisher', `${from.publisher_name}\nto\n${to.publisher_name}`, [
			{ style: 'cancel', text: 'Cancel' },
			{ style: 'default', text: 'Apply', onPress: ()=>{
				setSending(true);
				API.redirectPublisher(from.publisher_id, to.publisher_id).then(res=>{
					setSending(false);
					if(res.error) return Alert.alert('Error', res.message_client);
					if(onRedirect) onRedirect(from, to);
					props.navigation.goBack();
				}).catch(err=>{
					setSending(false);
					Alert.alert('Error', 'Unexpected error setting publisher redirect (LCL-2)');
				})
			}}
		])
	}
	
	useEffect(()=>{
		getPublishers();
	}, []);

	if(!publishers) return <LoadingHeader text="Loading publisher redirect" />

	return (
		<View style={StyleSheet.absoluteFill}>
			<View style={[styles.publisherContainer, { marginTop: 10 }, !reverse ? {} : { borderColor: selectedColor }]}>
				<Text style={styles.publisherName}>{publisher.publisher_name}</Text>
				<View>
					<Text style={styles.publisherMetadata}>ID: {publisher.publisher_id}</Text>
					<Text style={styles.publisherMetadata}>{publisher.manga} Book{publisher.manga==1 ? '' : 's'}</Text>
					<Text style={styles.publisherMetadata}>Created: {moment.unix(publisher.date_created).format('YYYY-MM-DD HH:mm')}</Text>
				</View>
			</View>
			<FontAwesome5 name={reverse ? 'arrow-up' : 'arrow-down'} color={'white'} style={{ textAlign: 'center', marginVertical: 10, fontSize: 40, color: 'gray' }} />
			{selectedPublisher ? (
				<View>
					<View style={[styles.publisherContainer, { marginTop: 10 }, reverse ? {} : { borderColor: selectedColor }]}>
						<Text style={styles.publisherName}>{selectedPublisher.publisher_name}</Text>
						<View>
							<Text style={styles.publisherMetadata}>ID: {selectedPublisher.publisher_id}</Text>
							<Text style={styles.publisherMetadata}>{selectedPublisher.books} Book{selectedPublisher.books==1 ? '' : 's'}</Text>
							<Text style={styles.publisherMetadata}>Created: {moment.unix(selectedPublisher.date_created).format('YYYY-MM-DD HH:mm')}</Text>
						</View>
					</View>
					<View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'center' }}>
						{!sending && (
							<Button width={40} icon="arrow-left" five height={40} onPress={()=>setSelectedPublisher(null)} iconStyle={{ marginRight: 0 }} color={'#1D79F3'} />
						)}
						<Button width={200} text="Redirect" height={40} onPress={redirect} style={{ marginHorizontal: 10 }} loading={sending} />
						{!sending && (
							<Button width={40} icon="sync" five height={40} onPress={()=>setReverse(!reverse)} iconStyle={{ marginRight: 0 }} color={'#1D79F3'} />
						)}
					</View>
				</View>
			) : (
				<ScrollView>
					{publishers.map(a=>(
						<TouchableOpacity key={a.publisher_id} onPress={()=>setSelectedPublisher(a)}>
							<View style={[styles.publisherContainer, { marginTop: 10 }, !reverse ? {} : { backgroundColor: selectedColor }]}>
								<Text style={styles.publisherName}>{a.publisher_name}</Text>
								<View>
									<Text style={styles.publisherMetadata}>{a.books} Book{a.books==1 ? '' : 's'}</Text>
									<Text style={styles.publisherMetadata}>Created: {moment.unix(a.date_created).format('YYYY-MM-DD HH:mm')}</Text>
								</View>
							</View>
						</TouchableOpacity>
					))}
				</ScrollView>
			)}
		</View>
	)
}

export default PublisherRedirect;