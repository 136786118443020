import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert, Linking } from 'react-native';
import { useNavigation, useTheme } from '@react-navigation/native';
import API from '../../API';
import { Input, LoadingHeader, HeaderIcon, LoadingCover, SettingItem, HeaderScroll } from '../../component';
import { connectActionSheet, useActionSheet } from '@expo/react-native-action-sheet';

var EditSeries = props=>{
	var navigation = useNavigation();
	var { series_id, onEdit } = (props && props.route && props.route.params) ? props.route.params : {};
	var [series, setSeries] = useState(null);
	var [name, setName] = useState('');
	var [publisher, setPublisher] = useState(null);
	var [sending, setSending] = useState(false);
	var [editRedirect, setEditRedirect] = useState(true);
	var { showActionSheetWithOptions } = useActionSheet();

	var sendSave = ()=>{
		setSending(true);
		API.editSeries(series.series_id, name, publisher.publisher_id, editRedirect).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			setSeries(res.data);
			if(onEdit){
				onEdit({
					series_id: series.series_id,
					new_series_id: res.data.series_id,
					series_name: name,
					publisher_id: publisher.publisher_id,
					publisher_name: publisher.publisher_name
				});
			}
		}).catch(err=>{
			setSending(false);
			Alert.alert('Error', 'Unexpected error loading editing series (LCL-2)');
		});
	}

	var saveSeries = ()=>{
		if(!name || name.length<4 || !publisher || !publisher.publisher_id) return null;
		if(editRedirect){
			Alert.alert('Redirect current to new edit?', 'Redirecting makes it so that any new book with the current settings get redirected to the new edit.', [
				{ text: 'Cancel', style: 'cancel' },
				{ text: 'Accept', onPress: sendSave }
			])
		}else{
			sendSave();
		}
	}
	
	useEffect(()=>{
		setSending(false);
		API.getSeriesInfo(series_id).then(res=>{
			if(res.error){
				return Alert.alert('Error', res.message_client);
			}
			if(!res.data){
				Alert.alert('Error', 'No series found.');
				navigation.goBack();
			}
			setPublisher({
				publisher_id: res.data.publisher_id,
				publisher_name: res.data.publisher_name
			})
			setSeries(res.data);
			setName(res.data.series_name);
		}).catch(err=>{
			Alert.alert('Error', 'Unexpected error loading series info (LCL-1)');
			if(series){
				navigation.goBack();
			}
		});
	}, []);

	if(series===null){
		return <LoadingHeader text='Loading series info' />
	}

	var showPublisherSearch = ()=>{
		props.navigation.push('SelectionList', {
			title: 'Search Publisher',
			search_api: {
				url: 'admin/publisher/search', 
				fields: {
					value: 'publisher_id',
					text: 'publisher_name'
				}
			},
			values: [{
				value: series.publisher_id,
				text: series.publisher_name
			}],
			onSelect: vals=>{
				setPublisher({
					publisher_id: vals.value,
					publisher_name: vals.text
				})
			}
		});
	}

	var searchSeries = ()=>{
		setSending(true);
		API.getSeriesManga(series.series_id, 1).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			if(res.data.length==0) return Alert.alert('Error', 'This series has no manga.');
			Linking.openURL(`https://barnesandnoble.com/s/${res.data[0].isbn}`);
		}).catch(err=>{
			setSending(false);
			return Alert.alert('Error', 'Unexpected error searching series (LCL-2)');
		})
	}

	var verifyInfo = ()=>{
		setSending(true);
		API.verifySeriesInfo(series.series_id).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			var changes = []
			if(res.data.publisher_id && res.data.publisher_id!=publisher.publisher_id){
				setPublisher({
					publisher_id: res.data.publisher_id,
					publisher_name: res.data.publisher_name
				});
				changes.push('Changed publisher');
			}
			if(res.data.series_name!=name){
				setName(res.data.series_name);
				changes.push('Changed series name');
			}
			if(changes.length>0){
				Alert.alert('Success', `Changed: ${changes.join('\n')}`);
			}else{
				Alert.alert('Success', 'Everything looks good on this series!');
			}
		}).catch(err=>{
			setSending(false);
			return Alert.alert('Error', 'Unexpected error searching series (LCL-3)');
		})
	}

	var showOptions = ()=>{
		var options = [
			{ title: 'Auto fill info', onPress: verifyInfo },
			{ title: 'Web search', onPress: searchSeries },
		]
		if(options.length==0) return;
		showActionSheetWithOptions(
			{
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
			},
			ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress();
			}
		);
	}

	var styles = StyleSheet.create({
		
	});

	return (
		<View style={StyleSheet.absoluteFill}>
			<HeaderScroll
				headerText={'Edit Series'}
				headerRight={(
					<View style={{ flexDirection: 'row' }}>
						<HeaderIcon icon={'ellipsis-h'} size={28} width={30} onPress={showOptions} style={{ alignSelf: 'flex-end' }} />
						<HeaderIcon icon={'floppy'} material size={28} width={60} onPress={saveSeries} style={{ alignSelf: 'flex-end' }} />
					</View>
				)}
			>
				<Input label='Manga Name' value={name} onChangeText={setName} />
				<Input label='Publisher' iconRight='chevron-right' editable={false} value={publisher.publisher_name} onPress={showPublisherSearch} />
				<SettingItem text='Redirect current' onToggle={setEditRedirect} value={editRedirect} style={{ marginTop: 10, borderRadius: 6 }} />
			</HeaderScroll>
			<LoadingCover loading={sending} />
		</View>
	)
}

export default connectActionSheet(EditSeries);