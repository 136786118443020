import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, Alert, RefreshControl } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../../API';
import { LoadingCover, LoadingHeader, SettingItem } from '../../component';
import { useActionSheet } from '@expo/react-native-action-sheet'
import { getAllIndexes } from '../../Util';
import moment from 'moment';

var NewPublishers = props=>{
	var { colors, dark } = useTheme();
	var [publishers, setPublishers] = useState(null);
	var [refreshing, setRefreshing] = useState(false);
	var [loading, setLoading] = useState(false);
	var { showActionSheetWithOptions } = useActionSheet();

	var styles = StyleSheet.create({
		badgeContainer: {
			backgroundColor: colors.border,
			width: 35,
			height: 22,
			borderRadius: 100
		},
		badgeText: {
			color: colors.text,
			textAlign: 'center',
			marginTop: 3,
			fontWeight: 'bold'
		}
	})

	var getData = ()=>{
		setRefreshing(true);
		API.getUnverifiedPublishers().then(res=>{
			setRefreshing(false);
			if(res.error){
				Alert.alert('Error', res.message_client);
				if(publishers===null) props.navigation.goBack();
				return;
			}
			setPublishers(res.data)
		}).catch(err=>{
			setRefreshing(false);
			Alert.alert('Error', 'Unexpected error loading publishers (LCL-1)');
			if(publishers===null) props.navigation.goBack();
		})
	}

	useEffect(()=>{
		getData()
	}, []);

	if(!publishers){
		return <LoadingHeader text='Loading publishers' />
	}

	var viewManga = (publisher)=>{
		props.navigation.push('PublisherManga', {
			publisher
		})
	}

	var verifyPublisher = (publisher)=>{
		Alert.alert(`${publisher.verified ? 'Unverify' : 'Verify'} publisher?`, publisher.publisher_name, [
			{ text: 'Cancel', style: 'cancel' },
			{ text: publisher.verified ? 'Unverify' : 'Verify', style: 'default', onPress: ()=>{
				setLoading(true);
				API.setPublisherVerified(publisher.publisher_id, publisher.verified ? false : true).then(res=>{
					setLoading(false);
					if(res.error) return Alert.alert('Error', res.message_client);
					publisher.verified = !publisher.verified;
					setPublishers([
						...publishers.filter(a=>a.publisher_id!=publisher.publisher_id),
						publisher
					])
				}).catch(err=>{
					setLoading(false);
					return Alert.alert('Error', 'Unexpected error verifying publisher. (LCL-MGS-3)')
				})
			}}
		])
	}

	var blacklistPublisher = (publisher)=>{
		Alert.alert(`${publisher.blacklist ? 'Unblacklist' : 'Blacklist'} publisher?`, publisher.publisher_name, [
			{ text: 'Cancel', style: 'cancel' },
			{ text: publisher.blacklist ? 'Unblacklist' : 'Blacklist', style: !publisher.blacklist ? 'destructive' : 'default', onPress: ()=>{
				setLoading(true);
				API.setPublisherBlacklist(publisher.publisher_id, publisher.blacklist ? false : true).then(res=>{
					setLoading(false);
					if(res.error) return Alert.alert('Error', res.message_client);
					publisher.blacklist = !publisher.blacklist;
					setPublishers([
						...publishers.filter(a=>a.publisher_id!=publisher.publisher_id),
						publisher
					])
				}).catch(err=>{
					setLoading(false);
					return Alert.alert('Error', 'Unexpected error blacklisting publisher. (LCL-MGS-3)')
				})
			}}
		])
	}

	var redirectPublisher = (publisher)=>{
		props.navigation.push('PublisherRedirect', {
			publisher,
			onRedirect: (from, to)=>{
				publisher.redirected = true;
				setPublishers([
					...publishers.filter(a=>a.publisher_id!=publisher.publisher_id),
					publisher
				])
			}
		});
	}

	var showOptions = publisher=>{
		return ()=>{
			var options = [
				{ title: 'View Mangas', onPress: viewManga },
				{ title: `${publisher.verified ? 'Unverify' : 'Verify'} Publisher`, onPress: verifyPublisher },
				{ title: 'Redirect Publisher', onPress: redirectPublisher },
				{ title: `${publisher.blacklist ? 'Unblacklist' : 'Blacklist'} Publisher`, destructive: !publisher.blacklist, onPress: blacklistPublisher },
			];
			showActionSheetWithOptions({
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
				destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
			}, ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress(publisher);
			});
		}
	}

	return (
		<View style={StyleSheet.absoluteFill}>
			<FlatList
				style={{ flex: 1 }}
				data={publishers.sort((a,b)=>b.date_created-a.date_created)}
				keyExtractor={a=>a.publisher_id}
				renderItem={({ item })=>(
					<SettingItem 
						grayed={(item.verified || item.blacklist || item.redirected) ? true : false}
						grayedContainer={(item.verified || item.blacklist || item.redirected) ? true : false}
						text={item.publisher_name} 
						onPress={showOptions(item)}
						metadata={[
							item.blacklist ? 'BLACKLISTED' : null,
							item.redirected ? 'REDIRECTED' : null,
							`${item.manga} Book${item.manga==1 ? '' : 's'}`,
							`Created: ${moment.unix(item.date_created).format('YYYY-MM-DD HH:mm')}`
						]}
						contentsRight={item.related ? (
							<View style={styles.badgeContainer}>
								<Text style={styles.badgeText}>{item.related}</Text>
							</View>
						) : <View />}
					/>
				)}
				refreshControl={
					<RefreshControl refreshing={refreshing} onRefresh={getData} />
				}
			/>
			<LoadingCover loading={loading} />
		</View>
	)
}

export default NewPublishers;