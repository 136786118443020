import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View, Image, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';
import ImageFallback from './ImageFallback';

const BookCarrousel = props=>{
	var { colors } = useTheme()

	const styles = StyleSheet.create({
		container: {
			width: '100%',
			borderRadius: 9,
			backgroundColor: colors.card,
			paddingVertical: 15,
			paddingRight: 5,
			overflow: 'visible'
		},
		contentContainer: {
			flexDirection: 'row',
			overflow: 'visible',
			paddingRight: 5
		},
		book: { 
			width: 120,
			height: 180, 
			backgroundColor: colors.border, 
			marginLeft: 15,
			borderRadius: 4,
			resizeMode: 'cover',
			overflow: 'hidden'
		},
		bookName: {
			color: colors.text,
			width: 140,
			fontSize: 14,
			fontWeight: 'bold',
			textAlign: 'center',
			paddingLeft: 15,
			marginTop: 5,
			height: 16
		}
	})

	if(!props.books || !Array.isArray(props.books) || props.books.length==0){
		return null;
	}

	var onBookPress = (book)=>{
		if(!props.onPress) return null;
		return ()=>{
			return props.onPress(book);
		}
	}
	
	return (
		<View style={[styles.container, props.style]}>
			<FlatList 
				contentContainerStyle={[styles.contentContainer]} 
				horizontal={true}
				snapToStart={true}
				snapToInterval={140}
				snapToAlignment={'start'}
				decelerationRate={0}
				showsHorizontalScrollIndicator={false}
				keyExtractor={a=>`carrousel-manga-${a.manga_id}`}
				renderItem={data=>{
					var a = data.item;
					return <TouchableOpacity key={'book-'+a.manga_id} style={{ flexDirection: 'column' }} onPress={onBookPress(a)}>
						<ImageFallback style={styles.book} url={`${a.image}/250.jpg`} fallbackText={a.manga_name} fallbackTextStyle={{ fontSize: 14, padding: 10 }} />
						<Text numberOfLines={1} style={styles.bookName}>{a.manga_name}</Text>
					</TouchableOpacity>
				}}
				data={props.books}
			/>
		</View>
	)
}

BookCarrousel.propTypes = {
	style: PropTypes.object,
	onPress: PropTypes.func,
	loading: PropTypes.bool,
	books: PropTypes.array,
}

export default BookCarrousel;
