import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View, RefreshControl } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../API';
import { Button, LoadingHeader, HeaderScroll, HeaderIcon } from '../component'
import BookItem from '../component/BookItem';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { getAllIndexes } from '../Util';

export default (props)=>{
	var propsSeries = props.route.params.series;
	var defaultOwned = props.route.params.owned;
	var { user_id, username } = props.route.params;
	var { colors, dark } = useTheme();

	var [loggedUser, setLoggedUser] = useState(null);
	var [user, setUser] = useState(null);
	var [series, setSeries] = useState(null);
	var [volumes, setVolumes] = useState([]);
	var [refreshing, setRefreshing] = useState(false);
	var [error, setError] = useState(null);
	var [showOwned, setShowOwned] = useState(defaultOwned || false);
	var { showActionSheetWithOptions } = useActionSheet();

	var refreshSeries = (refresh=false, user_id=null)=>{
		if(error) setError(null);
		if(refresh) setRefreshing(true);

		setError(null);
		API.getSeries(propsSeries.series_id, showOwned ? (user_id || loggedUser.user_id) : null).then(res=>{
			if(res.error) return setError(res.message_client);
			var { series, user, volumes } = res.data;
			setSeries(series);
			setVolumes(volumes)
			if(user){
				setUser(user)
			}
		}).catch(e=>{
			setError('Unexpected error loading series. (LCL-SR-1)');
		});

		// setTimeout(()=>{
		// 	setError('fuck u')
		// }, 1000)
	}

	var showBook = (book)=>{
		return ()=>{
			props.navigation.push('Book', {
				manga_id: book.manga_id,
				manga_name: book.manga_name,
				mangaka_name: book.authors ? book.authors[0] : null,
				image: book.images[0],
				mangaka_name: series.authors && series.authors[0] ? series.authors[0] : null,
				onOwned: (manga_id, owned)=>{
					var vols = [...volumes];
					var vix = vols.findIndex(a=>a.manga_id==manga_id);
					vols[vix].owned = owned;
					setVolumes(vols);
				}
			})
		}
	}
	
	useEffect(()=>{
		if(user_id){
			API.getLogin().then(setLoggedUser);
			refreshSeries(false, user_id);
		}else{
			API.getLogin().then(user=>{
				setLoggedUser(user)
				refreshSeries(false, user.user_id);
			})	
		}
	}, []);

	const styles = StyleSheet.create({
		headerText: {
			color: colors.text,
			fontSize: 32,
			fontWeight: 'bold',
			marginBottom: 10,
			marginTop: 5,
			textAlign: 'center',
		},
		headerMeta: {
			fontSize: 18,
			color: 'gray',
			textAlign: 'center',
			marginTop: -2
		}
	});

	var RenderHeader = ()=>{
		return (
			<View>
				<Text style={styles.headerMeta}>Series</Text>
				<Text style={styles.headerText}>{series ? series.series_name : propsSeries.series_name}</Text>
				<Text style={[styles.headerMeta, { marginTop: -10, marginBottom: 5}]}>{(series && series.authors ? series.authors : propsSeries.authors).join(', ')}</Text>
				{series ? (
					<Text style={[styles.headerMeta, { marginTop: -2, marginBottom: 5}]}>
						{volumes.length} Volume{volumes.length==1 ? '' : 's'} Total
					</Text>
				) : null}
				{/* <StatsCarrousel statWidth={100} data={[{ label: 'Volumes', value: 1 }]} /> */}
			</View>
		)
	}

	var changeShowOwned = owned=>{
		return ()=>{
			setShowOwned(owned);
		}
	}

	var renderVolumes = showOwned ? volumes.filter(a=>a.owned) : volumes;

	var RenderHeaderFilter = ()=>{
		return <View>
			<RenderHeader />
		</View>
	}
	
	if(!series){
		return <HeaderScroll
			headerText={series ? series.series_name : propsSeries.series_name}
			headerShown={false}
			containerTextShown={false}
			style={StyleSheet.absoluteFill}
		>
			<RenderHeader />
			<LoadingHeader error={error} retryButtonText={'Reload'} text="Loading series" retry={true} onRetry={refreshSeries} style={{ marginTop: 40 }} />
		</HeaderScroll>
	}

	var hasUsers = user && loggedUser;
	var isSelf = hasUsers && (user.user_id==loggedUser.user_id);

	var showReport = ()=>{
		props.navigation.push('ProblemReport', {
			series_id: series.series_id,
			name: series.series_name,
		});
	}

	var showOptions = ()=>{
		var options = [
			{ title: 'Report a problem', access: 0, onPress: showReport },
		]
		if(options.length==0) return;
		options = options.filter(a=>(!a.access || a.access<=(loggedUser ? loggedUser.user_type : 1)));
		showActionSheetWithOptions(
			{
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
				destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
			},
			ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress();
			}
		);
	}

	return (
		<HeaderScroll 
			headerText={series ? series.series_name : propsSeries.series_name}
			headerShown={false}
			style={StyleSheet.absoluteFill}
			containerTextShown={false}
			flatListItems={[0, 1, ...renderVolumes]}
			scrollMax={80}
			headerRight={<HeaderIcon icon='ellipsis-h' five onPress={showOptions} />}
			flatListRenderItem={(a,b)=>{
				return a.index>1 ? (
					<BookItem series grayed={!a.item.owned} book={{
						type: 'manga',
						manga_id: a.item.manga_id,
						name: a.item.volume_start ? series.series_name : a.item.manga_name,
						volumes: 1,
						volume_start: a.item.volume_start,
						volume_end: a.item.volume_end,
						images: a.item.images,
						owned: showOwned ? 0 : a.item.owned
					}} owner={hasUsers ? (isSelf ? 'You' : user.username) : null} singular={isSelf} onPress={showBook(a.item)} />
				) : a.index==0 ? (
					<RenderHeaderFilter />
				) : (
					<View>
						<View style={{ flexDirection: 'row', paddingVertical: 10, backgroundColor: colors.background, }}>
							<Button text={username ? `${username} owns` : 'Owned'} toggle toggleValue={showOwned} height={30} style={{ flex: 1, marginRight: 10 }} onPress={changeShowOwned(true)} toggleBorderWidth={0} />
							<Button text="All series" toggle toggleValue={!showOwned} height={30} style={{ flex: 1 }} onPress={changeShowOwned(false)} toggleBorderWidth={0} />
						</View>
						{username && showOwned ? (
							<Text style={{ color: 'gray', backgroundColor: colors.background, paddingBottom: 10, textAlign: 'center' }}>Showing {username}'s volumes</Text>
						) : null}
						{renderVolumes.length==0 ? (
							<LoadingHeader text={showOwned ? 'You don\'t own any volumes for this series.' : 'This series has no volumes'} icon="sad-cry" loading={false} iconSolid />
						) : null}
					</View>
				)
			}}
			stickyIndices={[2]}
			flatListKeys={(a)=>(`${a.manga_id || a.series_id || a}`)}
			refreshControl={
				<RefreshControl refreshing={refreshing} onRefresh={refreshSeries} />
			}
			flatListProps={{
				removeClippedSubviews: true
			}}
		>
		</HeaderScroll>
	)
}