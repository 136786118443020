import React, { useState } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';

const Button = props=>{
	var { colors } = useTheme()
	var isToggle = props.toggleValue;

	var fontSize = (props.size || 16);
	var textColor = (props.textColor || 'white');
	var loadingSize = props.size >= 24 ? 'large' : 'small';
	var onPress = (props.onPress && !props.loading) ? props.onPress : null;
	var btnStyle = {}
	if(props.height) btnStyle.height = props.height;
	if(props.width) btnStyle.width = props.width;
	if(props.toggle){
		if(isToggle){
			btnStyle.backgroundColor = (props.color || colors.primary);
		}else{
			textColor = colors.text;
			btnStyle.borderColor = colors.border;
			btnStyle.borderWidth = props.toggleBorderWidth || props.toggleBorderWidth===0 ? props.toggleBorderWidth : 2;
		}
	}else{
		btnStyle.backgroundColor = (props.color || colors.primary);
	}

	var btnPress = ()=>{
		if(props.toggle){
			var tgl = !isToggle;
			if(onPress) onPress(tgl);
		}else{
			if(onPress) onPress();
		}
	}

	return (
		<View style={[styles.container, btnStyle, props.style]}>
			<TouchableOpacity onPress={btnPress} style={[StyleSheet.absoluteFill, { alignItems: 'center', justifyContent: 'center' }]}>
				{props.loading ? (
					<ActivityIndicator color={textColor} size={loadingSize} />
				) : (
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						{props.icon ? (
							<FontAwesome5 name={props.icon} size={fontSize} color={textColor} style={[{ marginBottom: -1, marginRight: 10 }, props.iconStyle]} />
						) : null}
						<Text style={[{ color: textColor, fontWeight: 'bold', fontSize: fontSize }, props.textStyle]}>{(props.children || props.text)}</Text>
					</View>
				)}
			</TouchableOpacity>
		</View>
	)
}

Button.propTypes = {
	style: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	onPress: PropTypes.func,
	loading: PropTypes.bool,
	color: PropTypes.string,
	textColor: PropTypes.string,
	size: PropTypes.number,
	icon: PropTypes.string,
	iconStyle: PropTypes.object,
	text: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	toggle: PropTypes.any,
	toggleValue: PropTypes.bool,
	toggleBorderWidth: PropTypes.number,
	textStyle: PropTypes.object,
}

export default Button;

const styles = StyleSheet.create({
	container: {
		width: '100%',
		height: 45,
		borderRadius: 9,
		alignSelf: 'center', 
		alignItems: 'center',
		justifyContent: 'center'
	}
})