import React, { useState } from 'react';
import { Text, View, StyleSheet, ActivityIndicator, Switch, TouchableHighlight } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import PropTypes from 'prop-types'
import Icon from './Icon';

var SettingItem = props=>{
	var { colors, dark } = useTheme();
	var style = StyleSheet.create({
		setting: {
			minHeight: 60,
			backgroundColor: props.grayedContainer ? colors.background : colors.card,
			flexDirection: 'row',
			alignItems: 'center',
			paddingHorizontal: 10,
			paddingVertical: 10,
			borderTopColor: colors.border,
			borderTopWidth: StyleSheet.hairlineWidth
		},
		setting_label: {
			fontSize: 18,
			color: props.grayed ? 'gray' : colors.text,
		},
		setting_icon: {
			marginLeft: 10,
			marginRight: 20,
			textAlign: 'center',
			fontSize: 20,
			height: 24,
		},
		setting_icon_right: {
			fontSize: 20
		},
		metadata: {
			color: 'gray'
		},
		badgeContainer: {
			backgroundColor: colors.border,
			borderRadius: 200,
			marginRight: 5,
			marginLeft: 5,
		},
		badgeText: {
			color: colors.text,
			paddingVertical: 5,
			minWidth: 45,
			textAlign: 'center',
			paddingHorizontal: 10,
		}
	})

	var randId = Math.floor(Math.random()*1000);

	var ICON_ELEM = props.icon ? (
		<Icon icon={props.icon} icon2={props.icon2} color={colors.text} solid={props.iconSolid} size={22} style={[style.setting_icon, props.styleIcon]} />
	) : null;
	var TEXT_ELEM = <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'column' }}>
		<Text style={style.setting_label}>{props.text}</Text>
		{props.metadata ? (
			<View style={{ marginTop: 2 }}>
				{props.metadata.filter(a=>!!a).map((a,i)=>(
					<Text key={`meta-${randId}-${i}`} style={style.metadata}>{a}</Text>
				))}
			</View>
		) : null}
	</View>
	var BADGE_ELEM = props.badge ? <View style={style.badgeContainer}>
		<Text style={style.badgeText}>{props.badge}</Text>
	</View> : null

	return (
		props.onToggle ? (		
			<View style={[style.setting, props.style]}>
				{ICON_ELEM}
				{TEXT_ELEM}
				{props.loading ? (
					<ActivityIndicator size='small' color='gray' style={props.loadingStyle}  />
				) : (
					<Switch onValueChange={props.onToggle} value={props.value} />
				)}
			</View>
		) : props.onPress ? (
			<TouchableHighlight onPress={props.onPress} onLongPress={props.onLongPress}>
				<View style={[style.setting, props.style]}>
					{ICON_ELEM}
					{TEXT_ELEM}
					{BADGE_ELEM}
					{props.loading ? (
						<ActivityIndicator size='small' color='gray' style={props.loadingStyle}  />
					) : (
						props.contentsRight ? (
							props.contentsRight
						) : (
							<FontAwesome5 name={props.iconRight || "chevron-right"} solid={props.iconRightSolid} color={!props.iconRight ? 'gray' : '#CCCCCC'} style={[props.styleIconRight, props.styleIconRight]} />
						)
					)}
				</View>
			</TouchableHighlight>
		) : (
			<View style={[style.setting, props.style]}>
				{ICON_ELEM}
				<Text style={style.setting_label}>{props.text}</Text>
				{BADGE_ELEM}
				{props.loading ? (
					<ActivityIndicator size='small' color='gray' style={props.loadingStyle} />
				) : (
					props.contentsRight ? (
						props.contentsRight
					) : (
						<FontAwesome5 name={props.iconRight || "chevron-right"} solid={props.iconRightSolid} color={'#CCCCCC'} style={[style.setting_icon_right, props.styleIconRight]} />
					)
				)}
			</View>
		)
	)
}

SettingItem.propTypes = {
	onToggle: PropTypes.func,
	onPress: PropTypes.func,
	onLongPress: PropTypes.func,
	icon: PropTypes.string,
	iconSolid: PropTypes.bool,
	iconRight: PropTypes.string,
	iconRightSolid: PropTypes.bool,
	grayed: PropTypes.bool,
	grayedContainer: PropTypes.bool,
	text: PropTypes.string,
	value: PropTypes.bool,
	loading: PropTypes.bool,
	contentsRight: PropTypes.element,
	metadata: PropTypes.any,
	badge: PropTypes.any,
	style: PropTypes.any,
	loadingStyle: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]),
	styleIcon: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]),
	styleIconRight: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	])
}

export default SettingItem;