import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert, ScrollView, TouchableHighlight, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../../API';
import { ImageFallback, Input, LoadingHeader, Button, BookItem, HeaderIcon, LoadingCover } from '../../component';
import { MaterialCommunityIcons } from '@expo/vector-icons';

var EditBoxset = props=>{
	var { colors, dark } = useTheme();
	var { boxset_id, onEdit, onComplete } = (props && props.route && props.route.params) ? props.route.params : {};
	var [boxset, setBoxset] = useState(null);
	var [boxsetVolumes, setBoxsetVolumes] = useState(null);
	var [series, setSeries] = useState(null);
	var [seriesVolumes, setSeriesVolumes] = useState(null);
	var [boxsetNumber, setBoxsetNumber] = useState(null);
	var [showBoxsetVolumes, setShowBoxsetVolumes] = useState(true);
	var [loadingVolumes, setLoadingVolumes] = useState(false);
	var [boxsetPart, setBoxsetPart] = useState(null);
	var [sending, setSending] = useState(false);
	
	useEffect(()=>{
		setSending(false);
		API.getBoxset(boxset_id, true).then(res=>{
			if(res.error){
				if(!boxset) props.navigation.goBack();
				return Alert.alert('Error', res.message_client);
			}
			setBoxsetNumber(res.data.boxset_number);
			setBoxsetPart(res.data.boxset_part);
			setSeries({
				series_id: res.data.series_id,
				series_name: res.data.series_name,
			});
			setBoxsetVolumes(res.data.volumes.sort((a,b)=>a.volume_start-b.volume_start));
			setBoxset(res.data);
		}).catch(err=>{
			Alert.alert('Error', 'Unexpected error loading boxset info (LCL-1)');
			if(!boxset){
				props.navigation.goBack();
			}
		})
	}, []);

	if(boxset===null){
		return <LoadingHeader text='Loading boxset info' />
	}

	var styles = StyleSheet.create({
		cover: {
			width: 200, 
			height: 200*1.5, 
			backgroundColor: colors.card, 
			borderRadius: 9,
			alignSelf: 'center',
			marginTop: 10,
		},
		coverText: {
			fontSize: 24, 
			paddingHorizontal: 15
		},
		inputComment: {
			color: 'gray',
			textAlign: 'center',
			flexDirection: 'column',
			marginBottom: 10,
		},
		header: {
			fontWeight: 'bold',
			color: colors.text,
			fontSize: 24,
			textAlign: 'center',
			marginTop: 10
		},
		buttonsContainer: {
			flexDirection: 'row',
			paddingVertical: 10,
			backgroundColor: colors.background
		}
	});

	var showSeriesSearch = ()=>{
		props.navigation.push('SelectionList', {
			title: 'Search Series',
			search_api: {
				url: 'admin/series/search?p=1&m=1', 
				fields: {
					value: 'series_id',
					text: 'series_name',
					metadata: 'publisher_name',
					badge: 'manga_count'
				}
			},
			values: series ? [{
				value: series.series_id,
				text: series.series_name
			}] : [],
			onSelect: vals=>{
				if(vals.value!==series.series_id && seriesVolumes!==null){
					getSeriesVolumes(vals.value);
				}
				setSeries({
					series_id: vals.value,
					series_name: vals.text
				})
			}
		})
	}

	var getSeriesVolumes = (series_id)=>{
		setSeriesVolumes(null);
		setLoadingVolumes(true);
		API.getSeriesVolumes(series_id).then(res=>{
			setLoadingVolumes(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			setSeriesVolumes(res.data.sort((a,b)=>a.volume_start-b.volume_start));
		}).catch(err=>{
			setLoadingVolumes(false);
			Alert.alert('Error', 'Unexpected error loading boxset volumes (LCL-2)');
		})
	}

	var changeVolumeShow = (show)=>{
		return ()=>{
			if(show===false && seriesVolumes===null && !loadingVolumes){
				getSeriesVolumes(series.series_id);
			}
			setShowBoxsetVolumes(show);
		}
	}

	var addBook = (book)=>{
		return ()=>{
			var vols = [...boxsetVolumes];
			var vix = vols.findIndex(a=>a.manga_id==book.manga_id);
			if(vix==-1){
				vols.push({
					series_name: series.series_name,
					...book
				})
			}else{
				vols.splice(vix, 1);
			}
			if(vix!=-1){
				Alert.alert('Remove book?', 'Remove this book from this boxset?', [
					{ text: 'Cancel', style: 'cancel' },
					{ text: 'Remove', style: 'destructive', onPress: ()=>{
						setBoxsetVolumes(vols);
					}}
				]);
			}else{
				setBoxsetVolumes(vols);
			}
		}
	}

	var saveBoxset = ()=>{
		if(!series || !series.series_id){
			return Alert.alert('Error', 'Please select the series of this boxset.');
		}
		if(!boxsetVolumes || boxsetVolumes.length==0){
			return Alert.alert('Error', 'Please select at least one book for this boxset.');
		}
		setSending(true);
		API.editBoxset(boxset_id, series.series_id, boxsetNumber || null, boxsetPart || null, boxsetVolumes.map(a=>a.manga_id)).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			if(onEdit) onEdit({
				boxset_id,
				series_id: series.series_id,
				series_name: series.series_name,
				boxset_number: boxsetNumber,
				boxset_part: boxsetPart,
				volumes: boxsetVolumes
			})
			if(onComplete && !boxset.completed && boxsetVolumes.length>1 && series && series.series_id){
				Alert.alert('Mark as complete', 'Boxset editted, want to mark it as complete?', [
					{ text: 'No', style: 'cancel' },
					{ text: 'Yes', style: 'default', onPress: ()=>{
						props.navigation.goBack();
						onComplete(boxset_id);
					}}
				])
			}
		}).catch(err=>{
			setSending(false);
			return Alert.alert('Error', 'Unexpected error editing boxset (LCL-1)');
		})
	}

	return (
		<View>
			<ScrollView contentContainerStyle={{ paddingHorizontal: 10 }} stickyHeaderIndices={[7]}>
				<ImageFallback url={boxset.image+'/original.jpg'} fallbackText={series.series_name} style={styles.cover} fallbackTextStyle={styles.coverText} />
				<Text style={styles.header}>{boxset.boxset_title}</Text>
				<Input label='Series' iconRight='chevron-right' editable={false} value={series.series_name} onPress={showSeriesSearch} />
				<Text style={styles.inputComment}>
					Needs to be the series that the boxset and its volumes is from. 
				</Text>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					<Input label="Boxset Number" value={boxsetNumber} onChangeText={setBoxsetNumber} style={{ width: '48%' }} keyboardType={'number-pad'}/>
					<Input label="Boxset Part" value={boxsetPart} onChangeText={setBoxsetPart} style={{ width: '48%' }} keyboardType={'number-pad'} />
				</View>
				<Text style={styles.inputComment}>
					Boxset Number is the set numeration{'\n'} (One Piece Boxset <Text style={{ fontWeight: 'bold' }}>4</Text>)
					{'\n'}
					Boxset Part is the extra number {'\n'} (Attack on Titan Season 1 Part <Text style={{ fontWeight: 'bold' }}>2</Text>)
				</Text>
				<View>
					<Text style={styles.header}>Boxset Volumes</Text>
				</View>
				<View>
					<View style={styles.buttonsContainer}>
						<Button text={`This Boxset (${boxsetVolumes.length})`} toggle toggleValue={showBoxsetVolumes} height={30} style={{ flex: 1, marginRight: 10 }} onPress={changeVolumeShow(true)} toggleBorderWidth={0} />
						<Button text={'All series' + (seriesVolumes ? ` (${seriesVolumes.length})` : '')} toggle toggleValue={!showBoxsetVolumes} height={30} style={{ flex: 1 }} onPress={changeVolumeShow(false)} toggleBorderWidth={0} />
					</View>
				</View>
				{showBoxsetVolumes ? (
					boxsetVolumes && boxsetVolumes.length>0 ? (
						boxsetVolumes.map(a=>(
							<BookItem
								series
								key={`bxbook-${a.manga_id}`}
								onPress={addBook(a)}
								book={{
									name: series.series_name,
									volume_start: a.volume_start,
									volume_end: a.volume_end,
									image: a.image
								}}
							/>
						))
					) : (
						<LoadingHeader loading={false} iconSolid icon="sad-tear" text='This boxset has no volumes' />
					)
				) : (
					seriesVolumes ? (
						seriesVolumes.length==0 ? (
							<LoadingHeader loading={false} iconSolid icon="sad-cry" text='This series has no volumes' />	
						) : seriesVolumes.map(a=>{
							var added = boxsetVolumes.findIndex(b=>b.manga_id==a.manga_id)==-1;
							return <BookItem
								series
								grayed={added}
								key={`book-${a.manga_id}`}
								metadataStyle={{ color: 'green' }}
								onPress={addBook(a)}
								book={{
									name: series.series_name,
									volume_start: a.volume_start,
									volume_end: a.volume_end,
									image: a.image,
									metadata: !added ? ['Added'] : null
								}}
							/>
						})
					) : loadingVolumes ? (
						<LoadingHeader text='Loading series volumes' />
					) : (
						<LoadingHeader loading={false} iconSolid icon="sad-cry" text='Unexpected error loading volumes' />
					)
				)}
				<View style={{ height: 50 }} />
			</ScrollView>
			<TouchableOpacity onPress={saveBoxset}>
				<View style={{ position: 'absolute', width: 60, height: 60, backgroundColor: colors.primary, bottom: 10, right: 10, borderRadius: 50 }}>
					<MaterialCommunityIcons name="floppy" style={{ alignSelf: 'center', lineHeight: 60, fontSize: 30, color: 'white' }} />
				</View>
			</TouchableOpacity>
			<LoadingCover loading={sending} />
		</View>
	)
}

export default EditBoxset;