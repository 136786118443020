import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, RefreshControl, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import API from '../API';
import { useTheme } from '@react-navigation/native';
import { BookCarrousel, MessageCarrousel, LoadingHeader, Button } from '../component';
import { mixColors, RGBToHex } from '../Util';

export default (props)=>{
	var { colors, dark } = useTheme();
	var [home, setHome] = useState(null);
	var [error, setError] = useState(null);
	var [user, setUser] = useState(null);
	var [refresh, setRefresh] = useState(false);

	const styles = StyleSheet.create({
		shelfTitle: {
			color: colors.text,
			fontSize: 24,
			marginTop: 20,
			marginBottom: 5,
			fontWeight: 'bold'
		},
		containerText: {
			color: colors.text,
			fontSize: 32,
			fontWeight: 'bold',
			marginBottom: 10,
			marginTop: 20
		},
		messageContainer: {
			marginTop: 10,
			backgroundColor: mixColors(RGBToHex(colors.card), '#1D79F2', dark ? 70 : 60),
			borderRadius: 9,
			padding: 10,
		},
		messageHeader: {
			color: colors.text,
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 'bold',
		}
	});

	var refreshHome = (refreshed=true)=>{
		if(refreshed) setRefresh(true);
		API.getHome().then(res=>{
			setRefresh(false);
			if(res.error) setError(res.message_client);
			setHome(res.data);
		}).catch(err=>{
			return setError('There was an unexpected error loading user\'s home.');
		});
	}

	var changeUsername = ()=>{
		props.navigation.push('ChangeUsername');
	}

	var showChangelog = ()=>{
		API.getChangelog().then(res=>{
			if(!res || res.error) return;
			props.navigation.push('Changelog', {
				version: res.version,
				title: res.title,
				contents: res.contents
			})
		}).catch(err=>{});
	}
	
	var showWelcome = async ()=>{
		API.getWelcome().then(res=>{
			if(!res || res.error) return showChangelog();
			props.navigation.push('Changelog', {
				version: '',
				title: 'Welcome to Yama',
				contents: res,
				onClose: showChangelog
			})
		}).catch(err=>{
			showChangelog();
		})
	}

	useEffect(()=>{
		refreshHome(false);
		API.getLogin().then(setUser);
		showWelcome();
		// props.navigation.push('EditManga', {
		// 	manga_id: 438
		// });
		// props.navigation.push('Profile', {
		// 	username: 'Draken'
		// })
	}, []);

	return (
		<SafeAreaView edges={['top', 'right', 'left']} style={StyleSheet.absoluteFill}>
			<ScrollView 
				contentContainerStyle={{ paddingHorizontal: 10, paddingBottom: 30 }}
				refreshControl={<RefreshControl refreshing={refresh} onRefresh={refreshHome} />}
			>
				<Text style={styles.containerText}>{user ? `Welcome back, ${user.username}` : 'Home'}</Text>
				{home ? (
					<View>
						{home.messages && home.messages.length>0 ? (
							<MessageCarrousel messages={home.messages} width={400} onPress={message=>{
								props.navigation.push('HomeMessage', { message })
							}} />
						) : null}
						{home.change_username ? (
							<View style={styles.messageContainer}>
								<Text style={styles.messageHeader}>Your account was created with a random username, change your username?</Text>
								<Button text='Change username' height={30} width={200} color='transparent' style={{ marginTop: 10 }} textColor={colors.text} onPress={changeUsername} />
							</View>
						) : null}
						{home.shelves && home.shelves.length>0 ? home.shelves.map(a=>(
							<View key={`shelf-${a.shelf_name}`}>
								<Text style={styles.shelfTitle}>{a.shelf_name}</Text>
								<BookCarrousel books={a.shelf_books} onPress={a=>{
									props.navigation.push('Book', a);
								}} />
							</View>
						))	: null}
					</View>
				) : (
					<LoadingHeader text="Loading home" error={error} retryButtonText={home ? 'Reload' : 'Retry'} retry onRetry={refreshHome}/>
				)}
			</ScrollView>
		</SafeAreaView>
	)
}

const styles = StyleSheet.create({
	title: {
		fontSize: 50,
		fontWeight: 'bold',
		textAlign: 'center'
	},
	subtitle: {
		fontSize: 20,
		textAlign: 'center'
	}
})