import React, { useState, useEffect, useRef } from 'react';
import { View, Alert, useColorScheme, StyleSheet, StatusBar, Platform } from 'react-native';
import { NavigationContainer, DarkTheme, DefaultTheme, useTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import * as SplashScreen from 'expo-splash-screen';
import * as Updates from 'expo-updates';
import * as Notifications from 'expo-notifications';
import { Modalize } from 'react-native-modalize';
import { isDevice } from 'expo-device';
import API from './API';

const DEBUG_SCREENS = {
	TABS: 'ProfileTab',
	HOME: 'Home',
	LIBRARY: 'Library',
	SEARCH: 'Search',
	PROFILE: 'AdminMenu',
	ADMIN: 'AdminTools',
}

import Login from './screens/Login'
import Register from './screens/Register';
import Home from './screens/Home'
import Settings from './screens/Settings';
import Scanner from './screens/Scanner';
import Book from './screens/Book';
import Series from './screens/Series';
import HomeMessage from './screens/HomeMessage';
import Library from './screens/Library';
import Profile from './screens/Profile';
import EditManga from './screens/EditManga';
import EditSeries from './screens/Admin/EditSeries';
import SelectionList from './screens/SelectionList';
import Search from './screens/Search';
import ImageCrop from './screens/ImageCrop';
import Connections from './screens/Settings/Connections';
import Password from './screens/Settings/Password';
import ForgotPassword from './screens/ForgotPassword';
import NotificationsScreen from './screens/Settings/Notifications';
import ChangeEmail from './screens/Settings/ChangeEmail';
import ChangeUsername from './screens/Settings/ChangeUsername';
import ChangeCover from './screens/Admin/ChangeCover';
import AdminTools from './screens/Admin/AdminTools';
import Scraper from './screens/Admin/Scraper';
import Incompletes from './screens/Admin/Incompletes';
import RedirectBook from './screens/Admin/RedirectBook';
import RecentManga from './screens/Admin/RecentManga';
import Duplicates from './screens/Admin/Duplicates';
import Changelog from './screens/Changelog';
import NewPublishers from './screens/Admin/NewPublishers';
import PublisherManga from './screens/Admin/PublisherManga';
import PublisherRedirect from './screens/Admin/PublisherRedirect';
import NewSeries from './screens/Admin/NewSeries';
import SeriesRedirect from './screens/Admin/SeriesRedirect';
import AdminBoxsets from './screens/Admin/AdminBoxsets';
import EditBoxset from './screens/Admin/EditBoxset';
import BoxsetAdd from './screens/BoxsetAdd';
import ProblemReport from './screens/ProblemReport';
import NewUsers from './screens/Admin/NewUsers';


Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false,
	}),
});

function tabIcon(icon, size, five){
	var Class = five ? FontAwesome5 : FontAwesome;
	return {
		tabBarIcon: ({ color })=>{
			return <Class name={icon} size={size || 22} color="black" style={{ marginTop: 5, color, height: 24 }} />
		},
		tabBarLabelStyle: { paddingBottom: 2 }
	}
}

async function getPushToken() {
	let token;
	if(isDevice){
		const { status: existingStatus } = await Notifications.getPermissionsAsync();
		let finalStatus = existingStatus;
		if (existingStatus!=='granted'){
			const { status } = await Notifications.requestPermissionsAsync();
			finalStatus = status;
		}
		if(finalStatus !== 'granted') return;
		token = (await Notifications.getExpoPushTokenAsync({ experienceId: '@sanchezand/yama' })).data;
		return token;
	}else{
		return false;
	}
}

var HomeTab = ()=>{
	const HomeStack = createStackNavigator();
	return (
		<HomeStack.Navigator initialRouteName={API.DEBUG ? DEBUG_SCREENS.HOME : 'Home'} screenOptions={{ headerShown: false }}>
			<HomeStack.Screen name="Home" component={Home} />
			<HomeStack.Screen name="HomeMessage" component={HomeMessage} options={{ headerShown: true }} />
			<HomeStack.Screen name="Book" component={Book} />
			<HomeStack.Screen name="Series" component={Series} />
			<HomeStack.Screen name="EditManga" component={EditManga} />
			<HomeStack.Screen name="EditSeries" component={EditSeries} />
			<HomeStack.Screen name="Library" component={Library} />
			<HomeStack.Screen name="Profile" component={Profile} initialParams={{ elementHeader: true }} />
			<HomeStack.Screen name="ProblemReport" component={ProblemReport} />
		</HomeStack.Navigator>
	)
}

var LibraryTab = ()=>{
	const LibraryStack = createStackNavigator();
	return (
		<LibraryStack.Navigator initialRouteName={API.DEBUG ? DEBUG_SCREENS.LIBRARY : 'Library'} screenOptions={{ headerShown: false }}>
			<LibraryStack.Screen name="Library" component={Library} />
			<LibraryStack.Screen name="Book" component={Book} />
			<LibraryStack.Screen name="Series" component={Series} />
			<LibraryStack.Screen name="EditManga" component={EditManga} />
			<LibraryStack.Screen name="EditSeries" component={EditSeries} />
			<LibraryStack.Screen name="Profile" component={Profile} initialParams={{ elementHeader: true }} />
			<LibraryStack.Screen name="ProblemReport" component={ProblemReport} />
		</LibraryStack.Navigator>
	)
}

var ProfileTab = ()=>{
	var [user, setUser] = useState(null);
	useEffect(()=>{
		API.getLogin().then(setUser);
	}, [])

	if(!user) return null;
	const ProfileStack = createStackNavigator();
	return (
		<ProfileStack.Navigator initialRouteName={API.DEBUG ? DEBUG_SCREENS.PROFILE : 'Profile'} screenOptions={{ headerShown: false }}>
			<ProfileStack.Screen name="Profile" component={Profile} options={{ ...tabIcon('user-circle'), headerShown: true }} initialParams={{
				user_id: user?.user_id,
				username: user?.username
			}} />
			<ProfileStack.Screen name="Book" component={Book} options={{ headerShown: false }} />
			<ProfileStack.Screen name="Series" component={Series} options={{ headerShown: false }} />
			<ProfileStack.Screen name="EditManga" component={EditManga} options={{ headerShown: false }} />
			<ProfileStack.Screen name="EditSeries" component={EditSeries} />
			<ProfileStack.Screen name="Library" component={Library} />
			<ProfileStack.Screen name="AdminMenu" component={AdminStack} />
			<ProfileStack.Screen name="ProblemReport" component={ProblemReport} />
		</ProfileStack.Navigator>
	)
}

var SearchTab = ()=>{
	const SearchStack = createStackNavigator();
	return (
		<SearchStack.Navigator initialRouteName={API.DEBUG ? DEBUG_SCREENS.SEARCH : 'Search'} screenOptions={{ headerShown: false }}>
			<SearchStack.Screen name="Search" component={Search} />
			<SearchStack.Screen name="Book" component={Book} options={{ headerShown: false }} />
			<SearchStack.Screen name="Series" component={Series} options={{ headerShown: false }} />
			<SearchStack.Screen name="EditManga" component={EditManga} options={{ headerShown: false }} />
			<SearchStack.Screen name="EditSeries" component={EditSeries} />
			<SearchStack.Screen name="Profile" component={Profile} initialParams={{ elementHeader: true }} />
			<SearchStack.Screen name="Library" component={Library} />
			<SearchStack.Screen name="ProblemReport" component={ProblemReport} />
		</SearchStack.Navigator>
	)
}

var AdminStack = ()=>{
	const AdminStack = createStackNavigator();
	return (
		<AdminStack.Navigator initialRouteName={API.DEBUG ? DEBUG_SCREENS.ADMIN : 'AdminTools'}>
			<AdminStack.Screen name="AdminTools" component={AdminTools} options={{ title: 'Admin Menu' }} />
			<AdminStack.Screen name="Scraper" component={Scraper} options={{ title: 'Populate Books' }} />
			<AdminStack.Screen name="Incompletes" component={Incompletes} options={{ title: 'Incomplete Manga' }} />
			<AdminStack.Screen name="Book" component={Book} options={{ headerShown: false }} />
			<AdminStack.Screen name="Series" component={Series} options={{ headerShown: false }} />
			<AdminStack.Screen name="EditManga" component={EditManga} options={{ headerShown: false }} />
			<AdminStack.Screen name="EditSeries" component={EditSeries} options={{ headerShown: false }} />
			<AdminStack.Screen name="ChangeCover" component={ChangeCover} options={{ headerShown: false }} />
			<AdminStack.Screen name="RedirectBook" component={RedirectBook} />
			<AdminStack.Screen name="RecentManga" component={RecentManga} />
			<AdminStack.Screen name="Duplicates" component={Duplicates} />
			<AdminStack.Screen name="NewPublishers" component={NewPublishers} />
			<AdminStack.Screen name="PublisherManga" component={PublisherManga} />
			<AdminStack.Screen name="PublisherRedirect" component={PublisherRedirect} />
			<AdminStack.Screen name="NewSeries" component={NewSeries} />
			<AdminStack.Screen name="SeriesRedirect" component={SeriesRedirect} />
			<AdminStack.Screen name="AdminBoxsets" component={AdminBoxsets} options={{ title: 'Boxsets' }} />
			<AdminStack.Screen name="EditBoxset" component={EditBoxset} />
			<AdminStack.Screen name="ProblemReport" component={ProblemReport} options={{ headerShown: false }} />
			<AdminStack.Screen name="NewUsers" component={NewUsers} />
		</AdminStack.Navigator>
	)
}

var Tabs = (props)=>{
	const Tab = createBottomTabNavigator();
	return (
		<Tab.Navigator initialRouteName={API.DEBUG ? DEBUG_SCREENS.TABS : 'HomeTab'} screenOptions={{ headerShown: false }}>
			<Tab.Screen name="HomeTab" component={HomeTab} options={{
				...tabIcon('home', null, true),
				tabBarLabel: 'Home',
			}} />
			<Tab.Screen name="LibraryTab" component={LibraryTab} options={{
				...tabIcon('book'),
				tabBarLabel: 'Library'
			}} />
			<Tab.Screen name="SearchTab" component={SearchTab} options={{
				...tabIcon('search'),
				tabBarLabel: 'Search'
			}} />
			<Tab.Screen name="ProfileTab" component={ProfileTab} options={{
				...tabIcon('user-circle'),
				tabBarLabel: 'Profile'
			}} />
		</Tab.Navigator>
	)
}

var App = (props)=>{
	var [loggedIn, setLoggedIn] = useState(null);
	var [user, setUser] = useState(null);
	var navRef = useRef(null);
	const APP_THEME = props.theme;

	if (Platform.OS === 'android') {
		StatusBar.setTranslucent(false);
		StatusBar.setBackgroundColor(APP_THEME.colors.background);
	}

	useEffect(()=>{
		try {
			SplashScreen.preventAutoHideAsync().catch(err=>{});
		} catch (e) {}
		verifyLogin();
		// checkUpdate();
		API.setOnLogout(()=>{
			setLoggedIn(false);
		});

		const subscription = Notifications.addNotificationResponseReceivedListener(response=>{});
		return ()=>{
			subscription.remove();
		}
	}, [])
	
	var checkUpdate = async ()=>{
		Updates.checkForUpdateAsync().then(update=>{
			if(update.isAvailable){
				Updates.fetchUpdateAsync().then(update=>{
					Alert.alert('Update found', 'Install update? This will only take a few seconds.', [
						{ style: 'default', text: 'Later' },
						{ style: 'cancel', text: 'Install', onPress: ()=>{
							Updates.reloadAsync();
						}}
					]);
				}).catch(err=>{ });
			}
		}).catch(err=>{})
	}

	var verifyLogin = async ()=>{
		var data = await API.getLogin();
		if(!data) setLoggedIn(false);
		else{
			setUser(data);
			setLoggedIn(true);
			API.getNotificationSettings(false).then(notif=>{
				if(notif.device_id || notif.disabled) return;
				getPushToken().then(token=>{
					if(!token) return;
					API.registerPushToken(token).catch(()=>{});
				}).catch(err=>{});
			}).catch(err=>{});
		}
		await SplashScreen.hideAsync();
	}

	var Stack = createStackNavigator();
	if(loggedIn===null){
		return <View />
	}else if(loggedIn===false){
		return <NavigationContainer theme={APP_THEME} ref={navRef}>
			<StatusBar barStyle={APP_THEME.dark ? 'light-content' : 'dark-content'} />
			<Stack.Navigator initialRouteName='Login'>
				<Stack.Screen name="Login" component={Login} options={{ headerShown: false }} initialParams={{ onLogin: verifyLogin }} />
				<Stack.Screen name="Register" component={Register} options={{ presentation: 'modal' }} initialParams={{ onLogin: verifyLogin }} />
				<Stack.Screen name="ForgotPassword" component={ForgotPassword} options={{ presentation: 'modal' }} initialParams={{ onLogin: verifyLogin }} />
				<Stack.Screen name="Settings" component={Settings} options={{ presentation: 'modal', headerShown: false }} />
			</Stack.Navigator>
		</NavigationContainer>
	}else{
		return <NavigationContainer theme={APP_THEME} ref={navRef}>
			<StatusBar barStyle={APP_THEME.dark ? 'light-content' : 'dark-content'} />
			<Stack.Navigator initialRouteName="Yama" screenOption={{ headerBackTitle: 'Back' }}>
				<Stack.Screen name="Yama" component={Tabs} options={{ headerShown: false }} initialParams={{ username: user.username }} />
				<Stack.Screen name="Settings" component={Settings} options={{ presentation: 'modal', headerShown: false }} />
				<Stack.Screen name="Scanner" component={Scanner} options={{ presentation: 'modal' }} />
				<Stack.Screen name="SelectionList" component={SelectionList} options={{ headerBackTitle: 'Back' }} />
				<Stack.Screen name="ProfilePictureCrop" component={ImageCrop} />
				<Stack.Screen name="Connections" component={Connections} options={{ headerShown: false, presentation: 'modal' }} />
				<Stack.Screen name="Password" component={Password} options={{ headerShown: false, presentation: 'modal' }} />
				<Stack.Screen name="Notifications" component={NotificationsScreen} options={{ headerShown: false, presentation: 'modal' }} />
				<Stack.Screen name="ChangeEmail" component={ChangeEmail} options={{ headerShown: false, presentation: 'modal' }} />
				<Stack.Screen name="ChangeUsername" component={ChangeUsername} options={{ headerShown: false, presentation: 'modal' }} />
				<Stack.Screen name="RedirectBook" component={RedirectBook} />
				<Stack.Screen name="ChangeCover" component={ChangeCover} options={{ headerShown: false }} />
				<Stack.Screen name="Changelog" component={Changelog} options={{ headerShown: false, presentation: 'modal' }} />
				<Stack.Screen name="BoxsetAdd" component={BoxsetAdd} options={{ headerShown: false }} />
			</Stack.Navigator>
		</NavigationContainer>
	}
}

export default ()=>{
	var modal = useRef(null);
	var [modalContent, setModalContent] = useState(null);
	var [modalHeader, setModalHeader] = useState(null);
	var scheme = useColorScheme();

	var DEFAULT_THEME = scheme=='dark' ? DarkTheme : DefaultTheme;
	const APP_THEME = {
		...DEFAULT_THEME,
		colors: {
			...DEFAULT_THEME.colors,
			primary: '#EF3341'
		},
		container: {
			backgroundColor: DEFAULT_THEME.colors.background
		}
	}

	var openModal = (component, header=null)=>{
		setModalContent(component);
		setModalHeader(header);
		modal.current?.open();
	}

	var closeModal = ()=>{
		modal.current?.close();
	}

	useEffect(()=>{
		if(modal){
			API.setModal(openModal, closeModal);
		}
	}, []);

	const styles = StyleSheet.create({
		modal: {
			backgroundColor: APP_THEME.colors.card,
			width: '100%',
			maxWidth: 600,
			alignSelf: 'center',
		}
	})

	return (
		<View style={StyleSheet.absoluteFill}>
			<ActionSheetProvider>
				<App openModal={openModal} closeModal={closeModal} theme={APP_THEME} />
			</ActionSheetProvider>
			<Modalize ref={modal} modalStyle={styles.modal} childrenStyle={{ paddingBottom: 20 }} adjustToContentHeight={true} HeaderComponent={modalHeader}>
				{modalContent}
			</Modalize>
		</View>
	)
}