import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { Button, HeaderScroll, Input, LoadingHeader } from '../../component';
import API from '../../API';
import * as Google from 'expo-auth-session/providers/google'
import * as AppleAuthentication from 'expo-apple-authentication';
import { validateEmail } from '../../Util';

const GOOGLE_CREDENTIALS = API.GOOGLE_CREDENTIALS;

var Password = props=>{
	var { colors, dark } = useTheme();
	var [user, setUser] = useState(null);
	var [connections, setConnections] = useState(null);
	var [password, setPassword] = useState('');
	var [email, setEmail] = useState('');
	var [loading, setLoading] = useState(false);

	const [request, response, requestGoogleAuth] = Google.useAuthRequest({	
		expoClientId: GOOGLE_CREDENTIALS.web.client,
		iosClientId: GOOGLE_CREDENTIALS.ios[GOOGLE_CREDENTIALS.is_dev ? 'dev' : 'live'],
		androidClientId: GOOGLE_CREDENTIALS.android,
		webClientId: GOOGLE_CREDENTIALS.web,
	});

	useEffect(()=>{
		if(user===null){
			API.getLogin().then(setUser);
		}
		if(connections===null){
			API.getConnections().then(res=>{
				if(res.error){
					Alert.alert('Error', 'Unexpected error loading user\'s email info.');
					return props.navigation.goBack();
				}
				setConnections(res.data);
			}).catch(err=>{
				Alert.alert('Error', 'Unexpected error loading user\'s email info.');
				props.navigation.goBack();
			})
		}

		if(response?.type==='success'){
			if(!response.authentication.idToken){
				return Alert.alert('Error', 'Unexpected error logging in with Google.');
			}
			sendChange(null, response.authentication.idToken);
		}
	}, [response]);

	var styles = StyleSheet.create({
		disclamer: {
			color: colors.text,
			textAlign: 'center',
			padding: 10
		}
	});

	var verifyForm = ()=>{
		if(connections.has_password && password.length<5){
			Alert.alert('Invalid password', 'Account\'s password must be at least 5 characters long.');
			return false;
		}
		if(!validateEmail(email)){
			Alert.alert('Invalid email', 'The new email is invalid.');
			return false;
		}
		return true;
	}
	
	var sendChange = (apple_auth=null, google_auth=null)=>{
		setLoading(true);
		API.changeEmail(email, password, apple_auth, google_auth).then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('Email has been changed');
			props.navigation.goBack();
		}).catch(err=>{
			return Alert.alert('Error', 'There was an error changing email (LCL-1).');
		})
	}
	
	var authPassword = ()=>{
		var valid = verifyForm();
		if(!valid) return;
		sendChange();
	}

	var authApple = ()=>{
		var valid = verifyForm();
		if(!valid) return;

		setLoading(true);
		AppleAuthentication.signInAsync({
			requestedScopes: [AppleAuthentication.AppleAuthenticationScope.EMAIL],
		}).then(creds=>{
			if(!creds || !creds.identityToken){
				setLoading(false);
				return Alert.alert('Error', 'Could not authenticate Apple account (LCL-2).');
			}
			sendChange(null, creds.identityToken);
		}).catch(err=>{
			setLoading(false);
			if(err.code === 'ERR_CANCELED') return;
			return Alert.alert('Error', 'There was an error authenticating Apple account (LCL-1).');
		});
	}

	var authGoogle = ()=>{
		var valid = verifyForm();
		if(!valid) return;
		requestGoogleAuth();
	}

	return (
		<HeaderScroll
			headerText='Change email'
		>
			{connections && user ? (
				<View>
					<Input label='Current email' value={user.email} editable={false} />
					<Input label='New email' value={email} onChangeText={setEmail} editable={!loading} keyboardType={'email-address'} autoCapitalize={'none'} autoCorrect={false} />
					{connections.has_password && <Input label='Password' value={password} onChangeText={setPassword} password editable={!loading} />}
					{loading ? (
						<LoadingHeader text='Changing email...' />
					) : !connections.has_password ? (
						<View>
							<Text style={styles.disclamer}>Since this account has no password, you will need to authenticate with the connected account.</Text>
							{Platform.OS=='ios' && connections.apple_auth ? (
								<AppleAuthentication.AppleAuthenticationButton
									buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
									buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle[dark ? 'WHITE' : 'BLACK']}
									cornerRadius={9}
									style={{ height: 50, marginTop: 5, marginHorizontal: 2 }}
									onPress={authApple}
								/>
							) : null}
							{connections.google_auth && <Button text={'Sign in with Google'} onPress={authGoogle} color={'#4285F4'} textColor={'white'} height={50} style={{ marginTop: 10 }} icon={'google'} />}
						</View>
					) : (
						<Button text="Change email" onPress={authPassword} style={{ marginTop: 20 }} />
					)}
				</View>
			) : (
				<LoadingHeader text='Loading email info...' />
			)}
		</HeaderScroll>
	)
}

export default Password;