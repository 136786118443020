import axios from 'axios';
import { Platform } from 'react-native';
import Storage from '@react-native-async-storage/async-storage';
import * as Network from 'expo-network';
import Constants from 'expo-constants';
import { isDevice } from 'expo-device';
import iap from 'expo-in-app-purchases';
import { hashCode } from './Util';

const DEBUG = process.env.NODE_ENV=='development';

const PROD_ROOT_URL = 'https://api.yamacollect.com/app/'
const DEBUG_ROOT_URL = 'http://127.0.0.1:5051/app/';
const API_VERSION = 'v1';
const ROOT_URL = DEBUG ? DEBUG_ROOT_URL : PROD_ROOT_URL;

const GOOGLE_CREDENTIALS = {
	is_dev: process.env.NODE_ENV=='development' && isDevice,
	ios: {
		live: '819814649529-sv4uacee5m923bffiee83he7m1djjnl5.apps.googleusercontent.com',
		dev: '819814649529-4j5stfsa3jtrsnb7kpotsg5tkrukbsas.apps.googleusercontent.com',
	},
	android: '819814649529-5elsjn82g256mfe6iv7ja2gqel96ncnl.apps.googleusercontent.com',
	web: {
		client: '819814649529-0m9tp6ea1jc6e0p5ri5077erps7ud5du.apps.googleusercontent.com',
		secret: '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',
	},
}

var onLogout = null;
var MODAL = null;

function setOnLogout(fn){
	onLogout = fn;
}

function setModal(open, close){
	MODAL = { open, close };
}

function openModal(component){
	if(!MODAL || !MODAL.open) return;
	MODAL.open(component);
}

function closeModal(){
	if(!MODAL || !MODAL.close) return;
	MODAL.close();
}

async function getLogin(){
	var d = await Storage.getItem('login');
	if(!d) return false;
	var u = JSON.parse(d);
	if(!u.user_id) return false;
	return u;
}

/**
 * Do a POST request to the API server.
 * @param {string} endpoint The endpoint to POST
 * @param {object} data The data to send to the endpoint
 */
 async function post(endpoint, data, version=false, timeout){
	try{
		var status = await Network.getNetworkStateAsync();
		if(!status.isConnected){
			return {
				error: true,
				code: 9999, 
				message_client: 'El dispositivo no tiene conexión a internet.'
			}
		}
	}catch(e){}

	var options = {
		timeout: timeout || 12000
	}
	var u = await getLogin();
	if(u){
		options.headers = {
			'yama-token': u.token
		}
	}

	try{
		var ENDPOINT = `${ROOT_URL}${version || API_VERSION}/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT, data);
		var res = await axios.post(ENDPOINT, data, options);
		if(res.status==429){
			return { error: true, message: 'Too many attempts', message_client: 'Too many tries, try again later!' };
		}
		// console.log(res.data);
		if(res.data && res.data.error && res.data.code==2000){
			// logout();
		}
		return res.data;
	}catch(err){
		if(DEBUG) console.log(err);
		if(err && err.message && err.message.toLowerCase().indexOf('network error')>=0){
			return { error: true, message: 'Request timeout reached.', message_client: 'Could not connect to server (LCLT-1).' };
		}
		if(err && err.message && err.message.indexOf('timeout')>=0){
			return { error: true, message: 'Request timeout reached.', message_client: 'Request timed out (LCLT-1).' };
		}
		if(err && err.response && err.response.status && err.response.status==429){
			return { error: true, message: 'Too many attempts', message_client: 'Too many tries, try again later!' };
		}
		return {
			error: true, message: 'Unexpected error (LCL-1)', message_client: 'Unexpected error (LCL-1)'
		}
	}
}

var getSettings = async ()=>{
	var login = await getLogin();
	return { login }
}

var getSettingKey = async (key)=>{
	return await Storage.getItem(key);
}

var setSetting = async (key, val)=>{
	if(typeof val === 'object') val = JSON.stringify(val);
	return await Storage.setItem(key, val.toString());
}

var clearSetting = (key)=>{
	return Storage.removeItem(key);
}

var getNotificationSettings = async (api=false)=>{
	var d = await Storage.getItem('notifications');
	if(!d) return { disabled: false, device_id: null };
	try{
		var local = JSON.parse(d);
		return {
			disabled: local.disabled,
			device_id: local.device_id,
		};
	}catch(e){
		return { disabled: false, device_id: null };
	}
}

var registerPushToken = async (token)=>{
	var notif = await getNotificationSettings();
	var payload = {
		token,
		device_name: Constants.deviceName,
		platform: Platform.OS
	};
	var res = await post('push/register', payload);
	if(res.error || !res.data || !res.data.device_id) return res;
	await setSetting('notifications', {
		...notif,
		disabled: false,
		device_id: res.data.device_id
	});
	return res;
}

var disablePushToken = async ()=>{
	var notif = await getNotificationSettings();
	var res = await post('push/disable', { device_id: notif.device_id });
	if(res.error) return res;
	await setSetting('notifications', {
		disabled: true,
		device_id: null
	});
	return res;
}

var logout = async ()=>{
	await Storage.removeItem('login');
	if(onLogout) onLogout();
	try{
		await disablePushToken();
		await clearSetting('notifications');
	}catch(e){}
	return true;
}

var recacheUser = async (user, force)=>{
	try{
		var login = await getLogin();
		if(!login) login = {};
		if(force || (login && login.user_id && user.user_id==login.user_id)){
			if(force) login.user_id = user.user_id;
			if(user.token) login.token = user.token
			if(user.username) login.username = user.username;
			if(user.email) login.email = user.email;
			if(user.date_created) login.date_created = user.date_created;
			if(user.user_type) login.user_type = user.user_type;
			if(user.email_confirm) login.email_confirm = user.email_confirm;
			if(user.apple_auth) login.apple_auth = user.apple_auth;
			if(user.google_auth) login.google_auth = user.google_auth;
			if(user.tagline) login.tagline = user.tagline;
			if(user.private) login.private = user.private;
			await setSetting('login', login);
		}
	}catch(e){}
}

var loginUser = async (endpoint, payload)=>{
	var res = await post(endpoint, payload);
	if(!res.error && res.data.user){
		var user = res.data.user;
		await recacheUser(user, true);
	}
	return res;
}

var refreshUser = async ()=>{
	return loginUser('verify');
}

var login = async (username, password)=>{
	return loginUser('login', { username, password });
}

var loginGoogle = async (google_auth)=>{
	return loginUser('login/google', { google_auth });
}

var loginApple = async (apple_auth)=>{
	return loginUser('login/apple', { apple_auth });
}

var register = async (email, username, password)=>{
	return loginUser('register', { email, username, password });
}

var registerApple = async (token, username, email)=>{
	return loginUser('register/apple', { apple_auth: token, username, email });
}

var registerGoogle = async (token, username, email)=>{
	return loginUser('register/google', { google_auth: token, username, email });
}

var getUser = async (user_id, shelves=false, reviews=false, stats=false)=>{
	var res = await post('users/get', { 
		user_id: user_id || null, 
		shelves: shelves ? 1 : 0,
		reviews: reviews ? 1 : 0,
		stats: stats ? 1 : 0,
	});
	if(res.error) return res;
	recacheUser(res.data.user);
	return res;
}

var getUsername = async (username, shelves=false, reviews=false)=>{
	var res = await post('users/get', { 
		username,
		shelves: shelves ? 1 : 0,
		reviews: reviews ? 1 : 0
	});
	if(res.error) return res;
	recacheUser(res.data.user);
	return res;
}

var getLibrary = async (user_id=null, query, count=50, offset=0, user_info=false)=>{
	return post('users/library', { 
		user_id,
		query,
		count,
		offset,
		user_info: user_info ? 1 : 0
	});
}

var getWishlist = (user_id=null)=>{
	return post('users/wishlist', {
		user_id
	});
}

var searchManga = (query, check_own)=>{
	return post('manga/search', { query, check_own: check_own ? 1 : 0, language: 'EN' });
}

var getISBN = async (isbn, check_own=false)=>{
	return post('manga/isbn', { isbn, check_own });
}

var getManga = async (manga_id, ownership, series)=>{
	return post('manga/get', { 
		manga_id,
		ownership: ownership ? 1 : 0,
		series: series ? 1 : 0
	});
}

var getMultipleManga = async (mangas)=>{
	return post('admin/manga/multiple', { mangas, series: 1 });
}

var addManga = (manga)=>{
	return post('users/manga/add', { manga });
}

var sendMangaOwnership = (manga_id, wanted, read, owned)=>{
	return post('users/manga/ownership', {
		manga_id, 
		owned: owned ? 1 : 0,
		wanted: wanted ? 1 : 0,
		read: read ? 1 : 0,
	});
}

var getSeries = (series_id, user_id=null)=>{
	return post('manga/series/get', { series_id, user_id });
}

var getSeriesInfo = (series_id)=>{
	return post('admin/series/get', { series_id });
}

var getSeriesManga = (series_id, count=null)=>{
	return post('admin/series/manga', { series_id, count });
}

var getHome = ()=>{
	return post('feed/shelves');
}

var blacklistBook = (manga_id, blacklist=true)=>{
	return post('admin/manga/blacklist', { manga_id, blacklist: blacklist ? 1 : 0 });
}

var editManga = (manga_id, data)=>{
	return post('admin/manga/edit', { manga_id, data });
}

var editSeries = (series_id, series_name, publisher_id, redirect)=>{
	return post('admin/series/edit', { 
		series_id, 
		series_name, 
		publisher_id,
		redirect: redirect ? 1 : 0
	});
}

var searchAll = (query, count=30, offset=0, users=true, manga=true)=>{
	return post('search', { query, count, offset, users, manga });
}

var setProfilePicture = (manga_id, crop)=>{
	return post('profile/edit/picture', {
		manga_id, crop
	});
}

var changeUsername = (new_username)=>{
	return post('profile/edit/username', { username: new_username });
}

var setVisibility = (visibility)=>{
	return post('profile/edit/visibility', { visibility });
}

var getConnections = ()=>{
	return post('profile/connections');
}

var connectApple = token=>{
	return post('profile/connections/apple/connect', { identity_token: token });
}

var disconnectApple = ()=>{
	return post('profile/connections/apple/remove');
}

var connectGoogle = token=>{
	return post('profile/connections/google/connect', { token });
}

var disconnectGoogle = ()=>{
	return post('profile/connections/google/remove');
}

var changePassword = (new_password, old_password=null, apple_auth=null, google_auth=null)=>{
	return post('profile/password', { new_password, old_password, apple_auth, google_auth });
}

var recoverAccount = (email)=>{
	return post('recover/send', { email });
}

var sendRecoverCode = (email, code, password)=>{
	return post('recover', { email, code, password });
}

var changeEmail = async (email, password=null, apple_auth=null, google_auth=null)=>{
	var res = await post('profile/email', { email, password, apple_auth, google_auth });
	if(!res.error){
		await refreshUser();
	}
	return res;
}

var banUser = async (user_id, reason)=>{
	return post('admin/users/ban', { user_id, reason });
}

var unbanUser = async (user_id)=>{
	return post('admin/users/unban', { user_id });
}

var getCoverImages = async (isbn)=>{
	return post('admin/cover/isbn/get', { isbn });
}

var changeCover = (isbn, url)=>{
	return post('admin/cover/isbn/set', { isbn, hash: hashCode(url) });
}

var scrape = (query, source)=>{
	return post('admin/scrape', { query, source }, false, 20000);
}

var addISBN = (isbn, source=null)=>{
	return post('admin/isbn/add', { isbn, source });
}

var getIncompletes = (only_english=false)=>{
	return post('admin/manga/incompletes', { only_english });
}

var markMangaComplete = (manga_id, complete)=>{
	return post('admin/manga/complete', { manga_id, complete });
}

var redirectManga = (from_manga, to_manga)=>{
	return post('admin/manga/redirect', { from_manga, to_manga });
}

var getRecentManga = (offset=0, count=50)=>{
	return post('admin/manga/recent', { offset, count });
}

var getDuplicates = ()=>{
	return post('admin/manga/duplicates');
}

var getWelcome = async (check=true)=>{
	if(check){
		var ch = await getSettingKey('welcome');
		if(ch) return null;
	}
	var res = await post('welcome');
	if(res.error) return null;
	await setSetting('welcome', '1');
	return res.data;
}

var getChangelog = async (check=true)=>{
	var version = Constants.manifest.version;
	if(check){
		var ch = await getSettingKey(`changelog-${version}`);
		if(ch) return null;
	}
	var res = await post('changelog', { version });
	if(res.error) return null;
	await setSetting(`changelog-${version}`, '1');
	return res.data;
}

var getUnverifiedPublishers = ()=>{
	return post('admin/publisher/unverified');
}

var getPublisherManga = (publisher_id)=>{
	return post('admin/publisher/manga', { publisher_id });
}

var setPublisherVerified = (publisher_id, verified=true)=>{
	return post('admin/publisher/verify', { publisher_id, verified: verified ? 1 : 0 });
}

var setPublisherBlacklist = (publisher_id, blacklist=true)=>{
	return post('admin/publisher/blacklist', { publisher_id, blacklist: blacklist ? 1 : 0 });
}

var getRedirectPublishers = (publisher_id)=>{
	return post('admin/publisher/redirect/options', { publisher_id });
}

var redirectPublisher = (from_publisher, to_publisher)=>{
	return post('admin/publisher/redirect', {
		from: from_publisher,
		to: to_publisher
	});
}

var getRecentSeries = (search=null, offset=0, count=50)=>{
	return post('admin/series/recent', { search, offset, count });
}

var getRedirectSeries = (series_id)=>{
	return post('admin/series/redirect/options', { series_id });
}

var redirectSeries = (from_series, to_series)=>{
	return post('admin/series/redirect', {
		from: from_series,
		to: to_series
	});
}

var getRecentBoxsets = (query=null, offset=0, count=50)=>{
	return post('admin/boxset/recent', { offset, count, query });
}

var getBoxset = (boxset_id, volumes=false, ownership=false)=>{
	return post('boxset/get', {
		boxset_id,
		volumes: volumes ? 1 : 0,
		ownership: ownership ? 1 : 0,
	});
}

var getSeriesVolumes = (series_id)=>{
	return post('manga/series/volumes', { series_id });
}

var editBoxset = (boxset_id, series_id, boxset_number=null, boxset_part=nul, volumes)=>{
	return post('admin/boxset/edit', {
		boxset_id,
		series_id,
		boxset_number,
		boxset_part,
		volumes
	});
}

var blacklistBoxset = (boxset_id, blacklist)=>{
	return post('admin/boxset/blacklist', { boxset_id, blacklist: blacklist ? 1 : 0 });
}

var completeBoxset = (boxset_id, completed)=>{
	return post('admin/boxset/complete', { boxset_id, completed: completed ? 1 : 0 });
}

var addBoxset = (boxset_id, disabled_books)=>{
	return post('users/boxset/add', { boxset_id, disabled_books });
}

var getProblems = (type)=>{
	return post('problems/types', { type });
}

var sendProblem = async (manga_id, series_id, boxset_id, problem_type, description)=>{
	if((manga_id ? 1 : 0)+(series_id ? 1 : 0)+(boxset_id ? 1 : 0)!=1) return { error: true, message_client: 'Invalid problem data (LCLA-1)' }
	if(description && description.length>=120) return { error: true, message_client: 'Problem description must be max 120 characters.' }
	return post('problems/new', {
		manga_id, series_id, boxset_id,
		problem_type, description
	})
}

var getAdminProblems = async (offset=0, count=50)=>{
	return post('admin/problems', { offset, count });
}

var getRecentUsers = (count=50, offset=0)=>{
	return post('admin/users/recent', { count, offset });
}

var verifySeriesInfo = (series_id)=>{
	return post('admin/series/verify', { series_id });
}

var getSuspiciousPublishers = (count, offset)=>{
	return post('admin/publisher/suspicious', { count, offset });
}

export default {
	addBoxset,
	addISBN,
	addManga,
	banUser,
	blacklistBook,
	blacklistBoxset,
	changeCover,
	changeEmail,
	changePassword,
	changeUsername,
	clearSetting,
	closeModal,
	completeBoxset,
	connectApple,
	connectGoogle,
	DEBUG,
	disablePushToken,
	disconnectApple,
	disconnectGoogle,
	editBoxset,
	editManga,
	editSeries,
	getBoxset,
	getChangelog,
	getConnections,
	getCoverImages,
	getDuplicates,
	getHome,
	getIncompletes,
	getISBN,
	getLibrary,
	getLogin,
	getManga,
	getMultipleManga,
	getNotificationSettings,
	getProblems,
	getPublisherManga,
	getRecentBoxsets,
	getRecentManga,
	getRecentSeries,
	getRecentUsers,
	getRedirectPublishers,
	getRedirectSeries,
	getSeries,
	getSeriesInfo,
	getSeriesManga,
	getSeriesVolumes,
	getSettingKey,
	getSettings,
	getSuspiciousPublishers,
	getUnverifiedPublishers,
	getUser,
	getUsername,
	getWelcome,
	getWishlist,
	GOOGLE_CREDENTIALS,
	login,
	loginApple,
	loginGoogle,
	logout,
	markMangaComplete,
	openModal,
	post,
	recoverAccount,
	redirectManga,
	redirectPublisher,
	redirectSeries,
	refreshUser,
	register,
	registerApple,
	registerGoogle,
	registerPushToken,
	scrape,
	searchAll,
	searchManga,
	sendMangaOwnership,
	sendProblem,
	sendRecoverCode,
	setModal,
	setOnLogout,
	setProfilePicture,
	setPublisherBlacklist,
	setPublisherVerified,
	setSetting,
	setVisibility,
	unbanUser,
	verifySeriesInfo,
}