import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert, Linking } from 'react-native';
import * as Notifications from 'expo-notifications';
import API from '../../API';
import { Button, HeaderScroll, LoadingHeader, SettingItem } from '../../component';
import { isDevice } from 'expo-device';

var Screen = props=>{
	var [settings, setSettings] = useState(null);
	var [notificationPermission, setNotificationPermission] = useState(null);
	var [loading, setLoading] = useState({
		general: false
	});

	useEffect(()=>{
		API.getNotificationSettings(true).then(setSettings)
		Notifications.getPermissionsAsync().then(async status=>{
			if (status.status!=='granted'){
				const notifstat = await Notifications.requestPermissionsAsync();
				status = notifstat.status;
			}
			setNotificationPermission(status.status=='granted');
		})
	}, []);

	var changeAll = (val)=>{
		setLoading({ ...loading, general: true });
		if(val){
			Notifications.getExpoPushTokenAsync({ experienceId: '@sanchezand/yama' }).then(res=>{
				if(!res.data){
					setLoading({ ...loading, general: false })
					return Alert.alert('Error', 'Unexpected error enabling notifications (LCL-NOTF-3).');
				}
				API.registerPushToken(res.data).then(res=>{
					setLoading({ ...loading, general: false })
					if(res.error) return Alert.alert('Error', res.message_client);
					setSettings({
						disabled: false,
						device_id: res.data.device_id
					})
				}).catch(err=>{
					setLoading({ ...loading, general: false })
					return Alert.alert('Error', 'Unexpected error enabling notifications (LCL-NOTF-4).');
				})
			}).catch(err=>{
				setLoading({ ...loading, general: false });
				Alert.alert('Error', 'Unexpected error enabling notifications (LCL-NOTF-2).');
			})
		}else{
			Alert.alert('Disable notifications', 'Disable all notifications on this device?', [
				{ style: 'cancel', text: 'Cancel' },
				{ style: 'destructive', text: 'Disable all', onPress: ()=>{
					API.disablePushToken().then(res=>{
						if(res.error) return Alert.alert('Error', res.message_client);
						setLoading({ ...loading, general: false });
						setSettings({
							disabled: true,
							device_id: null
						})
						return Alert.alert('Notifications disabled');
					}).catch(err=>{
						setLoading({ ...loading, general: false })
						Alert.alert('Error', 'Unexpected error disabling notifications (LCL-NOTF-1).');
					})
				}}
			]);
		}
	}

	var styles = StyleSheet.create({
		footerText: {
			textAlign: 'center',
			color: 'gray',
			marginTop: 10
		}
	});

	return (
		<HeaderScroll
			headerText='Notifications'
			containerTextStyle={{ paddingHorizontal: 15 }}
			scrollStyle={{ paddingHorizontal: 0 }}
		>
			{!isDevice ? (
				<LoadingHeader icon='times-circle' iconSolid loading={false} text='This device is not compatible with notifications.' />
			) : settings===null || notificationPermission===null ? (
				<LoadingHeader />
			) : notificationPermission==false ? (
				<View>
					<LoadingHeader icon='times-circle' iconSolid loading={false} text='Enable notifications on settings to enable notifications for Yama.' style={{ paddingHorizontal: 10 }} />
					<Button text='Open settings' color='gray' width={200} style={{ marginTop: 20 }} onPress={()=>Linking.openSettings()} />
				</View>
			) : (
				<View>
					<SettingItem text='Enable Notifications' onToggle={changeAll} value={!!(!settings.disabled && settings.device_id)} loading={loading.general} />
					{settings.device_id ? <Text style={styles.footerText}>Device: {settings.device_id}</Text> : null}
				</View>
			)}
		</HeaderScroll>
	)
}

export default Screen;