import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, RefreshControl, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { SettingItem, LoadingHeader, Input } from '../../component'
import PropTypes from 'prop-types';
import API from '../../API';
import moment from 'moment';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { getAllIndexes } from '../../Util';

var NewSeries = props=>{
	var { colors, dark } = useTheme();
	var { suspicious } = (props.route.params || {});
	var [series, setSeries] = useState(null);
	var [defaultSeries, setDefaultSeries] = useState(null);
	var [refreshing, setRefreshing] = useState(false);
	var [searchValue, setSearchValue] = useState('')
	var [searching, setSearching] = useState(false);
	var [searchTimeout, setSearchTimeout] = useState(null);
	var { showActionSheetWithOptions } = useActionSheet();

	var getData = (query=null)=>{
		if(!query) setRefreshing(true);
		var fn = suspicious===true ? API.getSuspiciousPublishers(50, 0) : API.getRecentSeries(query, 0, 50)
		
		fn.then(res=>{
			setRefreshing(false);
			setSearching(false);
			if(res.error){
				Alert.alert('Error', res.message_client);
				if(series===null) props.navigation.goBack();
				return;
			}
			if(!query) setDefaultSeries(res.data);
			setSeries(res.data)
		}).catch(err=>{
			setRefreshing(false);
			setSearching(false);
			Alert.alert('Error', 'Unexpected error loading series (LCL-1)');
			if(series===null) props.navigation.goBack();
		})
	}

	useEffect(()=>{
		getData()
	}, []);

	if(!series){
		return <LoadingHeader text='Loading series' />
	}

	var styles = StyleSheet.create({
		badgeContainer: {
			backgroundColor: colors.border,
			width: 35,
			height: 22,
			borderRadius: 100
		},
		badgeText: {
			color: colors.text,
			textAlign: 'center',
			marginTop: 3,
			fontWeight: 'bold'
		},
	});

	var viewManga = (series)=>{
		props.navigation.push('Series', {
			series: {
				series_id: series.series_id,
				series_name: series.series_name,
				authors: [],
			},
			owned: false,
		})
	}

	var redirectSeries = (srs)=>{
		props.navigation.push('SeriesRedirect', {
			series: srs,
			onRedirect: (from, to)=>{
				srs.redirected = true;
				setSeries([
					...series.filter(a=>a.series_id!=srs.series_id),
					srs
				])
			}
		})
	}

	var blacklistSeries = ()=>{

	}

	var editSeries = (srs)=>{
		props.navigation.push('EditSeries', {
			series_id: srs.series_id,
			onEdit: (edit_series)=>{
				var new_series = [...series];
				var ix = new_series.findIndex(a=>a.series_id==edit_series.series_id);
				if(ix==-1) return;
				new_series[ix].series_id = edit_series.new_series_id;
				new_series[ix].series_name = edit_series.series_name;
				new_series[ix].publisher_id = edit_series.publisher_id;
				new_series[ix].publisher_name = edit_series.publisher_name;
				setSeries(new_series);
			}
		})
	}

	var showOptions = (series)=>{
		return ()=>{
			var options = [
				{ title: 'View Mangas', onPress: viewManga },
				{ title: 'Redirect Series', onPress: redirectSeries },
				{ title: 'Edit Series', onPress: editSeries },
				{ title: `${series.blacklist ? 'Unblacklist' : 'Blacklist'} Series`, destructive: !series.blacklist, onPress: blacklistSeries },
			];
			showActionSheetWithOptions({
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
				destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
			}, ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress(series);
			});
		}
	}

	var onSearchChange = (val)=>{
		setSearchValue(val);
		if(val.length>4){
			setSearching(true);
			if(searchTimeout) clearTimeout(searchTimeout);
			setSearchTimeout(setTimeout(()=>{
				getData(val);
			}, 500));
		}else{
			setSeries(defaultSeries);
			setSearching(false);
			if(searchTimeout) clearTimeout(searchTimeout);
		}
	}

	return (
		<View style={StyleSheet.absoluteFill}>
			{!suspicious ? (
				<Input height={40} placeholder="Search series..." icon="search" clearableBlurred clearable value={searchValue} onChangeText={onSearchChange} loading={searching} autoCorrect={false} />
			) : null}
			<FlatList
				style={{ flex: 1 }}
				data={series.sort((a,b)=>b.date_created-a.date_created)}
				keyExtractor={(a,i)=>`key-${a.series_id}-${i}`}
				keyboardDismissMode={'on-drag'}
				renderItem={({ item })=>(
					<SettingItem 
						key={`key-${item.series_id}`}
						grayed={(item.redirected || item.blacklist) ? true : false}
						grayedContainer={(item.redirected || item.blacklist) ? true : false}
						text={item.series_name} 
						onPress={showOptions(item)}
						metadata={[
							item.redirected ? 'REDIRECTED' : null,
							`${item.books} Book${item.books==1 ? '' : 's'}`,
							`[${item.publisher_id}] ${item.publisher_name}`,
							`Created: ${moment.unix(item.date_created).format('YYYY-MM-DD HH:mm')}`
						]}
						contentsRight={item.related ? (
							<View style={styles.badgeContainer}>
								<Text style={styles.badgeText}>{item.related}</Text>
							</View>
						) : <View />}
					/>
				)}
				refreshControl={
					<RefreshControl refreshing={refreshing} onRefresh={()=>getData(null)} />
				}
				// flatListProps={{
				// 	onEndReached: loadMore,
				// 	removeClippedSubviews: true,
				// 	onEndReachedThreshold: 0.3,
				// 	keyboardDismissMode: 'on-drag'
				// }}
			/>
		</View>
	)
}

export default NewSeries;