import React, { useState, useEffect, useRef } from 'react';
import { Text, View, StyleSheet, ActivityIndicator, Alert, Platform } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import * as Updates from 'expo-updates';
import API from '../API';
import { SettingItem, Button, HeaderScroll, Input } from '../component';
import { useTheme } from '@react-navigation/native';
import { getShade, RGBToHex } from '../Util';
import { Modalize } from 'react-native-modalize';
import moment from 'moment';
import Constants from 'expo-constants';

export default props=>{
	var { colors, dark } = useTheme();

	var [settings, setSettings] = useState(false);
	var [user, setUser] = useState(null);
	var [username, setUsername] = useState('');
	var [checkingUpdate, setCheckingUpdate] = useState(false);
	var [hasUpdate, setHasUpdate] = useState(false);
	var [downloading, setDownloading] = useState(false);
	var [finishDownload, setFinishDownloading] = useState(false);
	var [refreshingUser, setRefreshingUser] = useState(false);
	var [modalMode, setModalMode] = useState(null);
	var [modalTitle, setModalTitle] = useState('Settings');
	var [sending, setSending] = useState(false);
	var [visibility, setVisibility] = useState(false);
	var [loadingChangelog, setLoadingChangelog] = useState(false);
	var modalRef = useRef(null);

	useEffect(()=>{
		API.getSettings().then(sett=>{
			setSettings(sett);
			setUser(sett.login);
			setUsername(sett.login.username);
			setVisibility(!sett.login.private);
		});
		checkUpdates(false);
	}, [])

	var logout = ()=>{
		Alert.alert('Logout', 'Are you sure you want to logout?', [
			{ style: 'cancel', text: 'Cancel' },
			{ style: 'destructive', text: 'Logout', onPress: ()=>{
				API.logout().then(()=>{
					props.navigation.reset({
						index: 0,
						routes: [{ name: 'Login' }]
					})
				});
			} }
		])
	}

	var checkUpdates = (manual=true)=>{
		if(checkingUpdate) return;
		setCheckingUpdate(true);
		Updates.checkForUpdateAsync().then(update=>{
			setCheckingUpdate(false);
			if(update.isAvailable){
				setHasUpdate(true);
			}else if(manual){
				Alert.alert('Update applied', 'You\'re on the latest version of Yama!');
			}
		}).catch(err=>{
			setCheckingUpdate(false);
			if(manual){
				Alert.alert('Error', 'Unexpected error checking for update (LCL-UPDA-1)');
			}
		});
	}

	var downloadUpdate = ()=>{
		setDownloading(true);
		Updates.fetchUpdateAsync().then(()=>{
			setFinishDownloading(true)
			Alert.alert('Apply update', 'Apply update? This will only take a few seconds.', [
				{ style: 'cancel', text: 'Cancel' },
				{ style: 'default', text: 'Apply', onPress: ()=>{
					applyUpdate();
				} }
			]);
		}).catch(err=>{
			setDownloading(false);
			Alert.alert('Error', 'Unexpected error downloading update (LCL-UPDW-1).');
		});
	}

	var applyUpdate = ()=>{
		Updates.reloadAsync();
	}

	var refreshUser = ()=>{
		if(refreshingUser) return;
		setRefreshingUser(true);
		API.refreshUser().then(res=>{
			setUser(res.data.user);
			setRefreshingUser(false);
		}).catch(err=>{
			setRefreshingUser(false);
		})
	}

	const styles = StyleSheet.create({
		sectionHeader: {
			backgroundColor: dark ? colors.border : '#E7E5E7',
			color: getShade(RGBToHex(colors.text), dark ? -10 : 20),
			paddingHorizontal: 15,
			paddingVertical: 5,
			fontWeight: 'bold'
		},
		modalStyle: {
			backgroundColor: colors.card,
		},
		modalTitle: {
			padding: 15,
			fontSize: 20,
			backgroundColor: dark ? colors.border : colors.background,
			fontWeight: 'bold',
			color: colors.text,
			overflow: 'hidden'
		},
		toggleButton: {
			marginBottom: 10,
			borderRadius: 100,
			width: 200,
			alignSelf: 'center',
		},
		version: {
			textAlign: 'center',
			color: 'gray',
		},
		badgeContainer: {
			width: 40,
			height: 25,
			borderRadius: 100,
			backgroundColor: colors.primary,
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center',
		},
	});

	var showConnections = ()=>{
		props.navigation.push('Connections');
	}

	var showNotifications = ()=>{
		props.navigation.push('Notifications');
	}

	var showPassword = ()=>{
		props.navigation.push('Password');
	}

	var showChangeEmail = ()=>{
		props.navigation.push('ChangeEmail');
	}

	var openModal = (title, mode)=>{
		return ()=>{
			setModalTitle(title)
			setModalMode(mode);
			modalRef.current?.open();
		}
	}

	var changeVisibility = (vis)=>{
		return ()=>{
			if(vis==visibility) return;
			Alert.alert(`Change your profile\'s visibility to ${vis ? 'public' : 'private'}?`, null, [
				{ text: 'Cancel', style: 'cancel' },
				{ text: 'Change', style: 'default', onPress: ()=>{
					setVisibility(vis);
					setSending(true);
					API.setVisibility(vis).then(res=>{
						setSending(false);
						if(res.error){
							setVisibility(!vis);
							return Alert.alert('Error', res.message_client);
						}
						try{
							API.getUser(user.user_id, false, false);
						}catch(e){}
						Alert.alert(`Your Yama profile has been set to ${vis ? 'public' : 'private'}!`);
						setUser({
							...user,
							private: vis
						});
						modalRef.current?.close();
					})
				}}
			])
		}
	}

	var showChangelog = ()=>{
		setLoadingChangelog(true);
		API.getChangelog(false).then(res=>{
			setLoadingChangelog(false);
			if(!res || res.error) return Alert.alert('No changelog found.');
			props.navigation.push('Changelog', {
				version: res.version,
				title: res.title,
				contents: res.contents
			})
		}).catch(err=>{
			setLoadingChangelog(false);
			return Alert.alert('No changelog found.');
		})
	}

	var changeUsername = ()=>{
		props.navigation.push('ChangeUsername');
	}

	var badge = (icon, size=16)=>{
		return <View style={styles.badgeContainer}>
			<FontAwesome5 name={icon} size={size} color={'white'} />
		</View>
	}
	
	return (
		<View style={StyleSheet.absoluteFill}>
			<HeaderScroll
				headerText='Settings'
				scrollStyle={{ paddingHorizontal: 0 }}
				containerTextStyle={{ paddingHorizontal: 15 }}
				modal={true}
			>
				{user ? (
					<View>
						<Text style={[styles.sectionHeader, { marginTop: 0 }]}>User</Text>
						<SettingItem icon="user-tag" text='Change username' onPress={changeUsername} />
						<SettingItem icon="user-secret" text='Change profile visibility' onPress={openModal('Change profile visibility', 1)} />
						<SettingItem icon="link" text='Account connections' onPress={showConnections} />
						<SettingItem icon="key" text='Change password' onPress={showPassword} />
						<SettingItem icon="at" text='Change email' onPress={showChangeEmail} />
					</View>
				) : null}

				<Text style={styles.sectionHeader}>General</Text>
				{settings ? (
					<View>
						{user ? (
							<View>
								<SettingItem icon="bell" text='Notification settings' onPress={showNotifications} iconSolid />
								<SettingItem icon="sync" onPress={refreshUser} loading={refreshingUser} text='Refresh User' />
							</View>
						) : null}
						<SettingItem icon="newspaper" text='View changelog' onPress={showChangelog} loading={loadingChangelog} iconSolid />
						{hasUpdate ? (
							!downloading ? (
								<SettingItem icon="code" onPress={downloadUpdate} text='Update available!' contentsRight={badge('exclamation', 14)} />
							) : !finishDownload ? (
								<SettingItem icon="code" text='Downloading new update...' loading={true} />
							) : (
								<SettingItem icon="code" onPress={applyUpdate} text='Apply update' contentsRight={badge('check')} />
							)
						) : (
							<SettingItem icon="code" onPress={checkUpdates} loading={checkingUpdate} text='Check for updates' />
						)}
						{user ? (
							<Button text="Logout" onPress={logout} style={{ marginTop: 30, borderRadius: 200, alignSelf: 'center' }} width={200} height={40} />
						) : null}
					</View>
				) : (
					<ActivityIndicator size='large' style={{ marginTop: 20 }} />
				)}
				<Text style={[styles.version, { marginTop: 10 }]}>Version {Constants.manifest.version}</Text>
				{Updates.updateId ? <Text style={styles.version}>Update {Updates.updateId}</Text> : null}
				{Updates.createdAt ? <Text style={styles.version}>Release {moment(Updates.createdAt).format('YYYY-MM-DD HH:mm')}</Text> : null}
				<Text style={styles.version}>Platform {Platform.OS.toUpperCase()}</Text>
			</HeaderScroll>
			<Modalize
				ref={modalRef}
				adjustToContentHeight={true}
				modalStyle={styles.modalStyle}
				HeaderComponent={
					<Text style={styles.modalTitle}>{modalTitle}</Text>
				}
				panGestureEnabled={!sending}
				closeOnOverlayTap={!sending}
				withHandle={!sending}
			>
				<View style={{ padding: 15 }}>
					{modalMode==1 ? (
						<View>
							<Button toggle text="Public" toggleValue={visibility} style={styles.toggleButton} onPress={changeVisibility(true)} loading={sending} />
							<Button toggle text="Private" toggleValue={!visibility} style={styles.toggleButton} onPress={changeVisibility(false)} loading={sending} />
						</View>
					) : null}
				</View>
				<View style={{ height: 40 }} />
			</Modalize>
		</View>
	)
}