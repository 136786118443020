import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, TextInput, Text, View, ActivityIndicator, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';
import { getShade, RGBToHex } from '../Util'

var Input = props=>{
	var { colors, dark } = useTheme();
	var [focused, setFocused] = useState(false);
	var inputRef = useRef();

	const styles = StyleSheet.create({
		input: {
			width: '100%',
			height: props.height || (50+(props.metadata ? 10 : 0)),
			borderRadius: 9,
			alignSelf: 'center', 
			alignItems: 'center',
			paddingHorizontal: 10,
			flexDirection: 'row',
			borderWidth: StyleSheet.hairlineWidth,
			backgroundColor: colors.card, 
			borderColor: colors.border 
		},
		inputError: {
			backgroundColor: dark ? '#C7382D52' : '#FDBFBF',
			borderColor: dark ? '#C7382DAA' : '#FDAEAE',
		},
		icon: {
			marginRight: 10,
			marginLeft: 2,
			color: '#BBB'
		},
		textInput: {
			fontSize: 16, 
			color: colors.text, 
		},
		fullTextInput: {
			flex: 1, 
			height: '100%',
		},
		container: {
			paddingVertical: 5,
		},
		label: {
			fontWeight: 'bold',
			fontSize: 14,
			marginBottom: 5,
			color: dark ? colors.text : '#404040'
		},
		text: {
			color: colors.text,
			// flex: 1,
			fontSize: 16, 
		},
		multiline: {
			padding: 10
		},
		metadata: {
			fontSize: 12,
			color: 'gray',
			// marginTop: -10
		}
	});

	var onFocus = ()=>{
		setFocused(true);
		if(props.onFocus) props.onFocus();
	}

	var onBlur = ()=>{
		setFocused(false);
		if(props.onBlur) props.onBlur();
	}

	var clearText = ()=>{
		props.onChangeText('')
		if(props.onClear) props.onClear();
	}

	var placeholderColor = getShade(RGBToHex(colors.text), (dark ? -50 : 500));
	return (
		<View style={[styles.container, props.style]}>
			{props.label ? <Text style={styles.label}>{props.label}</Text> : null}
			<TouchableWithoutFeedback onPress={props.onPress}>
				<View style={[styles.input, props.multiline ? styles.multiline : {}, props.errored ? styles.inputError : {}, props.containerStyle]}>
					{props.icon && !props.loading ? (
						<FontAwesome5 name={props.icon || "search"} style={styles.icon} />
					) : props.loading ? (
						<ActivityIndicator size='small' style={{ marginRight: 4 }} color={colors.text} />
					) : null}
					<View style={{ flex: 1 }}>
						{props.onPress ? (
							<TouchableOpacity onPress={props.onPress} style={[styles.textInput, !props.metadata ? styles.fullTextInput : {}, { alignItems: 'center', flexDirection: 'row' }]}>
								{props.value ? (
									<Text style={styles.text}>{props.value ? props.value.toString() : props.value}</Text>
								) : (
									<Text style={[styles.text, { color: placeholderColor || 'gray' }]}>{props.placeholder || props.label}</Text>
								)}
							</TouchableOpacity>
						) : (
							<TextInput 
								multiline={props.multiline===true} 
								ref={props.inputRef} 
								placeholder={props.placeholder || props.label} 
								placeholderTextColor={placeholderColor}
								onChangeText={props.onChangeText} 
								value={props.value ? props.value.toString() : null} 
								keyboardAppearance={props.keyboardAppearance} 
								keyboardType={props.keyboardType} 
								autoCapitalize={props.autoCapitalize} 
								autoCorrect={props.autoCorrect} 
								onSubmitEditing={props.onSubmitEditing} 
								editable={props.editable} 
								secureTextEntry={props.password} 
								onFocus={onFocus} 
								onBlur={onBlur}
								style={[styles.textInput, !props.metadata ? styles.fullTextInput : {}]}
								maxLength={props.maxLength}
							/>
						)}
						{props.metadata ? (
							<Text style={styles.metadata}>{props.metadata}</Text>
						) : null}
					</View>
					{props.clearable!==false && props.value && props.value.length>0 && (focused || props.clearableBlurred) && !props.multiline ? (
						<TouchableOpacity onPress={clearText}>
							<FontAwesome5 name={'times-circle'} solid style={[styles.icon, { color: dark ? 'gray' : '#BBBBBB' }]} size={16} />
						</TouchableOpacity>
					) : null}
					{props.iconRight ? (
						<FontAwesome5 name={props.iconRight || "search"} style={styles.icon} />
					) : null}
				</View>
			</TouchableWithoutFeedback>
		</View>
	)
}

Input.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	onChangeText: PropTypes.func,
	value: PropTypes.any,
	icon: PropTypes.string,
	iconRight: PropTypes.string,
	keyboardAppearance: PropTypes.oneOf(['default', 'light', 'dark']),
	keyboardType: PropTypes.oneOf(['default', 'email-address', 'numeric', 'phone-pad', 'number-pad', 'decimal-pad']),
	autoCapitalize: PropTypes.oneOf(['none', 'sentences', 'words', 'characters']),
	autoCorrect: PropTypes.bool,
	onSubmitEditing: PropTypes.func,
	containerStyle: PropTypes.object,
	style: PropTypes.object,
	editable: PropTypes.bool,
	password: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onPress: PropTypes.func,
	multiline: PropTypes.bool,
	height: PropTypes.number,
	errored: PropTypes.bool,
	loading: PropTypes.bool,
	clearable: PropTypes.bool,
	clearableBlurred: PropTypes.bool,
	inputRef: PropTypes.any,
	maxLength: PropTypes.number,
}

export default Input;