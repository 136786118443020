import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useTheme } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { formatSeconds } from '../Util';

var Countdown = props=>{
	var { colors, dark } = useTheme();
	var [seconds, setSeconds] = useState(null);

	useEffect(()=>{
		if(!props.unix) return;
		var t = Math.max(props.unix-Math.floor(new Date().getTime()/1000), 0);
		setSeconds(t);
		if(t<=0){
			if(props.onFinish) props.onFinish();
			return
		}
		
		const ct = setInterval(()=>{
			var secs = Math.max(props.unix-Math.floor(new Date().getTime()/1000), 0)
			setSeconds(secs);
			if(secs<=0){
				if(ct) clearInterval(ct);
				if(props.onFinish) props.onFinish();
			}
		}, 1000)

		return ()=>{
			if(ct)clearInterval(ct);
		}
	}, [props.unix]);

	var styles = StyleSheet.create({
		container: {
			flexDirection: 'row',
			justifyContent: 'space-around'
		},
		value: {
			textAlign: 'center',
			fontSize: 32,
			fontWeight: 'bold',
			color: colors.text,
			width: 60,
		},
		label: {
			textAlign: 'center',
			fontSize: 14,
			color: colors.text,
		}
	});

	var format = formatSeconds(seconds);
	return (
		<View style={[styles.container, props.style]}>
			<View>
				<Text style={styles.value}>{format.d}</Text>
				<Text style={styles.label}>Days</Text>
			</View>
			<View>
				<Text style={styles.value}>{format.h}</Text>
				<Text style={styles.label}>Hours</Text>
			</View>
			<View>
				<Text style={styles.value}>{format.m}</Text>
				<Text style={styles.label}>Minutes</Text>
			</View>
			<View>
				<Text style={styles.value}>{format.s}</Text>
				<Text style={styles.label}>Seconds</Text>
			</View>
		</View>
	)
}

Countdown.propTypes = {
	unix: PropTypes.number,
	style: PropTypes.object,
}

export default Countdown;