const mixColors = (color_1, color_2, weight)=>{
	function d2h(d) { return d.toString(16); }  // convert a decimal value to hex
	function h2d(h) { return parseInt(h, 16); } // convert a hex value to decimal 
	
	weight = (typeof(weight) !== 'undefined') ? weight : 50; // set the weight to 50%, if that argument is omitted
	
	var color = "#";
	
	for(var i = 0; i <= 5; i += 2) { // loop through each of the 3 hex pairs—red, green, and blue
		var v1 = h2d(color_1.substr(i+1, 2)), // extract the current pairs
		v2 = h2d(color_2.substr(i+1, 2)),
		
		// combine the current pairs from each source color, according to the specified weight
		val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0))); 
		
		while(val.length < 2) { val = '0' + val; } // prepend a '0' if val results in a single digit
		
		color += val; // concatenate val to our new color string
	}
	return color; // PROFIT!
};

function RGBToHex(rgb) {
	let sep = rgb.indexOf(",") > -1 ? "," : " ";
	rgb = rgb.substr(4).split(")")[0].split(sep);
	
	let r = (+rgb[0]).toString(16),
	g = (+rgb[1]).toString(16),
	b = (+rgb[2]).toString(16);
	
	if (r.length == 1) r = "0" + r;
	if (g.length == 1) g = "0" + g;
	if (b.length == 1) b = "0" + b;
	return "#" + r + g + b;
}

function addCommas(num, fixed=true) {
	if(!num && num!==0) return 'ERR';
	var c = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	var dec = c.substring(-2, 2);
	return fixed ? (dec=='00' && fixed!==true ? c.slice(0, -3) : c) : c.slice(0, -3);
}

// https://stackoverflow.com/a/11868398
const getColorContrast = (hc, black, white)=>{
	const [r, g, b] = [0, 2, 4].map(p=>parseInt(hc.substr(p, 2), 16)); 	
	return ((r * 299) + (g * 587) + (b * 114)) / 1000 >= 128 ? (black || '#000000') : (white || '#FFFFFF'); 
}

var getShade = (color, percent)=>{
	if(!color) color = '#EF3341';
	if(color.length==6) color = '#'+color;
	
	var R = parseInt(color.substring(1,3),16);
	var G = parseInt(color.substring(3,5),16);
	var B = parseInt(color.substring(5,7),16);
	
	R = parseInt(R * (100 + percent) / 100);
	G = parseInt(G * (100 + percent) / 100);
	B = parseInt(B * (100 + percent) / 100);
	
	R = (R<255)?R:255;
	G = (G<255)?G:255; 
	B = (B<255)?B:255;
	
	var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
	var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
	var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
	
	return "#"+RR+GG+BB;
}

function getAllIndexes(arr, val) {
	var ix = [];
	for(var i = 0; i<arr.length; i++){
		if (arr[i] === val) ix.push(i);
	}
	return ix;
}

function containImage(view_width, view_height, img_width, img_height) {
	var aspect = img_width / img_height;
	if(aspect>=view_width / view_height){
		return {
			width: view_width,
			height: view_width / aspect
		}
	} else {
		return {
			width: view_height * aspect, 
			height: view_height
		}
	}
}

function randomNumber(min, max){
	return Math.floor(Math.random() * (max - min + 1) + min);
}

const validateUsername = (username)=>{
	var reg = /^[a-z]([a-z0-9\-_]{3,15})$/gi;
	return reg.test(username);
}

function validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

function hashCode(str){
	var hash = 0, i, chr;
	if(str.length === 0) return hash;
	for(i = 0; i < str.length; i++) {
		chr   = str.charCodeAt(i);
		hash  = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}

const timeout = (ms)=>{
	return new Promise((resolve, reject)=>{
		setTimeout(resolve, ms);
	})
}

var formatSeconds = (seconds)=>{
	var d = ('0'+Math.floor(((seconds/60)/60)/24)).slice(-2);
	var h = ('0'+Math.floor((seconds/60)/60)%24).slice(-2);
	var m = ('0'+Math.floor((seconds/60)%60)).slice(-2);
	var s = ('0'+Math.floor((seconds%60))).slice(-2);

	return { d, h, m, s };
}

export {
	addCommas,
	containImage,
	getAllIndexes,
	getColorContrast,
	getShade,
	hashCode,
	mixColors,
	randomNumber,
	formatSeconds,
	RGBToHex,
	timeout,
	validateEmail,
	validateUsername,
}