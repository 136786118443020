import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View, ScrollView, Dimensions } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';

const ReviewCarrousel = props=>{
	var { colors } = useTheme()
	var onPress = props.onPress || null;
	var { width } = Dimensions.get('window');
	var containerWidth = width-50;

	if(!props.reviews || !Array.isArray(props.reviews) || props.reviews.length==0){
		return null;
	}

	if(props.reviews.length==1){
		containerWidth = width-20;
	}

	const styles = StyleSheet.create({
		container: {
			width: '100%',
			borderRadius: 9,
			backgroundColor: colors.card,
			padding: 15,
			height: 200,
			width: containerWidth,
			marginRight: 10
		},
		contentContainer: {
			flexDirection: 'row',
			overflow: 'visible',
			paddingRight: 5,
		},
		reviewTitle: {
			fontSize: 16,
			fontWeight: 'bold',
			marginBottom: 5,
			color: colors.text,
		},
		reviewMeta: {
			color: 'gray',
			fontSize: 14,
		}
	})

	var Rating = props=>{
		var stars = []
		for(var i=0; i<props.rating; i++){
			stars.push(<FontAwesome5 name="star" key={'rating-'+i} solid size={16} color={colors.primary} style={{ marginRight: 2 }} />);
		}
		for(var i=0; i<(5-props.rating); i++){
			stars.push(<FontAwesome5 name="star" key={'ratingn-'+i} size={16} color={'gray'} style={{ marginRight: 2 }} />);
		}
		return <View style={{ flexDirection: 'row' }}>{stars}</View>;
	}
	
	return (
		<View style={[{ width: '100%' }, props.style]}>
			<ScrollView 
				contentContainerStyle={[styles.contentContainer]} 
				horizontal={true}
				snapToStart={true}
				snapToInterval={(containerWidth-10)}
				snapToAlignment={'center'}
				decelerationRate={0}
				showsHorizontalScrollIndicator={false}
				style={{ overflow: 'visible' }}
			>
				{props.reviews.map(a=>(
					<TouchableOpacity key={'review-'+a.manga_id} style={[styles.container]}>
						<View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
							<Text style={styles.reviewTitle}>{a.manga_name}</Text>
							<Text style={styles.reviewMeta}>1y ago</Text>
						</View>
						<Rating rating={a.rating} />
						<Text style={{ marginTop: 10, color: colors.text }} numberOfLines={7}>{a.review_text}</Text>
					</TouchableOpacity>
				))}
			</ScrollView>
		</View>
	)
}

ReviewCarrousel.propTypes = {
	style: PropTypes.object,
	onPress: PropTypes.func,
	loading: PropTypes.bool,
	reviews: PropTypes.array,
}

export default ReviewCarrousel;
