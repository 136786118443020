import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { SettingItem } from '../../component';
import { getShade, RGBToHex } from '../../Util';

var AdminTools = props=>{
	var { colors, dark } = useTheme();

	var showScreen = (screen, opts)=>{
		return ()=>{
			props.navigation.push(screen, opts);
		}
	}

	const styles = StyleSheet.create({
		sectionHeader: {
			backgroundColor: dark ? colors.border : '#E7E5E7',
			color: getShade(RGBToHex(colors.text), dark ? -10 : 20),
			paddingHorizontal: 15,
			paddingVertical: 5,
			fontWeight: 'bold'
		},
	})

	return (
		<ScrollView>
			{/* <SettingItem text='Problem Report' icon='exclamation-triangle' onPress={showScreen('AdminProblems')} /> */}
			<Text style={styles.sectionHeader}>Manga</Text>
			<SettingItem text='Recent Additions' icon='clock' onPress={showScreen('RecentManga')} />
			<SettingItem text='Populate Books' icon='book-medical' onPress={showScreen('Scraper')} />
			<SettingItem text='Incomplete Manga' icon='book-dead' onPress={showScreen('Incompletes')} />
			<SettingItem text='Duplicate Manga' icon='copy' onPress={showScreen('Duplicates')} />
			<SettingItem text='Boxsets' icon='box' onPress={showScreen('AdminBoxsets')} />
			{/* <SettingItem text='Questionable Manga' icon='book' icon2='question' onPress={showScreen('Duplicates')} /> */}
			<Text style={styles.sectionHeader}>Users</Text>
			<SettingItem text='New Users' icon="user-circle" iconSolid={true} onPress={showScreen('NewUsers')} />
			<Text style={styles.sectionHeader}>Publishers</Text>
			<SettingItem text='New Publishers' icon="clock" onPress={showScreen('NewPublishers')} />
			<SettingItem text='Suspicious Publishers' icon="meh" iconSolid onPress={showScreen('NewSeries', { suspicious: true })} />
			<Text style={styles.sectionHeader}>Series</Text>
			<SettingItem text='New Series' icon="clock" onPress={showScreen('NewSeries')} />
		</ScrollView>
	)
}

export default AdminTools;