import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, SafeAreaView, View, Alert, ActivityIndicator, Platform } from 'react-native';
import API from '../API';
import * as Google from 'expo-auth-session/providers/google';
import Button from '../component/Button';
import * as AppleAuthentication from 'expo-apple-authentication';
import { useTheme } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';

const GOOGLE_CREDENTIALS = API.GOOGLE_CREDENTIALS;

export default (props)=>{
	var [loading, setLoading] = useState(false);
	var { onLogin } = props.route.params;
	var { colors, dark } = useTheme();

	const [request, response, authGoogle] = Google.useAuthRequest({	
		expoClientId: GOOGLE_CREDENTIALS.web.client,
		iosClientId: GOOGLE_CREDENTIALS.ios[GOOGLE_CREDENTIALS.is_dev ? 'dev' : 'live'],
		androidClientId: GOOGLE_CREDENTIALS.android,
		webClientId: GOOGLE_CREDENTIALS.web,
	});

	var login = ()=>{
		props.navigation.navigate('Register', {
			register: false,
			ask_email: true
		});
	}

	var loginApple = (token, email)=>{
		setLoading(true);
		API.loginApple(token).then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			if(!res.data.registered){
				props.navigation.navigate('Register', {
					register: true,
					apple_auth: token,
					ask_email: res.data.ask_email,
					email,
				});
			}else{
				if(onLogin) onLogin();
			}
		}).catch(err=>{
			return Alert.alert('Error', 'There was an error logging in with Apple. (LCL)');
		})
	}

	var loginGoogle = (token)=>{
		setLoading(true);
		API.loginGoogle(token).then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			if(!res.data.registered){
				props.navigation.navigate('Register', {
					register: true,
					google_auth: token,
					ask_email: res.data.ask_email,
					email: res.data.email
				});
			}else{
				if(onLogin) onLogin();
			}
		}).catch(err=>{
			return Alert.alert('Error', 'There was an error logging in with Google. (LCL)');
		})
	}

	var authApple = ()=>{
		AppleAuthentication.signInAsync({
			requestedScopes: [AppleAuthentication.AppleAuthenticationScope.EMAIL],
		}).then(creds=>{
			setLoading(true);
			loginApple(creds.identityToken, creds.email)
		}).catch(err=>{
			if(err.code === 'ERR_CANCELED') return;
			return Alert.alert('Error', 'There was an error logging in with Apple.');
		})
	}

	var showSettings = ()=>{
		props.navigation.push('Settings');
	}

	useEffect(()=>{
		if(response?.type === 'success'){
			const { authentication } = response;
			if(!authentication.idToken){
				return Alert.alert('Error', 'Unexpected error logging in with Google.');
			}
			loginGoogle(authentication.idToken)
		}
	}, [response]);
	
	return (
		<SafeAreaView style={[StyleSheet.absoluteFill, styles.container]}>
			<LinearGradient colors={[
				'#f85032', '#e73827',
			]} start={{ x: 0.3, y: 0 }} end={{ x: 0.8, y: 1 }} style={StyleSheet.absoluteFill} />
			<View style={{ paddingTop: 50 }}>
				<Text style={[styles.subtitle]}>Welcome to</Text>
				<Text style={[styles.title]}>Yama</Text>
			</View>
			<View style={{ flex: 1 }}></View>
			{loading ? (
				<View style={{ marginBottom: 20 }}>
					<ActivityIndicator size={'large'} color='white' />
					<Text style={{ marginTop: 10, textAlign: 'center', fontSize: 20, fontWeight: 'bold', color: 'white' }}>Signing in...</Text>
				</View>
			) : (
				<View style={styles.buttonContainer}>
					<Button text={'Continue with Email'} onPress={login} color={'white'} textColor={'black'} height={50} size={18} />
					<Button text={'Sign in with Google'} onPress={authGoogle} color={'#4285F4'} textColor={'white'} height={50} style={{ marginTop: 5 }} icon={'google'} />
					{Platform.OS=='ios' ? (
						<AppleAuthentication.AppleAuthenticationButton
							buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
							buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
							cornerRadius={9}
							style={{ height: 50, marginTop: 5 }}
							onPress={authApple}
						/>
					) : null}
					<Button text={'Settings'} onPress={showSettings} color={'transparent'} textColor={'white'} height={30} style={{ marginTop: 10 }} size={14} />
				</View>
			)}
		</SafeAreaView>
	)
}

const styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		paddingVertical: 10
	},
	title: {
		fontSize: 80,
		fontWeight: 'bold',
		textAlign: 'center',
		color: 'white',
	},
	subtitle: {
		fontSize: 24,
		color: 'white',
		textAlign: 'center'
	},
	buttonContainer: {
		padding: 10,
		maxWidth: 400,
		width: '100%',
		alignSelf: 'center',
	}
})