import React from 'react';
import { StyleSheet, TouchableOpacity, View, ActivityIndicator, Text } from 'react-native';
import { FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';

const HeaderIcon = props=>{
	var { colors } = useTheme()
	var fontSize = (props.size || 24);
	var textColor = (props.color || colors.primary);
	var onPress = (props.onPress && !props.loading) ? props.onPress : null;
	var IconFamily = props.material ? MaterialCommunityIcons : props.five ? FontAwesome5 : FontAwesome;


	const styles = StyleSheet.create({
		container: {
			width: props.width || '100%',
			height: 35,
			borderRadius: 9,
			alignSelf: 'center', 
			alignItems: 'center',
			justifyContent: 'center',
		},	
		text: {
			marginLeft: 5,
			fontWeight: 'bold',
		},
		background: {
			backgroundColor: (props.backgroundColor || colors.border),
			borderRadius: 100,
			height: 30,
			padding: 5,
			alignItems: 'center',
			justifyContent: 'center',
		}
	});

	return (
		<View style={[styles.container, props.background ? styles.background : {}, props.style]}>
			<TouchableOpacity onPress={onPress} style={[{ flexDirection: 'row', alignItems: 'center' }]}>
				{props.loading ? (
					<ActivityIndicator size={'large'} color={textColor} />
				) : (
					<IconFamily name={props.icon} size={fontSize} color={textColor} solid={true} />
				)}
				{props.text ? (
					<Text style={[styles.text, { color: textColor }]}>{props.text}</Text>
				) : null}
			</TouchableOpacity>
		</View>
	)
}

HeaderIcon.propTypes = {
	style: PropTypes.object,
	onPress: PropTypes.func,
	color: PropTypes.string,
	size: PropTypes.number,
	icon: PropTypes.string,
	material: PropTypes.bool,
	solid: PropTypes.bool,
	text: PropTypes.string,
	width: PropTypes.number,
	loading: PropTypes.bool,
	background: PropTypes.bool,
}

export default HeaderIcon;