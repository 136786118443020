import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { BookItem, Button, Input, LoadingHeader } from '../../component';
import { FontAwesome5 } from '@expo/vector-icons';
import ISBNLib from 'isbn3';
import moment from 'moment';
import API from '../../API';

const SELECTED_COLORS = {
	dark: '#192319',
	light: '#D8FFD9',
}

var RedirectBook = props=>{
	var { colors, dark } = useTheme();
	var [defaultOptions, setDefaultOptions] = useState(null);
	var [reverse, setReverse] = useState(false);
	var [selectedManga, setSelectedManga] = useState(null);
	var [origin, setOrigin] = useState(null);

	var SELECTED_COLOR = dark ? SELECTED_COLORS.dark : SELECTED_COLORS.light;

	var { manga, onRedirect, from, to, autoBack } = props.route.params;

	var styles = StyleSheet.create({
		disclamer: {
			fontSize: 16,
			fontWeight: 'bold',
			color: colors.text,
			textAlign: 'center',
			marginTop: 10
		}
	});

	var parseManga = (m)=>{
		return {
			manga_id: m.manga_id,
			series: m.series,
			volume_start: m.volume_start,
			volume_end: m.volume_end,
			authors: m.authors.map(a=>a.mangaka_name),
			image: m.image,
			dimensions: (m.book_height ? 1 : 0)+(m.book_width ? 1 : 0)+(m.book_thick ? 1 : 0),
			description_length: m.description ? m.description.length : 0,
			date_created: m.date_created,
			date_release: m.date_release,
			isbn: m.isbn
		}
	}

	var getMangaScore = (manga)=>{
		var score = 0;
		if(manga.series && manga.series.series_id) score++;
		if(manga.volume_start) score++;
		if(manga.volume_end) score++;
		if(manga.authors.length>0) score++;
		if(manga.image) score++
		if(manga.dimensions){
			if(manga.dimensions==0){
				score -= 1;
			}else{
				score += manga.dimensions;
			}
		}
		if(manga.description_length) score += manga.description_length>30 ? 1 : 0;
		return score;
	}

	var getFromTo = ()=>{
		API.getMultipleManga([from, to]).then(res=>{
			if(res.error){
				Alert.alert('Error', res.message_client);
				return props.navigation.goBack();
			}
			var score1 = getMangaScore(parseManga(res.data[0]));
			var score2 = getMangaScore(parseManga(res.data[1]));

			if(score1>score2){
				setSelectedManga(parseManga(res.data[0]));
				setOrigin(res.data[1]);
			}else{
				setSelectedManga(parseManga(res.data[1]));
				setOrigin(res.data[0]);
			}
		}).catch(err=>{
			Alert.alert('Error', 'Unexpected error getting redirect mangas (LCL-2)');
		})
	}

	useEffect(()=>{
		if(manga){
			props.navigation.setOptions({
				title: manga.manga_name
			})
			API.searchAll(`${manga.series.series_name} ${manga.volume_start}`.trim(), 20, 0, false, true).then(res=>{
				if(res.error) return Alert.alert('Error', res.message_client);
				setDefaultOptions(res.data.manga.filter(a=>a.manga_id!=manga.manga_id));
			})
		}else if(from && to){
			getFromTo();
		}
	}, []);

	var redirect = ()=>{
		var from_manga = origin.manga_id;
		var to_manga = selectedManga.manga_id;
		if(reverse){
			var temp = from_manga;
			from_manga = to_manga;
			to_manga = temp;
		}
		Alert.alert('Confirm', `Redirect ${reverse ? 'Bottom' : 'Top'} to ${reverse ? 'Top' : 'Bottom'}\nManga ${from_manga} to ${to_manga}`, [
			{ style: 'cancel', text: 'Cancel' },
			{ style: 'default', text: 'Apply', onPress: ()=>{
				API.redirectManga(from_manga, to_manga).then(res=>{
					if(res.error) return Alert.alert('Error', res.message_client);
					if(onRedirect) onRedirect(from_manga, to_manga);
					if(autoBack){
						return props.navigation.goBack();
					}else{
						return Alert.alert('Success', 'Manga redirected!');
					}
				}).catch(err=>{
					Alert.alert('Error', 'Unexpected error redirecting manga (LCL-1)');
				})
			}}
		])
	}

	if(!origin){
		return <LoadingHeader text='Loading' />
	}

	return (
		<ScrollView
			contentContainerStyle={{ paddingHorizontal: 10 }}
		>
			<BookItem 
				series 
				color={!reverse ? null : SELECTED_COLOR}
				book={{
					manga_name: origin.series.series_name,
					volume_start: origin.volume_start,
					volume_end: origin.volume_end,
					authors: origin.authors.map(a=>a.mangaka_name),
					image: origin.image,
					metadata: [
						`MangaID: ${origin.manga_id}`,
						`${(origin.book_height ? 1 : 0)+(origin.book_width ? 1 : 0)+(origin.book_thick ? 1 : 0)}/3 Dimensions`,
						`${origin.description ? origin.description.length : 0} Description`,
						`Created: ${moment.unix(origin.date_created).format('YYYY-MM-DD')}`,
						`Release: ${moment.unix(origin.date_release).format('YYYY-MM-DD')}`,
						`ISBN: ${origin.isbn}`,
						`ISBN Group: ${ISBNLib.parse(origin.isbn).groupname}`
					]
				}} 
				metadataStyle={{ marginTop: 5 }} 
			/>
			{(!defaultOptions || !origin) && !selectedManga ? (
				<LoadingHeader text='Loading' />
			) : (
				<View>
					<FontAwesome5 name={reverse ? 'arrow-up' : 'arrow-down'} color={'white'} style={{ textAlign: 'center', marginVertical: 10, fontSize: 40, color: 'gray' }} />
					{!selectedManga ? (
						<View>
							<Input placeholder='Search manga...' icon="search" />
							{defaultOptions.map(a=>(
								<BookItem 
									series
									book={{
										manga_name: a.series_name,
										volume_start: a.volume_start,
										volume_end: a.volume_end,
										authors: a.authors,
										image: a.image,
										metadata: [
											`MangaID: ${a.manga_id}`,
											`${a.dimensions}/3 Dimensions`,
											`${a.description_length} Description`,
											`Created: ${moment.unix(a.date_created).format('YYYY-MM-DD')}`,
											`Release: ${moment.unix(a.date_release).format('YYYY-MM-DD')}`,
											`ISBN: ${a.isbn}`,
											`ISBN Group: ${ISBNLib.parse(a.isbn).groupname}`
										]
									}} 
									key={`manga-select-${a.manga_id}`} 
									metadataStyle={{ marginTop: 5 }} 
									onPress={()=>setSelectedManga(a)} 
								/>
							))}
						</View>
					) : (
						<View>
							<BookItem 
								series
								color={reverse ? null : SELECTED_COLOR}
								book={{
									manga_name: (selectedManga.series_name || selectedManga.series.series_name),
									volume_start: selectedManga.volume_start,
									volume_end: selectedManga.volume_end,
									authors: selectedManga.authors,
									image: selectedManga.image,
									metadata: [
										`MangaID: ${selectedManga.manga_id}`,
										`${selectedManga.dimensions}/3 Dimensions`,
										`${selectedManga.description_length} Description`,
										`Created: ${moment.unix(selectedManga.date_created).format('YYYY-MM-DD')}`,
										`Release: ${moment.unix(selectedManga.date_release).format('YYYY-MM-DD')}`,
										`ISBN: ${selectedManga.isbn}`,
										`ISBN Group: ${ISBNLib.parse(selectedManga.isbn).groupname}`
									]
								}} 
								key={`manga-select-${selectedManga.manga_id}`} 
								metadataStyle={{ marginTop: 5 }}
							/>
							<Text style={styles.disclamer}>{reverse ? 'Bottom' : 'Top'} will redirect to {reverse ? 'Top' : 'Bottom'}</Text>
							<View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'center' }}>
								<Button width={200} text="Redirect" height={40} onPress={redirect} style={{ marginRight: 10 }} />
								<Button width={40} icon="sync" five height={40} onPress={()=>setReverse(!reverse)} iconStyle={{ marginRight: 0 }} color={'#1D79F3'} />
							</View>
						</View>
					)}
				</View>
			)}
		</ScrollView>
	)
}

export default RedirectBook;