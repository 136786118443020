import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet, Alert, Switch, Image, Keyboard, Linking } from 'react-native';
import API from '../API';
import { mixColors, RGBToHex } from '../Util';
import { HeaderScroll, Input, HeaderIcon, LoadingHeader } from '../component';
import { useNavigation, useTheme } from '@react-navigation/native';
import { useActionSheet, connectActionSheet } from '@expo/react-native-action-sheet';

var EditManga = props=>{
	var { colors, dark } = useTheme();
	var navigation = useNavigation();
	// props.route.params = {
	// 	manga_id: 381	
	// }
	var { manga: propsManga, manga_id: propsMangaId, onEdit, autoBack } = props.route.params;
	if(!propsManga) propsManga = {};
	
	var [loading, setLoading] = useState(true);
	var [manga, setManga] = useState(propsManga);
	var [manga_id, setMangaId] = useState(propsManga.manga_id || propsMangaId);
	var [manga_name, setMangaName] = useState(propsManga.manga_name);
	var [mangakas, setMangakas] = useState(propsManga.authors);
	var [manga_description, setMangaDescription] = useState(propsManga.description);
	var [series, setSeries] = useState(propsManga.series)
	var [volume_start, setVolumeStart] = useState(propsManga.volume_start ? propsManga.volume_start.toString() : '');
	var [volume_end, setVolumeEnd] = useState(propsManga.volume_end ? propsManga.volume_end.toString() : '');
	var [mangaImage, setMangaImage] = useState(propsManga.image);
	var [sending, setSending] = useState(false);
	var [automaticName, setAutomaticName] = useState(true);
	const { showActionSheetWithOptions } = useActionSheet();

	var ErrorBack = (text, title)=>{
		Alert.alert((title || 'Error'), text, [
			{ style: 'default', text: 'Close', onPress: ()=>{
				navigation.goBack();
			}}
		]);
	}

	useEffect(()=>{
		if(!propsManga.manga_id){
			if(!propsMangaId){
				Alert.alert('Error', 'Invalid manga information.')
				return navigation.goBack();
			}
			API.getManga(manga_id, false, true).then(res=>{
				if(res.error) return ErrorBack(res.message_client);
				var { manga } = res.data;
				setManga(manga);
				setMangaId(manga.manga_id);
				setMangaName(manga.manga_name);
				setMangakas(manga.authors);
				setMangaDescription(manga.description);
				setSeries({
					...manga.series,
					publisher_name: manga.publisher.publisher_name,
				});
				setVolumeStart(manga.volume_start || '');
				setVolumeEnd(manga.volume_end || '');
				setMangaImage(manga.image);
				setLoading(false);
			}).catch(err=>{
				return ErrorBack('Unexpected error searching for book (LCL-MGS-1).');
			});
			setLoading(true);
		}else{
			if(propsManga && propsManga.publisher){
				setSeries({
					...series,
					publisher_name: propsManga.publisher.publisher_name
				});
			}
			setLoading(false);
		}
	}, []);

	var showMangakaSelect = ()=>{
		navigation.push('SelectionList', {
			title: 'Search Mangaka',
			multiple: true,
			search_api: {
				url: 'admin/mangaka/search',
				query: 'query',
				fields: {
					value: 'mangaka_id',
					text: 'mangaka_name'
				}
			},
			values: mangakas && mangakas.map(a=>({
				value: a.mangaka_id,
				text: a.mangaka_name,
				selected: true
			})),
			onSelect: vals=>{
				setMangakas(vals.map(a=>({
					mangaka_id: a.value,
					mangaka_name: a.text
				})))
			}
		})
	}
	
	var showSeriesSearch = ()=>{
		navigation.push('SelectionList', {
			title: 'Search Series',
			search_api: {
				url: 'admin/series/search?p=1&m=1', 
				fields: {
					value: 'series_id',
					text: 'series_name',
					metadata: 'publisher_name',
					badge: 'manga_count'
				}
			},
			values: series ? [{
				value: series.series_id,
				text: series.series_name,
				metadata: series.publisher_name
			}] : [],
			// onLongPress: (val, self)=>{
			// 	showActionSheetWithOptions({
			// 		options: ['Edit Series', 'Cancel'],
			// 		cancelButtonIndex: 1,
			// 	}, i=>{
			// 		// navigation.push('EditSeries', {
			// 		// 	series_id: val.value,
			// 		// 	onEdit: (series)=>{
			// 		// 		console.log(series);
			// 		// 	}
			// 		// })
			// 	});
			// },
			onSelect: vals=>{
				updateAutomaticName(automaticName, vals.text, volume_start, volume_end);
				setSeries({
					series_id: vals.value,
					series_name: vals.text,
					publisher_name: vals.metadata,
				});
			}
		})
	}

	var saveManga = ()=>{
		if(sending || loading) return;
		var data = {
			manga_name: manga_name,
			authors: mangakas.map(a=>a.mangaka_id),
			volume_start: volume_start,
			volume_end: volume_end,
			series_id: series.series_id,
			description: manga_description
		}
		setSending(true);
		API.editManga(manga_id, data).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			// Alert.alert('Saved', 'Manga edited successfully.');
			Keyboard.dismiss();
			if(onEdit) onEdit(manga_id, {
				manga_name: manga_name,
				authors: mangakas.map(a=>a.mangaka_name),
				volume_start: volume_start,
				volume_end: volume_end,
				series_id: series.series_id,
				description: manga_description
			});
			if(autoBack){
				navigation.goBack();
			}
		}).catch(err=>{
			setSending(false)
			Alert.alert('Error', 'Unexpected error editing manga. (LCLA-EDMG-1)');
		})
	}

	var updateAutomaticName = (automaticName, series_name, volume_start, volume_end)=>{
		if(automaticName){
			setMangaName(`${series_name || '???'}, Vol. ${parseInt(volume_start) || '???'}${parseInt(volume_end) ? '-'+parseInt(volume_end) : ''}`);
		}
	}

	var onAutomaticNameSet = (val)=>{
		setAutomaticName(val);
		if(!val || !series){
			setMangaName(propsManga.manga_name || manga.manga_name)
		}else{
			updateAutomaticName(val, series?.series_name, volume_start, volume_end);
		}
	}

	var onVolumeStartSet = (vs)=>{
		setVolumeStart(vs);
		updateAutomaticName(automaticName, series?.series_name, vs, volume_end);
	}

	var onVolumeEndSet = (ve)=>{
		setVolumeEnd(ve);
		if(automaticName) updateAutomaticName(automaticName, series?.series_name, volume_start, ve);
	}

	var webSearchBook = ()=>{
		Linking.openURL(`https://barnesandnoble.com/s/${manga.isbn}`);
	}

	var messageColor = mixColors(RGBToHex(colors.card), colors.primary, 80);
	var styles = StyleSheet.create({
		message: {
			backgroundColor: messageColor,
			padding: 10,
			borderRadius: 9,
			marginBottom: 10
		},
		messageText: {
			color: colors.text
		},
		cover: {
			width: 200,
			height: (200*1.5),
			backgroundColor: colors.card,
			resizeMode: 'contain',
			alignSelf: 'center',
			borderRadius: 9,
			marginBottom: 10
		},
		inputComment: {
			color: 'gray',
			textAlign: 'center',
			flexDirection: 'column'
		}
	});

	return (
		<HeaderScroll
			headerText='Edit Manga'
			headerRight={!loading ? <View style={{ flexDirection: 'row' }}>
				<HeaderIcon icon={'web'} material size={28} onPress={webSearchBook} width={30} />
				<HeaderIcon icon={'floppy'} loading={sending} material size={28} onPress={saveManga} />
			</View> : false}
		>
			{loading ? (
				<LoadingHeader text='Loading manga info...' />
			) : (
				<View style={{ marginTop: 10 }}>
					{mangaImage ? (
						<Image source={{ uri: `${mangaImage}/original.jpg` }} style={styles.cover}  />
					) : (
						<View style={styles.cover} />
					)}
					<View style={[styles.message, { backgroundColor: colors.card, flexDirection: 'row', alignItems: 'center', marginTop: 10 }]}>
						<View style={{ flex: 1, paddingRight: 10 }}>
							<Text style={{ color: colors.text, fontSize: 16, fontWeight: 'bold' }}>Automatic Name</Text>
							<Text style={{ color: colors.text }}>Generate the name based on the series and the volume number.</Text>
						</View>
						<Switch value={automaticName} onValueChange={onAutomaticNameSet} />
					</View>
					<Input label='Manga Name' value={manga_name} onChangeText={setMangaName} errored={automaticName && (!volume_start || !series)} editable={!automaticName} />
					{!automaticName ? (
						<Text style={styles.inputComment}>
							<Text style={{ fontWeight: 'bold' }}>Manga name should follow the series' name standard.</Text>{'\n'}
							<Text>Example: Vinland Saga 2, Monster, Vol. 1</Text>
						</Text>
					) : null}

					<Input label='Mangaka' iconRight='chevron-right' editable={false} value={mangakas.map(a=>a.mangaka_name).join(', ')} onPress={showMangakaSelect} errored={!mangakas || mangakas.length==0} />
					<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						<Input label="Volume start" value={volume_start} onChangeText={onVolumeStartSet} style={{ width: '48%' }} keyboardType={'number-pad'} errored={automaticName && !volume_start} />
						<Input label="Volume end" value={volume_end} onChangeText={onVolumeEndSet} style={{ width: '48%' }} keyboardType={'number-pad'} />
					</View>
					<Text style={styles.inputComment}>Example: Volume 1 would just be volume start 1. Omnibus 3 with volumes 8-12 would be volume start 3. Book with volumes 12-14 would be volume start 12 and volume end 14.</Text>

					<Input label='Series' iconRight='chevron-right' editable={false} value={series?.series_name} metadata={series?.publisher_name} onPress={showSeriesSearch} errored={automaticName && !series} />
					<Input label='Description' multiline height={200} value={manga_description} onChangeText={setMangaDescription} />
					<Text style={styles.inputComment}>Click the icon on the top right to save!</Text>
				</View>
			)}
		</HeaderScroll>
	)
}

export default connectActionSheet(EditManga)