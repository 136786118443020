import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../API';
import { Button, HeaderScroll, Input, LoadingHeader } from '../component';

var ProblemReport = props=>{
	var { colors, dark } = useTheme();
	var { series_id, manga_id, boxset_id, name } = props.route.params
	var [problems, setProblems] = useState(null);
	var [description, setDescription] = useState('');
	var [selected, setSelected] = useState(null);
	var [loading, setLoading] = useState(false);

	useEffect(()=>{
		var type = manga_id ? 1 : (series_id ? 2 : (boxset_id ? 3 : null));
		if(type===null){
			Alert.alert('Error', 'Unexpected error loading options (LCL-1)');
			return props.navigation.goBack();
		}
		API.getProblems(type).then(res=>{
			if(res.error){
				Alert.alert('Error', res.message_client);
				return props.navigation.goBack();
			}
			setProblems(res.data);
		}).catch(err=>{
			Alert.alert('Error', 'Unexpected error loading options (LCL-2)');
			return props.navigation.goBack();
		})
	}, []);

	var styles = StyleSheet.create({
		name_type: {
			color: 'gray',
			fontSize: 18,
			marginBottom: 2,
			textAlign: 'center',
		},
		name: {
			fontSize: 24,
			color: colors.text,
			fontWeight: 'bold',
			textAlign: 'center',
			marginBottom: 10,
		},
		disclamer: {
			color: 'gray',
			textAlign: 'center',
		}
	});

	var showProblemSelect = ()=>{
		props.navigation.push('SelectionList', {
			title: 'Select problem',
			values: problems.map(a=>({
				value: a.type_id,
				text: a.problem_name
			})),
			onSelect: vals=>{
				setSelected(vals);
			}
		})
	}

	var sendProblem = ()=>{
		if(loading) return;
		if(!selected) return;
		setLoading(true);
		API.sendProblem(manga_id, series_id, boxset_id, selected.value, description).then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			props.navigation.goBack();
			Alert.alert('Success', 'The problem as been reported! Thank you!');
		}).catch(err=>{
			setLoading(false);
			Alert.alert('Error', 'Unexpected error loading options (LCL-3)');
		});
	}

	var typeName = series_id ? 'Series' : (manga_id ? 'Manga' : boxset_id ? 'Boxset' : 'UNKWN')

	return (
		<HeaderScroll
			headerText={'Report a problem'}
			containerTextStyle={{ textAlign: 'center' }}
		>
			{problems ? (
				<View>
					<Text style={styles.name_type}>{typeName}</Text>
					<Text style={styles.name}>{name}</Text>
					<Input label='Problem' editable={false} iconRight='chevron-right' onPress={showProblemSelect} placeholder='Select problem' value={selected ? selected.text : null} style={{ marginBottom: 10 }} />
					<Input maxLength={120} multiline label={'Problem description'} height={120} placeholder={`Describe the problem this ${typeName.toLowerCase()} has\nto make it easier for us to fix!`} value={description} onChangeText={setDescription} />
					<Text style={styles.disclamer}>Please only serious reports! You may be blacklisted if this system is abused.</Text>
					{selected ? <Button loading={loading} style={{ marginTop: 15 }} width={200} text='Send problem' onPress={sendProblem} /> : null}
				</View>
			) : (
				<LoadingHeader text='Loading' />
			)}
		</HeaderScroll>
	)
}

export default ProblemReport;