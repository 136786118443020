import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert, TouchableOpacity, FlatList } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { LoadingHeader, ImageFallback } from '../../component';
import API from '../../API';

var NewUsers = props=>{
	var { colors, dark } = useTheme();
	var [users, setUsers] = useState(null);

	useEffect(()=>{
		API.getRecentUsers(50, 0).then(res=>{
			if(res.error){
				Alert.alert('Error', res.message_client);
				return props.navigation.goBack();
			}
			setUsers(res.data);
		}).catch(err=>{
			Alert.alert('Error', 'Unexpected error loading recent users (LCL-1).');
			props.navigation.goBack();
		})
	}, []);

	var styles = StyleSheet.create({
		userContainer: {
			backgroundColor: colors.card,
			padding: 10,
			borderRadius: 12,
			marginTop: 5,
			flexDirection: 'row',
			alignItems: 'center'
		},
		userImage: {
			width: 50,
			height: 50,
			borderRadius: 100,
			borderColor: colors.border,
			backgroundColor: colors.card,
			borderWidth: 3,
			marginRight: 10
		},
		username: {
			fontWeight: 'bold',
			color: colors.text,
			fontSize: 20,
			marginTop: -4
		},
		manga: {
			color: 'gray'
		}
	})

	if(!users) return <LoadingHeader text='Loading users' />
	return (
		<FlatList
			data={users}
			keyExtractor={a=>a.user_id}
			renderItem={({ item })=>(
				<TouchableOpacity key={'usr-'+item.user_id} onPress={()=>{
					props.navigation.push('Profile', {
						user_id: item.user_id,
						username: item.username
					})
				}}>
					<View style={styles.userContainer}>
						<ImageFallback style={styles.userImage} url={item.image} fallbackText={item.username[0].toUpperCase()} fallbackTextStyle={{ fontSize: 24, color: colors.border }} />
						<View>
							<Text style={styles.username} numberOfLines={1}>{item.username}</Text>
							<Text style={styles.manga} numberOfLines={1}>{item.books} Book{item.books==1 ? '' : 's'}</Text>
						</View>
					</View>
				</TouchableOpacity>
			)}
		/>
	)
}

export default NewUsers;