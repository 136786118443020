import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import { useTheme } from '@react-navigation/native';
import PropTypes from 'prop-types';

var LoadingCover = props=>{
	var { colors, dark } = useTheme();

	return props.loading ? (
		<View style={[StyleSheet.absoluteFill, { position: 'absolute', alignItems: 'center', justifyContent: 'center' }]}>
			<View style={{ width: 150, height: 150, backgroundColor: dark ? '#333333CC' : '#444444CC', alignSelf: 'center', justifyContent: 'center', borderRadius: 9 }}>
				<ActivityIndicator size={'large'} color={'white'} />
				<Text style={{ textAlign: 'center', marginTop: 10, color: 'white', fontSize: 20, fontWeight: 'bold' }}>{props.text || 'Loading'}</Text>
			</View>
		</View>
	) : null;
}

LoadingCover.propTypes = {
	loading: PropTypes.bool,
	text: PropTypes.string,
}

export default LoadingCover;