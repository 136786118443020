import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View, Dimensions, ScrollView, Image } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { getShade, getColorContrast } from '../Util';
import ImageFallback from './ImageFallback';
import cleanMarkdown from 'markdown-to-text';

const MessageCarrousel = props=>{
	var { dark, colors } = useTheme();
	var { width: WindowWidth } = Dimensions.get('window');
	var width = Math.min(WindowWidth, props.width);

	var containerWidth = width-50;

	if(!props.messages || !Array.isArray(props.messages) || props.messages.length==0){
		return null;
	}

	if(props.messages.length==1){
		containerWidth = width-20;
	}
	
	const styles = StyleSheet.create({
		container: {
			width: '100%',
			borderRadius: 9,
			backgroundColor: colors.card,
			height: 120,
			width: containerWidth,
			marginRight: 10,
			overflow: 'hidden'
		},
		gradient: {
			width: '100%',
			height: '100%',
			position: 'absolute'
		},
		messageSubtitle: {
			fontSize: 14,
			fontWeight: 'bold',
		},
		messageTitle: {
			fontSize: 20,
			fontWeight: 'bold'
		},
		messateContents: {
			paddingVertical: 10, 
			paddingHorizontal: 10, 
			flexDirection: 'row',
			flex: 1,
		},
		userImage: {
			height: 80,
			width: 80,
			backgroundColor: colors.card,
			borderRadius: 100,
			alignSelf: 'center',
			marginRight: 10,
			borderWidth: 3,
			borderColor: dark ? colors.card : colors.border
		},
		mangaImage: {
			height: 100,
			width: 66,
			backgroundColor: colors.card,
			borderRadius: 4,
			alignSelf: 'center',
			marginRight: 10,
			borderColor: colors.card
		}
	});

	return <View style={[{ width: '100%' }, props.style]}>
		<ScrollView  
			horizontal={true}
			snapToStart={true}
			snapToInterval={(containerWidth)}
			snapToAlignment={'center'}
			decelerationRate={0}
			showsHorizontalScrollIndicator={false}
			style={{ overflow: 'visible' }}
		>
			{props.messages.map((a, i, ar)=>{
				a.message_color = a.message_color || 'EF3341';
				var contrast_color = getColorContrast(a.message_color);
				return (
					<TouchableOpacity key={'homemessage-'+a.message_id} style={[styles.container, (i==ar.length-1) ? { marginRight: 0 } : {}]} onPress={()=>{
						if(props.onPress) props.onPress(a);
					}}>
						<LinearGradient colors={['#'+a.message_color, getShade(a.message_color, -10)]} style={styles.gradient} />
						<View style={styles.messateContents}>
							{a.manga_display ? (
								<Image source={{ uri: `${a.manga_image}/150.jpg` }} style={styles.mangaImage} />
							) : a.user_display ? (
								<ImageFallback style={styles.userImage} url={a.user_image} fallbackText={a.username[0].toUpperCase()} />
							) : null}
							<View style={{ flex: 1 }}>
								<Text style={[styles.messageSubtitle, { color: contrast_color }]}>{a.message_subtitle}</Text>
								<Text style={[styles.messageTitle, { color: contrast_color }]} numberOfLines={1}>{a.message_title}</Text>
								<Text style={{ marginTop: 5, color: contrast_color }} numberOfLines={3}>{cleanMarkdown(a.message_content).replace(/\n/gi, ' ')}</Text>
							</View>
						</View>
					</TouchableOpacity>
				)
			})}
		</ScrollView>
	</View>
}

MessageCarrousel.propTypes = {
	style: PropTypes.object,
	messages: PropTypes.array,
	onPress: PropTypes.func,
	width: PropTypes.number,
}

export default MessageCarrousel;