import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View, Image, TouchableOpacity, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { Button, HeaderIcon, HeaderScroll, IconButton, ImageFallback, LoadingHeader, StatsCarrousel, Countdown } from '../component';
import { addCommas, getAllIndexes, mixColors, RGBToHex } from '../Util'
import { FontAwesome5 } from '@expo/vector-icons';
import { connectActionSheet, useActionSheet } from '@expo/react-native-action-sheet';
import API from '../API';
import ISO6391 from 'iso-639-1';
import moment from 'moment';

const MAX_LINES = 5;

var Book = (props)=>{
	var { manga_id, image, manga_name, mangaka_name, onOwned, onEdit, onWishlist } = (props.route.params || {});
	var { colors, dark } = useTheme();
	var [manga, setManga] = useState(null);
	var [ownership, setOwnership] = useState(null);
	var [timeoutOwnership, setTimeoutOwnership] = useState(null);
	var [showMore, setShowMore] = useState(false);
	var [user, setUser] = useState(null);
	var [loggedUser, setLoggedUser] = useState(null);
	var [descriptionShown, setDescriptionShown] = useState(false);
	const { showActionSheetWithOptions } = useActionSheet();

	var ErrorBack = (text, title)=>{
		Alert.alert((title || 'Error'), text, [
			{ style: 'default', text: 'Close', onPress: ()=>{
				props.navigation.goBack();
			}}
		]);
	}

	var viewManga = manga_id=>{
		setManga(null);
		setOwnership(null);
		API.getManga(manga_id, true, true).then(res=>{
			if(res.error) return ErrorBack(res.message_client);
			setOwnership(res.data.ownership);
			setManga(res.data.manga);
		}).catch(err=>{
			return ErrorBack('Unexpected error searching for book (LCL-MGS-1).');
		});
	}

	useEffect(()=>{
		viewManga(manga_id);
		API.getLogin().then(setLoggedUser);
	}, [])

	var selectedColor = mixColors(RGBToHex(colors.card), colors.primary, 80);

	var sendOwnership = (osh)=>{
		return ()=>{
			var data = {
				manga_id: manga.manga_id,
				own: osh.owned ? 1 : 0,
				read: osh.has_read ? 1 : 0,
				wanted: osh.wanted && !osh.owned ? 1 : 0
			}
			API.sendMangaOwnership(data.manga_id, data.wanted, data.read, data.own).then(res=>{
				if(res.error) Alert.alert('Error', res.message_client);
				var osh = { ...ownership };
				osh.owned = res.data.owned;
				osh.has_read = res.data.read;
				osh.wanted = res.data.wanted;
				setOwnership(osh);
			}).catch(err=>{
				return Alert.alert('Error', 'Unexpected error searching for book (LCL-MGS-2).');
			})
		}
	}

	var changeOwnership = key=>{
		return ()=>{
			var osh = { ...ownership };
			osh[key] = !osh[key];
			if(key=='owned'){
				osh.wanted = false;
			}
			
			if(ownership.owned && !osh.owned){
				Alert.alert('Remove from collection', 'Are you sure you want to remove this volume from your collection?', [
					{ type: 'Cancel', style: 'cancel', text: 'Cancel' },
					{ text: 'Remove', style: 'destructive', onPress: ()=>{
						setOwnership(osh);
						if(timeoutOwnership) clearTimeout(timeoutOwnership);
						setTimeoutOwnership(setTimeout(sendOwnership(osh), 500));
						if(onOwned) onOwned(manga.manga_id, osh.owned);
					}}
				])
			}else{
				if(onOwned) onOwned(manga.manga_id, osh.owned);
				if(key=='wanted' && onWishlist) onWishlist(manga.manga_id, osh.wanted, manga);
				setOwnership(osh);
				if(timeoutOwnership) clearTimeout(timeoutOwnership);
				setTimeoutOwnership(setTimeout(sendOwnership(osh), 1000));
			}
		}
	}

	var showSeries = ()=>{
		props.navigation.navigate('Series', {
			series: {
				series_id: manga.series_id,
				series_name: manga.series.series_name,
				authors: manga.authors.map(a=>a.mangaka_name),
			},
			owned: false,
		})
	}

	var setProfilePicture = ()=>{
		props.navigation.push('ProfilePictureCrop', {
			manga_id: manga.manga_id,
			manga_image: `${manga.image}/original.jpg`
		})
	}

	const styles = StyleSheet.create({
		cover: {
			width: 200,
			height: 300,
			backgroundColor: dark ? colors.card : colors.border,
			alignSelf: 'center',
			borderRadius: 9,
			marginBottom: 10
		},
		mangakaContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 15,
		},
		mangakaName: {
			fontSize: 18,
			color: 'gray',
			textAlign: 'center',
			marginRight: 10,
			marginTop: -2
		},
		header: {
			marginTop: 20,
			fontSize: 30,
			fontWeight: 'bold',
			color: colors.text
		},
		description: {
			color: colors.text,
			fontSize: 16
		},
		descriptionContainer: {
			backgroundColor: colors.card,
			padding: 20,
			marginTop: 5,
			borderRadius: 9
		},
		message: {
			backgroundColor: selectedColor,
			padding: 10,
			borderRadius: 9
		},
		messageInfo: {
			backgroundColor: mixColors(RGBToHex(colors.card), '#1D79F2', 80),
		},
		messageHeader: {
			color: colors.text,
			fontSize: 20,
			textAlign: 'center',
			fontWeight: 'bold'
		},
		messageText: {
			color: colors.text,
			fontSize: 16,
			textAlign: 'center'
		},
		authorsPlaceholder: {
			height: 20,
			backgroundColor: colors.border,
			width: 150,
			alignSelf: 'center',
			marginBottom: 15,
			borderRadius: 100,
		}
	});

	var release_date = manga && manga.date_release ? moment.unix(manga.date_release) : null
	if(manga){
		var stats = []
		if(manga.page_count){
			stats.push({ label: 'Length', value: manga.page_count, metric: 'Pages' });
		}
		if(release_date){
			stats.push({ label: 'Release', value: release_date.format('YYYY'), metric: release_date.format('DD MMM') });
		}
		stats.push({ label: 'Language', value: manga.language, metric: ISO6391.getName(manga.language.toLowerCase()) });
		if(manga.volume_start){
			stats.push({ label: 'Series Vol.', value: `${manga.volume_start}${manga.volume_end ? '-'+manga.volume_end : ''}`, valueStyle: { fontSize: 32 } })
		}
	}

	var StatItem = (props)=>{
		return <View style={{ flexDirection: 'row', borderBottomColor: colors.border, borderBottomWidth: StyleSheet.hairlineWidth, alignItems: 'center' }}>
			<Text style={{ color: dark ? 'gray' : '#404040', flex: 1, fontWeight: 'bold', fontSize: 16 }} selectable>{props.label}</Text>
			{props.onPress ? (
				<TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', padding: 15, }} onPress={props.onPress}>
					<Text style={{ color: colors.text, fontSize: 16, maxWidth: 250 }} numberOfLines={1} selectable>{props.value}</Text>
					{typeof props.chevron!=='undefined' ? (
						<FontAwesome5 name="chevron-right" color={'gray'} style={{ marginBottom: 0, marginLeft: 10 }} size={16} />
					) : null}
				</TouchableOpacity>
			) : (
				<Text style={{ color: colors.text, fontSize: 16, maxWidth: 250, padding: 15,  }} numberOfLines={1} selectable>{props.value}</Text>
			)}
		</View>
	}

	var blacklistBook = ()=>{
		var title = `${manga.blacklist ? 'Unblacklist' : 'Blacklist'} book`;
		var message;
		if(manga.blacklist){
			message = 'Unblacklist this book and make it available?';
		}else{
			message = 'Use this option ONLY if the book is not a manga or should not be in the system.';
		}

		Alert.alert(title, message, [
			{ text: 'Cancel', style: 'cancel' },
			{ text: manga.blacklist ? 'Unblacklist' : 'Blacklist', style: 'destructive', onPress: ()=>{
				API.blacklistBook(manga.manga_id, !manga.blacklist).then(res=>{
					if(res.error) return Alert.alert('Error', res.message_client);
					
					Alert.alert('Done', `Book has been ${!!res.data.blacklist ? 'blacklisted' : 'unblacklisted'}.`);
					setManga({
						...manga,
						blacklist: !!res.data.blacklist,
						date_blacklisted: moment().unix(),
						user_blacklisted_username: loggedUser.username
					})
				}).catch(err=>{
					return Alert.alert('Error', 'Unexpected error blacklisting book. (LCL-MGS-3)')
				})
			}}
		])
	}

	var editManga = (manga_param)=>{
		if(!manga && !manga_param) return;
		props.navigation.navigate('EditManga', {
			manga: manga_param || manga,
			onEdit: (manga_id, data)=>{
				var nm = {...manga}
				for(var i in data){
					nm[i] = data[i]
				}
				setManga(nm);
				if(onEdit) onEdit(manga_id, data);
			}
		})
	}

	var changeCover = ()=>{
		props.navigation.push('ChangeCover', {
			isbn: manga.isbn,
			manga_name: manga.manga_name,
		});
	}

	var setRedirect = ()=>{
		props.navigation.push('RedirectBook', {
			manga,
			onRedirect: (from_manga, to_manga)=>{
				if(from_manga==manga_id){
					setManga({
						...manga,
						manga_redirect: to_manga
					});
				}
			}
		});
	}

	var showReport = ()=>{
		props.navigation.push('ProblemReport', {
			manga_id: manga.manga_id,
			name: manga.manga_name,
		});
	}

	var showOptions = ()=>{
		var options = [
			{ title: 'Set cover as profile picture', access: 1, onPress: setProfilePicture },
			{ title: 'Edit Manga', access: 100, onPress: editManga },
			{ title: 'Set Redirect', access: 100, onPress: setRedirect },
			{ title: 'Change cover', access: 50, onPress: changeCover },
			{ title: 'Report a problem', access: 1, onPress: showReport },
			{ title: `${manga.blacklist ? 'Unblacklist' : 'Blacklist'} book`, access: 101, destructive: true, onPress: blacklistBook },
		]
		if(options.length==0) return;
		options = options.filter(a=>(!a.access || a.access<=(loggedUser ? loggedUser.user_type : 1)));
		showActionSheetWithOptions(
			{
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
				destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
			},
			ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress();
			}
		);
	}

	var fallbackText = null;
	if(manga){
		fallbackText = manga.series ? manga.series.series_name : manga.manga_name;
		if(manga.volume_start || manga.volume_end){
			fallbackText += '\n';
			fallbackText += [manga.volume_start, manga.volume_end].filter(a=>!!a).join('-')
		}
	}else{
		fallbackText = manga_name;
	}

	return (
		<HeaderScroll 
			containerTextStyle={{ textAlign: 'center', marginBottom: 5 }}
			headerText={manga ? manga.manga_name : manga_name}
			headerRight={manga ? <HeaderIcon icon={'ellipsis-h'} five onPress={showOptions} /> : false}
		>
			{manga || mangaka_name ? (
				<TouchableOpacity style={styles.mangakaContainer}>
					<Text style={styles.mangakaName}>{manga && manga.authors && manga.authors.length>0 ? manga.authors[0].mangaka_name : mangaka_name }</Text>
					{/* <FontAwesome5 name="chevron-right" color={'gray'} size={14} /> */}
				</TouchableOpacity>
			) : <View style={styles.authorsPlaceholder} />}
			{image || (manga && manga.image) ? (
				<ImageFallback url={`${manga ? manga.image : image}/original.jpg`} style={styles.cover} fallbackText={fallbackText} fallbackTextStyle={{ fontSize: 20, paddingHorizontal: 10 }} />
			) : (
				<View style={styles.cover} />
			)}
			{!manga ? (
				<LoadingHeader text='Loading...' />
			) : (
				<View>
					{manga && manga.blacklist ? (
						<View style={styles.message}>
							<Text style={styles.messageHeader}>This book is blacklisted</Text>
							<Text style={styles.messageText}>Blacklisted by {manga.user_blacklisted_username}</Text>
							<Text style={styles.messageText}>{moment.unix(manga.date_blacklisted).format('YYYY-MM-DD HH:mm:ss')}</Text>
						</View>
					) : null}
					{manga && manga.manga_redirect ? (
						<View style={styles.message}>
							<Text style={styles.messageHeader}>This manga has been redirected</Text>
							<Button text='View redirect' height={40} style={{ marginTop: 10 }} color='transparent' onPress={()=>viewManga(manga.manga_redirect)} />
						</View>
					) : null}
					{manga && manga.date_release && moment().isBefore(moment.unix(manga.date_release)) ? (
						<View style={[styles.message, styles.messageInfo]}>
							<Text style={styles.messageHeader}>This manga releases{'\n'}{moment.unix(manga.date_release).format('MMM DD, YYYY')}</Text>
							<Countdown style={{ marginTop: 10 }} unix={manga.date_release} />
						</View>
					) : null}
					{ownership && !manga.blacklist ? (
						<View style={{ flexDirection: 'row', marginTop: 10 }}>
							<IconButton onPress={changeOwnership('owned')} text={ownership.owned ? 'Owned' : 'Own'} icon={ownership.owned ? 'check-circle' : 'plus-circle'} solid={false} iconColor={ownership.owned ? colors.primary : null} color={ownership.owned ? selectedColor : null} />
							<IconButton onPress={changeOwnership('has_read')} text={'Read'} icon={ownership.has_read ? 'book-open' : 'book'} solid={false} iconColor={ownership.has_read ? colors.primary : null} five={ownership.has_read ? true : false} color={ownership.has_read ? selectedColor : null} />
							{!ownership.owned ? (
								<IconButton onPress={changeOwnership('wanted')} text={'Wishlist'} icon={ownership.wanted ? 'star' : 'star-o'} solid={true} iconColor={ownership.wanted ? colors.primary : null} five={ownership.wanted ? true : false} color={ownership.wanted ? selectedColor : null} />
							) : null}
						</View>
					) : null}
					<Text style={styles.header}>Description</Text>
					<View style={styles.descriptionContainer}>
						<Text onTextLayout={e=>setShowMore(e.nativeEvent.lines.length>MAX_LINES)} numberOfLines={showMore || descriptionShown ? null : MAX_LINES} style={styles.description} selectable={true}>{manga.description}</Text>
						{!descriptionShown ? (
							<Button text='Show more...' height={30} color='transparent' style={{ marginTop: 10 }} onPress={()=>setDescriptionShown(true)} textColor={colors.text} />
						) : null}
					</View>
					<Text style={styles.header}>Information</Text>
					<StatsCarrousel style={{ marginVertical: 10 }} data={stats} />
					<View>
						<StatItem label='Language' value={ISO6391.getName(manga.language.toLowerCase())} />
						{manga.series ? (
							<StatItem label='Series' value={manga.series.series_name} chevron onPress={showSeries} />
							) : null}
						{manga.volume_start ? (
							<StatItem label='Volume Number' value={manga.volume_start+(manga.volume_end ? `-${manga.volume_end}` : '')} />
							) : null}
						{manga.publisher ? (
							<StatItem label='Publisher' value={manga.publisher.publisher_name} chevron onPress={()=>{}} />
						) : null}
						{release_date ? (
							<StatItem label='Release Date' value={release_date.format('DD MMMM, YYYY')} />
						) : null}
						{manga.page_count && <StatItem label='Page count' value={addCommas(manga.page_count, false)} />}
						<StatItem label='ISBN' value={manga.isbn} />
						{manga.book_width || manga.book_height || manga.book_height ? (
							<StatItem label='Dimensions (mm)' value={`${manga.book_width || '??'} x ${manga.book_height || '??'} x ${manga.book_thick || '??'}`} />
						) : null}
					</View>
				</View>
			)}
			<View style={{ height: 40 }} />
		</HeaderScroll>
	)
}

export default connectActionSheet(Book);