import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { BookItem, LoadingHeader } from '../../component';
import API from '../../API';
import { FlatList } from 'react-native-gesture-handler';

var PublisherManga = props=>{
	var { colors, dark } = useTheme();
	var [mangas, setMangas] = useState(null);
	var { publisher } = props.route.params;

	var styles = StyleSheet.create({
		
	})

	useEffect(()=>{
		API.getPublisherManga(publisher.publisher_id).then(res=>{
			if(res.error){
				Alert.alert('Error', res.message_client);
				return props.navigation.goBack();
			}
			setMangas(res.data);
		}).catch(err=>{
			Alert.alert('Error', 'Unexpected error loading publisher manga (LCL-1)');
			return props.navigation.goBack();
		})
	}, []);

	if(!mangas) return <LoadingHeader text="Loading publisher manga" />

	return <FlatList
		data={mangas}
		keyExtractor={a=>a.manga_id}
		renderItem={({ item })=><BookItem book={item} series />}
	/>
}

export default PublisherManga;