import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, RefreshControl, ActivityIndicator, Alert, Text } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../API';
import { Button, LoadingHeader, HeaderScroll, Input, HeaderIcon, BookItem } from '../component'

export default (props)=>{
	var { user_id } = (props.route.params || {});
	var scanEnable = true;
	if(props.route && props.route.params && props.route.params.scan===false) scanEnable = false;
	var { colors, dark } = useTheme();

	var [user, setUser] = useState(null);
	var [loggedUser, setLoggedUser] = useState(false);
	var [library, setLibrary] = useState(null);
	var [librarySearch, setLibrarySearch] = useState([]);
	var [refreshing, setRefreshing] = useState(false);
	var [loadingMore, setLoadingMore] = useState(false);
	var [error, setError] = useState(null);
	var [loadMoreError, setLoadMoreError] = useState(null);
	var [searchError, setSearchError] = useState(null);
	var [loadAmount, setLoadAmount] = useState(50);
	var [loadEndReached, setLoadEndReached] = useState(false);
	var [searchValue, setSearchValue] = useState('');
	var [searchTimeout, setSearchTimeout] = useState(null);
	var [searchMode, setSearchMode] = useState(null);
	var [searching, setSearching] = useState(null);
	var [searchLocation, setSearchLocation] = useState(0);
	var [searchInputFocus, setSearchInputFocus] = useState(false);
	var [wishlist, setWishlist] = useState(null);
	var [showingWishlist, setShowingWishlist] = useState(null);
	var scrollRef = useRef(null);
	var inputRef = useRef(null);

	var isOtherLibrary = user && loggedUser && user.user_id!=loggedUser.user_id;
	var libraryOwner = `${user && isOtherLibrary ? user.username+"'s " : ''}`

	var refreshLibrary = (refresh=false)=>{
		if(error) setError(null);
		if(refresh) setRefreshing(true);
		API.getLibrary(user_id, null, loadAmount, 0, true).then(res=>{
			if(refresh) setRefreshing(false);
			if(res.error) return setError(res.message_client);
			var { library, user } = res.data;
			setLibrary(library);
			if(library.length==0){
				if(user_id) setError(`${user.username} doesn\'t have any books!`);
				else setError('No books added to your library');
			}
			setLoadEndReached(library.length<loadAmount);
			setUser(user);
		}).catch(err=>{
			if(refresh) setRefreshing(false);
			setError('Unexpected error (LCL-LB-1)');
		})
	}

	var refreshWishlist = (refresh=false)=>{
		if(error) setError(null);
		if(refresh) setRefreshing(true);
		API.getWishlist(user_id).then(res=>{
			if(refresh) setRefreshing(false);
			if(res.error) return setError(res.message_client);
			setWishlist(res.data);
			if(res.data.length==0){
				if(user_id) setError(`${user.username} doesn\'t have any manga on their wishlist!`);
				else setError('No books added to your wishlist');
			}
		}).catch(err=>{
			if(refresh) setRefreshing(false);
			setError('Unexpected error (LCL-LB-4)');
		})
	}
	
	var loadMore = ()=>{
		if(error || loadingMore || loadMoreError || loadEndReached) return;
		setLoadEndReached(true);
		setLoadingMore(true);
		setLoadMoreError(null);
		API.getLibrary(user_id, null, loadAmount, library.length, false).then(res=>{
			if(res.error) return setLoadMoreError(res.message_client);
			setLibrary([...library, ...res.data.library]);
			setLoadingMore(false);
			setLoadEndReached(res.data.library.length<loadAmount);
		}).catch(err=>{
			setLoadMoreError('Unexpected error (LCL-LB-2)')
			setLoadingMore(false);
		})
	}

	var searchLibrary = (val, immediate=false, location=null)=>{
		setSearchValue(val);
		if(searchTimeout) clearTimeout(searchTimeout);
		if(val.length<3){
			setSearchError(null);
			return setSearchMode(false);
		}
		
		if(location===null) location = searchLocation;

		var searchfn = function(){
			setSearchMode(true);
			setSearching(true);
			setSearchError(null);
			setLibrarySearch([]);
			if(location==1){
				API.searchManga(val, true).then(res=>{
					setSearching(false);
					if(res.error) setSearchError(res.message_client);
					if(!res.data || res.data.length==0){
						setSearchError('No results found!')
					}else setLibrarySearch(res.data);
				})
			}else{
				API.getLibrary(user_id, val, loadAmount, 0, false).then(res=>{
					setSearching(false);
					if(res.error) setSearchError(res.message_client);
					if(res.data.library.length==0){
						setSearchError('No results found!')
					}else setLibrarySearch(res.data.library);
				}).catch(err=>{
					setSearching(false);
					setSearchError('Unexpected error (LCL-LB-3)');
				})
			}
		}
		if(immediate){
			searchfn();
		}else{
			setSearchTimeout(setTimeout(searchfn, 500));
		}
	}

	var showBook = (book)=>{
		return ()=>{
			if(book.type=='manga' || showingWishlist){
				props.navigation.navigate('Book', {
					manga_id: book.manga_id,
					manga_name: book.name || book.manga_name,
					mangaka_name: book.authors ? book.authors[0] : null,
					image: book.images[0],
					onEdit: (manga_id, data)=>{
						try{
							var ls = [...librarySearch];
							for(var i of ls){
								if(i.manga_id==manga_id){
									if(typeof i.manga_name === 'undefined' && i.volumes==1){
										i.name = data.manga_name;
									}
									if(typeof i.volume_start === 'undefined'){
										i.volume_start = data.volume_start;
									}
									if(typeof i.volume_end === 'undefined'){
										i.volume_end = data.volume_end;
									}
									if(data.authors){
										i.authors = data.authors.map(a=>a.mangaka_name);
									}
								}
							}
							setLibrarySearch(ls);
	
							var lib = [...library];
							for(var i of lib){
								if(i.manga_id==manga_id){
									if(typeof i.manga_name !== 'undefined' && i.volumes==1){
										i.name = data.manga_name;
									}
									if(typeof i.volume_start !== 'undefined'){
										i.volume_start = data.volume_start;
									}
									if(typeof i.volume_end !== 'undefined'){
										i.volume_end = data.volume_end;
									}
									if(data.authors){
										i.authors = data.authors.map(a=>a.mangaka_name);
									}
								}
							}
							setLibrary(lib);
						}catch(e){}
					},
					onOwned: (manga_id, owned)=>{
						var ls = [...librarySearch];
						var ix = ls.findIndex(a=>a.manga_id==manga_id);
						if(ix==-1) return;
						else ls[ix].owned = owned;
						setLibrarySearch(ls);
					},
					onWishlist: (manga_id, wanted, manga)=>{
						if(!wishlist) return;
						var wsh = [...wishlist].filter(a=>a.manga_id!=manga_id);
						if(wanted){
							wsh.push({
								authors: manga.authors ? manga.authors.map(a=>a.mangaka_name) : [],
								date_release: manga.date_release,
								images: [manga.image],
								isbn: manga.isbn,
								manga_id: manga.manga_id,
								manga_name: manga.manga_name,
								series_name: manga.series ? manga.series.series_name : manga.manga_name,
								volume_start: manga.volume_start,
								volume_end: manga.volume_end,
							})
						}
						setWishlist(wsh);
					}
				})
			}else{
				return Alert.alert('Boxset', 'Boxset has not been cataloged.');
				// props.navigation.navigate('Boxset', {
				// 	manga_id: book.manga_id,
				// 	manga_name: book.name,
				// 	mangaka_name: book.authors ? book.authors[0] : null,
				// 	image: book.images[0]
				// })
			}
		}
	}

	var showSeries = (series)=>{
		return ()=>{
			var data = { 
				series, 
				owned: true,
			}
			if(isOtherLibrary){
				data.user_id = user.user_id;
				data.username = user.username;
			}
			props.navigation.navigate('Series', data);
		}
	}

	var showScanner = ()=>{
		props.navigation.navigate('Scanner', {
			onAdd: ()=>{
				Alert.alert('Volumes added to your library!');
				refreshLibrary(true);
			}
		});
	}

	var showWishlist = ()=>{
		setSearchMode(false);
		setSearchValue('');
		setLibrarySearch([]);
		setShowingWishlist(true);
		if(wishlist===null){
			refreshWishlist();
		}
	}

	var showLibrary = ()=>{
		setShowingWishlist(false);
	}
	
	useEffect(()=>{
		refreshLibrary(false);
		API.getLogin().then(setLoggedUser);
	}, []);

	var locationChange = (loc)=>{
		return ()=>{
			setSearchLocation(loc);
			if(searchMode){
				searchLibrary(searchValue, true, loc);
			}
		}
	}

	var RenderSearch = ()=>{
		if(showingWishlist) return null;
		return <View>
			<Input style={styles.searchInput} containerStyle={styles.searchInputContainer} icon="search" placeholder={`Search ${libraryOwner}library...`} value={searchValue} onChangeText={searchLibrary} autoCorrect={false} onFocus={()=>setSearchInputFocus(true)} onBlur={()=>setSearchInputFocus(false)} clearableBlurred={true} inputRef={inputRef} onClear={()=>inputRef.current?.focus()} />
			{/* {(searchInputFocus || searchMode) && !isOtherLibrary ? (
				<View style={{ flexDirection: 'row', marginTop: 10 }}>
					<Button text="My library" toggle toggleValue={searchLocation==0} height={30} style={{ flex: 1, marginRight: 10 }} onPress={locationChange(0)} toggleBorderWidth={0} />
					<Button text="Everywhere" toggle toggleValue={searchLocation!=0} height={30} style={{ flex: 1 }} onPress={locationChange(1)} toggleBorderWidth={0} />
				</View>
			) : null} */}
			{searching || searchError ? (
				<LoadingHeader error={searchError} text={`Searching ${!user_id ? 'your' : user.username} mountain...`} style={{ marginTop: showingWishlist ? 0 : 30 }} />
			) : null}
		</View>
	}

	const styles = StyleSheet.create({
		searchInput: {
			borderRadius: 100,
			margin: 0,
			backgroundColor: dark ? colors.border : '#E0E0E0',
			height: 40,
			paddingHorizontal: 15
		},
		searchInput: {
			height: 50,
			paddingHorizontal: 0,
			backgroundColor: colors.background,
			marginBottom: -5,
			// backgroundColor: 'red',
		},
		searchInputContainer: {
			borderRadius: 100,
			height: 40
		}
	});

	var headerScan = ()=>{
		if(!scanEnable) return null;
		return <HeaderIcon onPress={showScanner} icon={'barcode-scan'} material={true} text='Scan' size={20} background />
	}
	var headerWishlist = ()=>{
		if(showingWishlist){
			return <HeaderIcon icon="book" onPress={showLibrary} solid text="Library" size={18} background backgroundColor={colors.primary} color='white' />
		}
		return <HeaderIcon icon="star" onPress={showWishlist} solid text="Wishlist" size={20} background />
	}

	if((!library || library.length==0 || (showingWishlist && (!wishlist || wishlist.length==0))) && !searchMode){
		return <HeaderScroll
			root={!isOtherLibrary}
			headerText={`${libraryOwner}${showingWishlist ? 'Wishlist' : 'Library'}`}
			headerShown={false}
			refreshControl={
				<RefreshControl refreshing={refreshing} onRefresh={showingWishlist ? refreshWishlist : refreshLibrary} />
			}
			headerRight={headerScan()}
			headerLeft={showingWishlist ? headerWishlist() : null}
			headerWidth={100}
			style={StyleSheet.absoluteFill}
		>
			{RenderSearch()}
			{showingWishlist && wishlist && wishlist.length==0 ? (
				<LoadingHeader
					retry
					error={user_id ? `${user.username} doesn\'t have any manga on their wishlist!` : 'No books added to your wishlist'}
					icon={'star'} 
					iconSolid
					retryButtonText={'Reload'} 
					onRetry={showingWishlist ? refreshWishlist : refreshLibrary} 
				/>
			) : !searching ? (
				<LoadingHeader
					retry
					error={error}
					icon={library ? 'sad-tear' : 'exclamation-circle'} 
					iconSolid
					retryButtonText={library ? 'Reload' : 'Retry'} 
					text={`Loading ${showingWishlist ? 'wishlist' : 'library'}`} 
					onRetry={showingWishlist ? refreshWishlist : refreshLibrary} 
					style={{ marginTop: showingWishlist ? 20 : 40 }} 
				/>
			) : null}
		</HeaderScroll>
	}

	return (
		<HeaderScroll
			root={!isOtherLibrary}
			headerText={`${libraryOwner}${showingWishlist ? 'Wishlist' : 'Library'}`}
			headerShown={false}
			headerLeft={!isOtherLibrary ? headerWishlist() : null}
			headerWidth={100}
			headerRight={headerScan()}
			style={StyleSheet.absoluteFill}
			scrollRef={scrollRef}
			flatListItems={[0, ...(showingWishlist ? wishlist : (searchMode ? librarySearch : library))]}
			flatListRenderItem={a=>a.index>0 ? (
				<BookItem imageScale={1.1} book={a.item} onPress={(a.item.volumes && a.item.volumes>1) ? showSeries(a.item) : showBook(a.item)} />
			) : RenderSearch()}
			flatListKeys={a=>(`${a.type}-${a.manga_id || 0}-${a.boxset_id || 0}`)}
			refreshControl={
				<RefreshControl refreshing={refreshing} onRefresh={showingWishlist ? refreshWishlist : refreshLibrary} />
			}
			flatListFooter={loadingMore && !searching ? (
				<View style={{ marginTop: 20 }}>
					<ActivityIndicator color={'gray'} />
				</View>
			) : null}
			flatListProps={{
				onEndReached: loadMore,
				removeClippedSubviews: true,
				onEndReachedThreshold: 0.3,
				keyboardShouldPersistTaps: setSearchInputFocus,
				keyboardDismissMode: 'on-drag'
			}}
		/>
	)
}