import React, { useState, useEffect } from 'react';
import { Text, View, TouchableOpacity, StyleSheet, ActivityIndicator, ScrollView, Alert, Image } from 'react-native';
import { ImageFallback } from '../component';
import { useTheme } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { getColorContrast, getShade } from '../Util';
import Markdown from 'react-native-markdown-display';
import moment from 'moment';

export default props=>{
	var { colors, dark } = useTheme();
	var message = props.route && props.route.params ? props.route.params.message : null;

	useEffect(()=>{
		props.navigation.setOptions({
			title: message.message_title
		})
	})
	
	var shade = getShade(message.message_color || colors.primary, -10);
	var contrast = getColorContrast(message.message_color);

	const styles = StyleSheet.create({
		headerContainer: {
			width: '100%',
			height: 150,
			alignItems: 'center',
			justifyContent: 'center',
		},
		gradient: {
			width: '100%',
			height: '100%',
			position: 'absolute'
		},
		title: {
			fontSize: 30,
			fontWeight: 'bold',
			textAlign: 'center',
			color: contrast,
			paddingHorizontal: 15,
		},
		widthConstraint: {
			maxWidth: 500,
			width: '100%',
			alignSelf: 'center'
		},
		messageContainer: {
			maxWidth: 600,
			width: '100%',
			alignSelf: 'center',
		},
		subtitle: {
			fontSize: 18,
			fontWeight: 'bold',
			textAlign: 'center',
			color: contrast,
		},
		dateText: {
			color: 'gray',
			fontSize: 18,
			textAlign: 'center',
			fontWeight: 'bold',
			marginTop: 15
		},
		messageContents: {
			fontSize: 16,
			color: colors.text,
			padding: 15,
			textAlign: 'justify'
		},
		displayContainer: {
			margin: 15,
			backgroundColor: colors.card,
			padding: 15,
			borderRadius: 9,
			flexDirection: 'row',
			alignItems: 'center'
		},
		displayUserImage: {
			width: 80,
			height: 80,
			borderRadius: 100,
			borderColor: colors.border,
			borderWidth: 5,
			marginRight: 15
		},
		displayHeader: {
			fontSize: 24,
			fontWeight: 'bold',
			color: colors.text,
		},
		displayMeta: {
			fontSize: 16,
			color: 'gray',
			fontStyle: 'italic'
		},
		displayMangaImage: {
			width: 80,
			height: 120,
			borderRadius: 9,
			marginRight: 15
		}
	})

	var manga_release = message.manga_release ? moment.unix(message.manga_release) : null;

	return (
		<ScrollView>
			<View style={styles.headerContainer}>
				<LinearGradient colors={['#'+message.message_color, shade]} style={styles.gradient} />
				<Text style={[styles.subtitle, styles.widthConstraint]}>{message.message_subtitle}</Text>
				<Text style={[styles.title, styles.widthConstraint]}>{message.message_title}</Text>
			</View>
			<Text style={styles.dateText}>{moment.unix(message.date_created).format('MMMM DD, YYYY')}</Text>
			<View style={styles.messageContainer}>
				{message.user_display ? (
					<TouchableOpacity style={styles.displayContainer} onPress={()=>{
						props.navigation.push('Profile', {
							user_id: message.user_display,
							username: message.username
						});
					}}>
						<ImageFallback style={styles.displayUserImage} url={message.user_image} fallbackText={message.username[0].toUpperCase()} />
						<View style={{ flex: 1 }}>
							<Text style={styles.displayHeader} numberOfLines={1}>{message.username}</Text>
							<Text style={styles.displayMeta}>Joined {moment.unix(message.user_joined).format('MMMM, YYYY')}</Text>
						</View>
					</TouchableOpacity>
				) : message.manga_display ? (
					<TouchableOpacity style={styles.displayContainer} onPress={()=>{
						props.navigation.push('Book', {
							manga_id: message.manga_display,
							manga_name: message.manga_name,
							image: message.manga_image,
						})
					}}>
						<Image source={{ uri: `${message.manga_image}/250.jpg` }} style={styles.displayMangaImage} />
						<View style={{ flex: 1 }}>
							<Text style={[styles.displayHeader, { fontSize: 20 }]} numberOfLines={2}>{message.manga_name}</Text>
							{manga_release ? (
								<Text style={styles.displayMeta}>Release{manga_release.isAfter(moment()) ? 's' : 'd'} {manga_release.format('MMMM DD, YYYY')}</Text>
							) : null}
						</View>
					</TouchableOpacity>
				) : null}
				<Markdown style={{
					body: {
						color: colors.text,
						fontSize: 16,
						paddingHorizontal: 15,
					},
					heading1: {
						marginTop: 20,
						fontWeight: 'bold'
					},
					heading2: {
						marginTop: 20,
						fontWeight: 'bold'
					},
					heading3: {
						marginTop: 10,
						fontWeight: 'bold'
					},
					code_block: {
						backgroundColor: colors.card,
						borderWidth: 0,
					},
					image: {
						// width: '100%',
						// height: 200,
						// backgroundColor: 'red',
						// alignItems: 'center',
					}
				}}>
					{message.message_content}
				</Markdown>
				<View style={{ height: 50 }} />
				{/* <Text style={styles.messageContents} selectable={true} userSEle>{message.message_content}</Text> */}
			</View>
		</ScrollView>
	)
}