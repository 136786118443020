import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, RefreshControl, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../../API';
import { BookItem, LoadingHeader } from '../../component';

var Duplicates = props=>{
	var { colors, dark } = useTheme();
	var [refresh, setRefresh] = useState(false);
	var [books, setBooks] = useState(null);

	var styles = StyleSheet.create({
		
	});

	var getDuplicates = (refreshing=false)=>{
		if(refreshing) setRefresh(true);
		API.getDuplicates().then(res=>{
			setRefresh(false);
			if(res.error){
				Alert.alert('Error', res.message_client);
				return props.navigation.goBack();
			}
			setBooks(res.data);
		}).catch(err=>{
			setRefresh(false);
			Alert.alert('Error', 'Unexpected error loading duplicate mangas (LCL-1)');
			return props.navigation.goBack();
		})
	}

	useEffect(()=>{
		getDuplicates();
	}, []);

	if(!books){
		return <LoadingHeader text='Loading duplicates...' />
	}

	if(books.length==0){
		return <LoadingHeader loading={false} text="No duplicate manga" icon="exclamation-circle" />
	}

	var redirectBook = (book)=>{
		return ()=>{
			if(book.manga_id.length==2){
				props.navigation.push('RedirectBook', {
					from: book.manga_id[0],
					to: book.manga_id[1],
					autoBack: true,
					onRedirect: ()=>{
						var bk = [...books];
						for(var i of bk){
							if(i.series_id==book.series_id && i.volume_start==book.volume_start && i.volume_end==book.volume_end){
								i.edited = true;
							}
						}
						setBooks(bk);
					}
				})
			}else{
				
			}
		}
	}

	return (
		<FlatList
			refreshControl={<RefreshControl refreshing={refresh} onRefresh={getDuplicates} />}
			data={books}
			keyExtractor={a=>`${a.series_id}-${a.volume_start}-${a.volume_end}`}
			renderItem={({ item })=>(
				<BookItem series 
					book={{
						name: item.series_name,
						volume_start: item.volume_start,
						volume_end: item.volume_end,
						image: item.image,
						metadata: !item.edited ? [`${item.mangas} Volumes`] : ['Redirected']
					}}
					grayed={item.edited}
					grayedText={item.edited}
					metadataStyle={item.edited ? { color: 'green' } : {}}
					onPress={redirectBook(item)}
				/>
			)}
		/>
	)
}

export default Duplicates;