import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert, RefreshControl, FlatList, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../../API';
import { BookItem, HeaderScroll } from '../../component';
import { connectActionSheet, useActionSheet } from '@expo/react-native-action-sheet'
import { getAllIndexes } from '../../Util';
import { FontAwesome5 } from '@expo/vector-icons';

var Incompletes = props=>{
	var { colors, dark } = useTheme();
	var [books, setBooks] = useState(null);
	var [refreshing, setRefreshing] = useState(false);
	var [loggedUser, setLoggedUser] = useState(null);
	var [loadingBook, setLoadingBook] = useState(null);
	var [showing, setShowing] = useState(1);
	var { showActionSheetWithOptions } = useActionSheet();

	var getData = (refresh=true)=>{
		if(refresh) setRefreshing(true);
		API.getIncompletes(showing==1).then(res=>{
			setRefreshing(false);
			if(res.error){
				Alert.alert('Error', res.message_client);
				return props.navigation.goBack();
			}
			setBooks(res.data);
		}).catch(err=>{
			setRefreshing(false);
			Alert.alert('Error', 'Error loading incomplete mangas.');
			props.navigation.goBack();
		})
	}

	useEffect(()=>{
		getData(false);
		API.getLogin().then(setLoggedUser);
	}, [])

	var styles = StyleSheet.create({
		filterContainer: { 
			flexDirection: 'row', 
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 10,
			padding: 5,
		},
		filter: {
			color: colors.text,
			fontSize: 16,
			textAlign: 'center',
			textAlignVertical: 'center'
		},
		filterText: {
			color: colors.text,
			fontSize: 18,
			marginLeft: 5,
			fontWeight: 'bold'
		}
	})

	var viewManga = book=>{
		return props.navigation.push('Book', book);
	}

	var editManga = book=>{
		return props.navigation.push('EditManga', {
			manga_id: book.manga_id,
			autoBack: true,
			onEdit: (manga_id, data)=>{
				var bx = [...books];
				bix = bx.findIndex(a=>a.manga_id==manga_id);
				if(bix==-1) return;
				bx[bix].manga_name = data.manga_name;
				bx[bix].authors = data.authors;
				bx[bix].volume_start = data.volume_start;
				bx[bix].volume_end = data.volume_end;
				bx[bix].edited = true;
				setBooks(bx);
			}
		})
	}

	var changeCover = book=>{
		props.navigation.push('ChangeCover', {
			manga_id: book.manga_id,
			manga_name: book.manga_name,
		});
	}

	var blacklistBook = async book=>{
		var bks = [...books];
		for(var i of bks){
			if(i.manga_id==book.manga_id){
				var blacklist = !i.blacklisted;
				setLoadingBook(book.manga_id);
				var res = await API.blacklistBook(book.manga_id, blacklist);
				if(res.error){
					return Alert.alert('Error', res.message_client);
				}else{
					i.blacklisted = blacklist;
				}
				setLoadingBook(null);
				break;
			}
		}
		setBooks(bks);
	}

	var markComplete = async book=>{
		var bks = [...books];
		for(var i of bks){
			if(i.manga_id==book.manga_id){
				var completed = !i.completed;
				setLoadingBook(book.manga_id);
				var res = await API.markMangaComplete(book.manga_id, completed);
				setLoadingBook(null);
				if(res.error){
					return Alert.alert('Error', res.message_client);
				}else{
					i.completed = completed;
				}
				break;
			}
		}
		setBooks(bks);
	}

	var showOptions = book=>{
		return ()=>{
			if(!loggedUser) return;
			var options = [
				{ title: 'View Manga', access: 1, onPress: viewManga },
				{ title: 'Edit Manga', access: 100, onPress: editManga },
				{ title: 'Change Cover', access: 50, onPress: changeCover },
				{ title: book.blacklisted ? 'Unblacklist' : 'Blacklist', access: 100, destructive: !book.blacklisted, onPress: blacklistBook },
				{ title: book.completed ? 'Unmark Complete' : 'Mark Complete', access: 100, onPress: markComplete },
			];
			if(options.length==0) return;
			options = options.filter(a=>(!a.access || a.access<=(loggedUser ? loggedUser.user_type : 1)));
			showActionSheetWithOptions({
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
				destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
			}, ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress(book);
			});
		}
	}

	var editBook = book=>{
		return ()=>{
			if(loggedUser && loggedUser.user_type>=100){
				editManga(book);
			}
		}
	}

	var showShowingOptions = ()=>{
		var options = [
			{ title: 'Only English', showing: 1 },
			{ title: 'All Languages', showing: 2 }
		];
		showActionSheetWithOptions({
			options: [...options.map(a=>a.title), 'Close'],
			cancelButtonIndex: options.length,
			destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
		}, ix=>{
			if(options[ix] && options[ix].showing){
				if(showing!=options[ix].showing){
					setShowing(options[ix].showing);
					getData(true);
				}
			}
		});
	}

	return (
		<FlatList
			style={StyleSheet.absoluteFill}
			refreshControl={<RefreshControl onRefresh={getData} refreshing={refreshing} />}
			data={books}
			keyExtractor={(item)=>`manga-${item.manga_id}`}
			ListHeaderComponent={(
				<TouchableOpacity style={styles.filterContainer} onPress={showShowingOptions}>
					<Text style={styles.filter}>Showing:</Text>
					<Text style={styles.filterText}>{showing==1 ? 'English' : 'All'}</Text>
					<FontAwesome5 name={'chevron-down'} color={colors.text} style={{ marginLeft: 5 }} />
				</TouchableOpacity>
			)}
			renderItem={({ item })=>(
				<BookItem 
					key={item.manga_id} 
					series 
					book={{
						manga_name: item.series_name,
						volume_start: (item.volume_start || 'NULL'),
						authors: item.authors,
						image: item.image,
						metadata: item.edited ? ['Edited'] : (item.completed ? ['Completed'] : item.blacklisted ? ['Blacklisted'] : null)
					}} 
					loading={loadingBook==item.manga_id}
					metadataStyle={{ color: ((item.completed || item.edited) ? 'green' : colors.primary) }}
					grayed={(!!item.blacklisted || !!item.completed || !!item.edited)}
					grayedText={(!!item.blacklisted || !!item.completed || !!item.edited)}
					onPress={editBook(item)}
					onLongPress={showOptions(item)} 
				/>
			)}
		>
		</FlatList>
	)
}

export default connectActionSheet(Incompletes);