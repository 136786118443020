import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { useTheme } from '@react-navigation/native';
import PropTypes from 'prop-types';

var ImageFallback = props=>{
	var { colors, dark } = useTheme();
	var [source, setSource] = useState(props.url);
	var [errored, setErrored] = useState(false);

	var styles = StyleSheet.create({
		fallbackText: {
			fontSize: 50,
			fontWeight: 'bold',
			color: colors.text,
			textAlign: 'center',
			color: 'gray',
		}
	})

	useEffect(()=>{
		if(props.url){
			setErrored(false);
			setSource(props.url)
		}
		else{
			setErrored(true);
			setSource(null);
		}
	}, [props.url])

	var loadError = ()=>{
		setErrored(true);
		if(props.fallback) setSource(props.fallback);
	}

	if(!source || (errored && props.fallbackText && !props.fallback)){
		return <View {...props}>
			<View style={{ justifyContent: 'center', height: '100%' }}>
				<Text style={[styles.fallbackText, props.fallbackTextStyle]}>{props.fallbackText}</Text>
			</View>
		</View>
	}

	return (
		<Image
			{...props}
			source={typeof source==='number' ? source : { uri: source }}
			onError={loadError}
		/>
	)
}

ImageFallback.propTypes = {
	url: PropTypes.string,
	fallback: PropTypes.string,
	fallbackText: PropTypes.string,
	fallbackTextStyle: PropTypes.object,
}

export default ImageFallback;