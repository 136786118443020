import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, Alert } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { BookItem, Button, HeaderScroll, Input, LoadingHeader } from '../../component';
import API from '../../API';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { getAllIndexes } from '../../Util';

const SOURCES = [{
	name: 'BN',
	color: '#3E5962'
}, {
	name: 'BN-S',
	color: '#3E5962',
}, {
	name: 'RS',
	color: '#D63E3E',
}];

var Scraper = props=>{
	var { colors, dark } = useTheme();
	var [loggedUser, setLoggedUser] = useState(null);
	var [source, setSource] = useState(0);
	var [query, setQuery] = useState('');
	var [loading, setLoading] = useState(false);
	var [results, setResults] = useState(null);
	var [sending, setSending] = useState(false);
	var [loadingBook, setLoadingBook] = useState(null);
	var [disabledBooks, setDisabledBooks] = useState([]);
	var [showOwned, setShowOwned] = useState(true);
	var insets = useSafeAreaInsets();
	var { showActionSheetWithOptions } = useActionSheet()

	var styles = StyleSheet.create({
		input: {
			height: 40,
			flex: 1,
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		button: {
			width: 60,
			height: 40,
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0
		},
		footer: {
			position: 'absolute',
			bottom: 0,
			paddingHorizontal: 15,
			paddingVertical: 10,
			width: '100%',
			backgroundColor: colors.card,
			flexDirection: 'row',
			alignItems: 'center'
		},
		footerText: {
			color: colors.text,
			fontSize: 20,
			fontWeight: 'bold',
			flex: 1
		},
		disclamer: {
			color: colors.text,
			fontSize: 14,
			fontWeight: 'bold',
			textAlign: 'center',
			marginTop: 5
		}
	})

	useEffect(()=>{
		API.getLogin().then(setLoggedUser);
	}, []);

	var selectSource = ()=>{
		props.navigation.push('SelectionList', {
			values: [
				{ text: 'Barnes & Noble', value: 0 },
				{ text: 'Barnes & Noble Series', value: 1 },
				{ text: 'RightStuf', value: 2 },
			],
			onSelect: (val)=>{
				if(!val) return;
				setSource(val.value);
				search(val.value);
			}
		})
	}

	var search = (src=null)=>{
		if(sending) return;
		if(!src) src = source;
		if(query.length<3) return;
		setLoading(true);
		API.scrape(query, src).then(res=>{
			setLoading(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			setDisabledBooks([]);
			setResults(res.data);
		}).catch(err=>{
			setLoading(false);
		})
	}

	var disableBook = book=>{
		return ()=>{
			if(sending) return;
			if(book.owned) return;
			var db = [...disabledBooks];
			if(db.indexOf(book.isbn)==-1){
				db.push(book.isbn);
			}else{
				db = db.filter(a=>a!=book.isbn);
			}
			setDisabledBooks(db);
		}
	}

	var addBooks = async ()=>{
		if(sending) return;
		var books = results.filter(a=>!a.owned).filter(a=>disabledBooks.indexOf(a.isbn)==-1);
		if(books.length==0) return;
		setSending(true);
		for(var i of books){
			try{
				setLoadingBook(i.isbn);
				var res = await API.addISBN(i.isbn);
				if(res.error) i.errored = true;
				else{
					i.owned = true;
					i.errored = false;
				}
			}catch(e){
				i.errored = true;
			}
		}
		setLoadingBook(null);
		var search_res = [...results];
		for(var i of books){
			var ix = search_res.findIndex(a=>a.isbn==i.isbn);
			search_res[ix].owned = i.owned;
			search_res[ix].errored = i.errored;
		}
		setResults(search_res);
		setSending(false);
	}

	var addSingle = async (book, source)=>{
		if(sending) return;
		setSending(true);
		setLoadingBook(book.isbn);
		var res = null;
		try{
			res = await API.addISBN(book.isbn, source);
		}catch(e){
			res = null;
		}
		setSending(false);
		setLoadingBook(null);
		if(res.error || !res) Alert.alert('Error', res ? res.message_client : 'Unexpected error adding ISBN.');

		var book_res = [...results];
		var ix = book_res.findIndex(a=>a.isbn==book.isbn);
		if(ix!=-1){
			book_res[ix].owned = res && !res.error;
			book_res[ix].errored = !res ? false : !!res.error;
		}
		setResults(book_res);
	}

	var bookOptions = (book)=>{
		return ()=>{
			if(sending || book.owned) return;
			var options = [
				{ title: `Add ISBN${book.errored ? ' (Second try)' : ''}`, access: 1, onPress: ()=>{
					addSingle(book, book.errored ? 1 : 0)
				}},
				{ title: 'Add ISBN Barnes', access: 100, onPress: ()=>{
					addSingle(book, 1);
				}}
			];
			if(options.length==0) return;
			options = options.filter(a=>(!a.access || a.access<=(loggedUser ? loggedUser.user_type : 1)));
			showActionSheetWithOptions({
				options: [...options.map(a=>a.title), 'Close'],
				cancelButtonIndex: options.length,
				destructiveButtonIndex: getAllIndexes(options.map(a=>a.destructive), true)
			}, ix=>{
				if(options[ix] && options[ix].onPress) options[ix].onPress(book);
			});
		}
	}

	var good_books = results ? results.filter(a=>!a.owned).filter(a=>disabledBooks.indexOf(a.isbn)==-1) : [];
	var shown_results = results ? (showOwned ? results : results.filter(a=>!a.owned && disabledBooks.findIndex(b=>b==a.isbn)==-1)) : null;

	return <View style={StyleSheet.absoluteFill}>
		<ScrollView keyboardDismissMode='on-drag' style={{ paddingHorizontal: 10 }} scrollIndicatorInsets={{ right: 0 }} >
			<View style={{ flexDirection: 'row' }}>
				<Input containerStyle={styles.input} style={{ flex: 1 }} value={query} onChangeText={setQuery} placeholder={'Search'} icon={'search'} onSubmitEditing={()=>search()} loading={loading} autoCorrect={false} />
				<Button style={styles.button} color={SOURCES[source]?.color} text={SOURCES[source]?.name} onPress={selectSource} />
			</View>
			{shown_results ? (
				results.length==0 ? (
					<LoadingHeader icon={'sad-tear'} text="No results!" loading={false} />
				) : (
					<View>
						<Text style={styles.disclamer}>Press to exclude, long press for more options.</Text>
						{shown_results.map(a=>{
							var disabled = disabledBooks.indexOf(a.isbn)!=-1;
							return <BookItem numberOfLines={4} key={a.isbn} grayed={a.owned || disabled} book={{
								manga_name: a.title,
								image: a.image,
								isbn: a.isbn,
								metadata: [
									`Series: ${a.series_name}`,
									`Volume: ${a.volume_start}`+(a.volume_end ? `-${a.volume_end}` : ''),
									a.errored && !disabled ? 'Errored' : (a.owned ? 'Already added' : null),
								]
							}} metadataStyle={a.errored ? { color: colors.primary } : null} loading={loadingBook==a.isbn} grayedText onPress={disableBook(a)} onLongPress={bookOptions(a)} />
						})}
					</View>
				)
			) : null}
			<View style={{ height: insets.bottom+15+80 }} />
		</ScrollView>
		<View style={styles.footer}>
			<Text style={styles.footerText}>{good_books.length} Books</Text>
			{results && (results.filter(a=>!a.owned).length-disabledBooks.length)>0 ? (
				<Button text="Add Books" color={colors.primary} width={150} loading={sending} onPress={addBooks} />
			) : null}
			<Button text={`${showOwned ? 'Hide' : 'Show'} Owned`} style={{ marginLeft: 10 }} textStyle={{ textAlign: 'center', fontSize: 14 }} color={showOwned ? '#1F9CF0' : '#0A304A'} width={80} onPress={()=>setShowOwned(!showOwned)} />
		</View>
	</View>
}

export default Scraper;