import React, { useState, useEffect } from 'react';
import { View, Alert, StyleSheet, Platform } from 'react-native';
import { HeaderScroll, LoadingHeader, SettingItem } from '../../component';
import API from '../../API';
import * as Google from 'expo-auth-session/providers/google'
import * as AppleAuthentication from 'expo-apple-authentication';
import { connectActionSheet, useActionSheet } from '@expo/react-native-action-sheet';
import { useTheme } from '@react-navigation/native';

const GOOGLE_CREDENTIALS = API.GOOGLE_CREDENTIALS;

var Connections = props=>{
	var { colors } = useTheme();
	const { showActionSheetWithOptions } = useActionSheet();
	var [connections, setConnections] = useState(null);
	var [loadingApple, setLoadingApple] = useState(false);
	var [loadingGoogle, setLoadingGoogle] = useState(false);

	const [request, response, authGoogle] = Google.useAuthRequest({	
		expoClientId: GOOGLE_CREDENTIALS.web.client,
		iosClientId: GOOGLE_CREDENTIALS.ios[GOOGLE_CREDENTIALS.is_dev ? 'dev' : 'live'],
		androidClientId: GOOGLE_CREDENTIALS.android,
		webClientId: GOOGLE_CREDENTIALS.web,
	});

	useEffect(()=>{
		if(connections===null){
			API.getConnections().then(res=>{
				if(res.error){
					Alert.alert('Error', 'Unexpected error loading user\'s connections.');
					return props.navigation.goBack();
				}
				setConnections(res.data);
			}).catch(err=>{
				Alert.alert('Error', 'Unexpected error loading user\'s connections.');
				props.navigation.goBack();
			})
		}
		if(response?.type==='success'){
			if(!response.authentication.idToken){
				return Alert.alert('Error', 'Unexpected error logging in with Google.');
			}
			connectGoogle(response.authentication.idToken);
		}
	}, [response]);

	var disconnectApple = ()=>{
		if(!connections.has_password && !connections.google_auth){
			return Alert.alert('Error', 'This account doesn\'t have a password. If you want to disconnect your Apple account you must set a password.');
		}
		setLoadingApple(true);
		API.disconnectApple().then(res=>{
			setLoadingApple(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('Account disconnected', 'Your Apple account has been disconnected from this user.');
			setConnections({
				...connections,
				apple_auth: false
			})
		}).catch(err=>{
			setLoadingApple(false);
			Alert.alert('Unexpected error disconnecting Apple account. (LCL-1)');
		})
	}

	var connectApple = ()=>{
		setLoadingApple(true);
		AppleAuthentication.signInAsync({
			requestedScopes: [AppleAuthentication.AppleAuthenticationScope.EMAIL],
		}).then(creds=>{
			if(!creds || !creds.identityToken){
				Alert.alert('Error connecting', 'Could not connect Apple account (LCL-4).');
			}
			API.connectApple(creds.identityToken).then(res=>{
				setLoadingApple(false);
				if(res.error) return Alert.alert('Error', res.message_client);
				Alert.alert('Account connected', 'Your Apple account has been connected to this user.');
				setConnections({
					...connections,
					apple_auth: true
				})
			}).catch(err=>{
				setLoadingApple(false);
				return Alert.alert('Error', 'There was an error connecting Apple account (LCL-2).');
			})
		}).catch(err=>{
			setLoadingApple(false);
			if(err.code === 'ERR_CANCELED') return;
			return Alert.alert('Error', 'There was an error connecting Apple account (LCL-3).');
		})
	}

	var connectGoogle = (token)=>{
		setLoadingGoogle(true);
		API.connectGoogle(token).then(res=>{
			setLoadingGoogle(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('Account connected', 'Your Google account has been connected to this user.');
			setConnections({
				...connections,
				google_auth: true
			});
		}).catch(err=>{
			setLoadingGoogle(false);
			return Alert.alert('Error', 'There was an error connecting Google account (LCL-6).');
		})
	}

	var disconnectGoogle = ()=>{
		if(!connections.has_password && !connections.apple_auth){
			return Alert.alert('Error', 'This account doesn\'t have a password. If you want to disconnect your Google account you must set a password.');
		}
		setLoadingGoogle(true);
		API.disconnectGoogle().then(res=>{
			setLoadingGoogle(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('Account disconnected', 'Your Google account has been disconnected from this user.');
			setConnections({
				...connections,
				google_auth: false
			})
		}).catch(err=>{
			setLoadingGoogle(false);
			Alert.alert('Unexpected error disconnecting Google account. (LCL-5)');
		})
	}

	var showAppleOptions = ()=>{
		showActionSheetWithOptions(
			{
				options: [!connections.apple_auth ? 'Connect Apple Account' : 'Disconnect Apple Account', 'Close'],
				cancelButtonIndex: 1,
			},
			ix=>{
				if(ix==0 && connections.apple_auth){
					disconnectApple();
				}else if(ix==0 && !connections.apple_auth){
					connectApple();
				}
			}
		);
	}

	var showGoogleOptions = ()=>{
		showActionSheetWithOptions(
			{
				options: [!connections.google_auth ? 'Connect Google Account' : 'Disconnect Google Account', 'Close'],
				cancelButtonIndex: 1,
			},
			ix=>{
				if(ix==0 && connections.google_auth){
					disconnectGoogle();
				}else if(ix==0 && !connections.google_auth){
					authGoogle();
				}
			}
		);
	}

	const styles = StyleSheet.create({
		iconRight: {
			marginRight: 10, 
			fontSize: 24, 
			color: colors.text
		},
		loading: {
			marginRight: 13,
		}
	})
	
	return (
		<HeaderScroll
			headerText={'Account Connections'}
			containerTextStyle={{ paddingHorizontal: 15 }}
			scrollStyle={{ paddingHorizontal: 0 }}
		>
			{connections ? (
				<View>
					{Platform.OS=='ios' && <SettingItem text='Sign in with Apple' icon='apple' iconRight={connections.apple_auth ? 'check-circle' : 'circle'} styleIconRight={styles.iconRight} iconRightSolid={connections.apple_auth} onPress={showAppleOptions} loading={loadingApple} />}
					<SettingItem text='Sign in with Google' icon='google' iconRight={connections.google_auth ? 'check-circle' : 'circle'} styleIconRight={styles.iconRight} iconRightSolid={connections.google_auth} onPress={showGoogleOptions} loading={loadingGoogle} loadingStyle={styles.loading} />
				</View>
			) : (
				<LoadingHeader text='Loading connections...' loading={true} />
			)}
		</HeaderScroll>
	)
}

export default connectActionSheet(Connections);