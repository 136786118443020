import React from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';

var ProfileButton = props=>{
	var { colors } = useTheme();

	var styles = StyleSheet.create({
		button: {
			backgroundColor: props.color || colors.card,
			flex: 1,
			marginHorizontal: 3,
			padding: 10,
			borderRadius: 9
		},
		buttonText: {
			color: colors.text,
			fontSize: 16,
			fontWeight: 'bold',
			textAlign: 'center',
		},
		buttonIcon: {
			color: props.iconColor || colors.text,
			fontSize: 30,
			textAlign: 'center',
			marginBottom: 5
		},
		badge: {
			padding: 5,
			borderRadius: 100,
			backgroundColor: colors.primary,
			position: 'absolute',
			top: 0,
			right: 0,
		},
		badgeText: {
			color: 'white',
			fontWeight: 'bold',
			minWidth: 14,
			textAlign: 'center',
			height: 15,
			marginTop: -1
		}
	})

	return (
		<TouchableOpacity style={styles.button} onPress={props.onPress}>
			{typeof props.five!=='undefined' && props.five!==false ? (
				<FontAwesome5 solid={props.solid!==false} name={props.icon} style={styles.buttonIcon} />
			) : (
				<FontAwesome solid={props.solid!==false} name={props.icon} style={styles.buttonIcon} />
			)}
			<Text style={styles.buttonText}>{props.text}</Text>
			{props.badge ? (
				<View style={styles.badge}>
					<Text style={styles.badgeText}>{props.badge}</Text>
				</View>
			) : null}
		</TouchableOpacity>
	)
}

ProfileButton.propTypes = {
	onPress: PropTypes.func,
	five: PropTypes.bool,
	icon: PropTypes.string,
	text: PropTypes.string,
	solid: PropTypes.bool,
	iconColor: PropTypes.string,
	color: PropTypes.string,
}

export default ProfileButton