import React, { useState, useEffect, useRef } from 'react';
import { Text, View, Image, Vibration, StyleSheet, ActivityIndicator, ScrollView, Alert, Dimensions, SafeAreaView, TouchableWithoutFeedback, Platform } from 'react-native';
import API from '../API';
import ISBNLib from 'isbn3';
import { Modalize } from 'react-native-modalize';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useTheme } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Button from '../component/Button'

const DEFAULT_MODAL_HEIGHT = 90;
const MODAL_HEIGHT = DEFAULT_MODAL_HEIGHT*2;

export default props=>{
	var onAdd = props.route && props.route.params ? props.route.params.onAdd : null;
	var [hasPermission, setHasPermission] = useState(null);
	var [loading, setLoading] = useState(false);
	var [cameraEnabled, setCameraEnabled] = useState(true)
	var [sending, setSending] = useState(false);
	var [books, setBooks] = useState([]);
	var modalRef = useRef(null);

	var { height, width } = Dimensions.get('screen');
	var { colors, dark } = useTheme();
	var insets = useSafeAreaInsets();

	var scanned = ({ data })=>{
		if(loading) return;
		addISBN(data);
	}

	var ErrorLoading = (text, title)=>{
		Alert.alert((title || 'Error'), text, [
			{ style: 'default', text: 'Close', onPress: ()=>{
				setLoading(false);
			}}
		]);
	}

	var ISBNAlert = async ()=>{
		var v = await API.getSettingKey('language-alert');
		if(!v){
			return new Promise((resolve, reject)=>{
				Alert.alert('Disclamer', 'Yama currently only works great with english spoken volumes. Other languages or regions may not have the same experience. We do our best to support other languages.', [{
					text: 'Confirm',
					onPress: ()=>{
						try{
							API.setSetting('language-alert', '1');
						}catch(e){}
						resolve();
					}
				}]);
			})
		}
	}

	var addISBN = async isbn=>{
		var parsedIsbn = ISBNLib.parse(isbn);
		if(!parsedIsbn || !parsedIsbn.isValid){
			setLoading(true);
			return ErrorLoading('Invalid ISBN number, please use ISBN-13 or ISBN-10.');
		}

		for(var i of books){
			if(i.data.isbn==isbn) return;
		}

		if(parsedIsbn.group!=1){
			await ISBNAlert();
		}

		setLoading(true);
		try{
			var res = await API.getISBN(isbn, true)
		}catch(e){
			return ErrorLoading('Unexpected error searching for book (LCL-SC-1).');
		}
		if(res.error) return ErrorLoading(res.message_client);
		if(!res.data || (!res.data.boxset && !res.data.manga)) return ErrorLoading('Invalid ISBN number or no manga found with that ISBN (LCL-SC-2)');
		if(res.data.boxset && !res.data.boxset.completed){
			return ErrorLoading('We know you just scanned a boxset but we don\'t know which volumes are in it :(. You can still add your manga by scanning each volume in the boxset.', 'Boxset not catalogued')
		}
		if(res.data.boxset){
			setLoading(false);
			setCameraEnabled(false);
			return props.navigation.push('BoxsetAdd', res.data.boxset);
		}

		setLoading(false);
		setBooks([{
			boxset: res.data.boxset ? true : false,
			data: res.data.boxset || res.data.manga,
			own: true,
			already_owned: !!(res.data.ownership && res.data.ownership.owned),
			read: !!(res.data.ownership && res.data.ownership.has_read),
			want: false,
		}, ...books]);
		Vibration.vibrate(100);
	}

	useEffect(()=>{
		BarCodeScanner.requestPermissionsAsync().then(perm=>{
			setHasPermission(perm.status==='granted');
		}).catch(err=>{
			setHasPermission(false);
		});
		
		const unsubscribe = props.navigation.addListener('focus', () => {
			setCameraEnabled(true);
		});
		return unsubscribe;
	}, [])
	
	if(!hasPermission){
		return <View />
	}

	var style = StyleSheet.create({
		viewfinder: {
			width: 200,
			height: 120,
			backgroundColor: 'red',
			alignSelf: 'center',
			borderRadius: 10,
			// marginTop: -insets.bottom-MODAL_HEIGHT,
			backgroundColor: '#FFFFFF40'
		},
		viewfinderText: {
			color: 'white',
			textAlign: 'center',
			fontWeight: 'bold',
			marginTop: 10,
			fontSize: 20
		},
		viewfinderBackground: {
			backgroundColor: '#00000040',
			...StyleSheet.absoluteFill
		},
		modalStyle: {
			backgroundColor: colors.card,
			overflow: 'hidden',
			width: '100%',
			maxWidth: 600,
			alignSelf: 'center',
		},
		modalHeader: {
			padding: 15,
			paddingTop: 25,
			backgroundColor: colors.border,
			marginBottom: 10,
			justifyContent: 'space-between',
			alignItems: 'center',
			flexDirection: 'row'
		},
		modalHeaderText: {
			fontSize: 20,
			fontWeight: 'bold',
			color: colors.text,
			textAlign: 'center'
		},
		bookTitle: {
			color: colors.text,
			fontSize: 16,
			fontWeight: 'bold'
		},
		bookCover: {
			width: 60,
			height: 90,
			borderRadius: 4,
			backgroundColor: colors.border,
			resizeMode: 'cover'
		},
		bookMeta: {
			color: 'gray',
			fontSize: 16,
		},
		bookContainer: {
			flexDirection: 'row',
			marginBottom: 15
		}
	})

	var Book = (props)=>{
		var book = props.book;
		var boxset = props.boxset;
		if(!book) return null;
		return <View style={style.bookContainer}>
			<Image source={{ uri: `${book.image}/150.jpg` }} style={style.bookCover} />
			<View style={{ marginLeft: 10, flex: 1 }}>
				<View style={{ flexDirection: 'row' }}>
					<View style={{ flex: 1 }}>
						<Text style={style.bookTitle} numberOfLines={2}>{book.series_name || book.manga_name || book.boxset_title}</Text>
						{boxset ? (
							<Text style={style.bookMeta}>
								{book.volume_count} Volumes
							</Text>
						) : (
							<Text style={style.bookMeta}>
								Volume {book.volume_start}
								{book.volume_end ? `-${book.volume_end}` : ''}
							</Text>
						)}
						{props.alreadyOwn ? (
							<Text style={{ color: colors.primary }}>You already own this</Text>
						) : null}
					</View>
					<Button width={30} height={30} color={'transparent'} icon={'times'} size={24} onPress={()=>{
						var new_books = books.filter(a=>{
							return !(boxset ? book.boxset_id==a.data.boxset_id : book.manga_id==a.data.manga_id)
						})
						if(new_books.length==0) modalRef.current?.close('top');
						setBooks(new_books)
					}} />
				</View>
				<View style={{ marginTop: 8, width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
					<Button text='Own' height={30} width={'30%'} toggle toggleValue={props.own} onPress={props.onOwn} />
					<Button text='Read' height={30} width={'30%'} toggle toggleValue={props.read} onPress={props.onRead} />
					<Button text='Want' height={30} width={'30%'} color={'orange'} textColor={'black'} toggleValue={props.want} toggle onPress={props.onWant} />
				</View>
			</View>
		</View>
	}

	var onBookToggle = (key, isbn)=>{
		if(sending) return null;
		return val=>{
			var bix = books.findIndex(a=>a.data.isbn==isbn);
			books[bix][key] = val;
			if(key==='want'){
				books[bix].own = false;
				books[bix].read = false;
			}else{
				books[bix].want = false;
			}
			setBooks([ ...books ]);
		}
	}

	var doneAdding = ()=>{
		if(books.length==0) return;
		var manga = books.filter(a=>!a.boxset).map(a=>({
			manga_id: a.data.manga_id,
			own: a.own,
			read: a.read,
			want: a.want
		}));
		for(var i of manga){
			if(!i.own && !i.read && !i.want) return Alert.alert('Error', 'Please select own, read or want on all of the added books.');
		}
		modalRef.current?.open('top');
		setSending(true);

		API.addManga(manga).then(res=>{
			setSending(false);
			if(res.error) Alert.alert('Error', res.message_client);
			if(onAdd) onAdd(manga);
			props.navigation.goBack();
		}).catch(err=>{
			setSending(false);
			return Alert.alert('Error', 'Unexpected error adding books to your library (LCL-SC-3)');
		})
	}

	return (
		<View style={[StyleSheet.absoluteFill, { justifyContent: 'center' }]}>
			{!sending && cameraEnabled ? (
				<BarCodeScanner
					onBarCodeScanned={scanned}
					style={[StyleSheet.absoluteFillObject, style.camera]}
				/>
			) : null}
			<View style={style.viewfinderBackground} />
			{loading ? (
				<View style={{ height: 87, justifyContent: 'center' }}>
					<ActivityIndicator size={'large'} color={'white'} style={{ marginTop: -25 }} />
				</View>
			) : (
				<View style={style.viewfinder} />
			)}
			<Text style={style.viewfinderText}>{loading ? 'Searching' : 'Scan ISBN'}</Text>
			<Modalize 
				ref={modalRef} 
				modalStyle={style.modalStyle} 
				alwaysOpen={books.length>0 ? insets.bottom+MODAL_HEIGHT : 0} 
				handlePosition={'inside'} 
				modalTopOffset={height*0.2}
				handleStyle={dark ? { backgroundColor: '#404040' } : {}}
				panGestureEnabled={!sending}
				closeOnOverlayTap={!sending}
				withHandle={!sending}
				onPositionChange={t=>setCameraEnabled(t!='top')}
				HeaderComponent={(
					<View style={style.modalHeader}>
						<Text style={style.modalHeaderText}>{books.length} Book{books.length==1 ? '' : 's'} Added</Text>
						<Button text="Done" loading={sending} width={80} height={30} color={colors.text} textColor={colors.background} onPress={doneAdding} />
					</View>
				)}
			>	
				<View style={{ paddingHorizontal: 15, paddingBottom: insets.bottom }}>
					{books.map((a,i)=><Book book={a.data} boxset={a.boxset} key={'bookscan-'+i} alreadyOwn={a.already_owned} own={a.own} onOwn={onBookToggle('own', a.data.isbn)} read={a.read} onRead={onBookToggle('read', a.data.isbn)} want={a.want} onWant={onBookToggle('want', a.data.isbn)} />)}
				</View>
			</Modalize>
		</View>
	)
}
