import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, Image, Dimensions, Alert, PanResponder, Animated } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { containImage } from '../Util';
import { Button } from '../component';
import API from '../API';

export default props=>{
	var { colors, dark } = useTheme();
	var { manga_id, manga_image } = ((props.route ? props.route.params : null) || {});
	var dims = Dimensions.get('window');
	
	var [contain, setContain] = useState(null);
	var [imageDimensions, setImageDimensions] = useState(null);
	var [pan] = useState(new Animated.ValueXY());
	var [panResp, setPanResponder] = useState(null);
	var [sending, setSending] = useState(false);

	useEffect(()=>{
		props.navigation.setOptions({
			title: 'Set Profile Picture'
		});
		
		if(!manga_image || !manga_id){
			Alert.alert('Error', 'No manga selected for profile picture.');
			return props.navigation.goBack();
		}

		Image.getSize(manga_image, (width, height)=>{
			var img_contain = containImage(dims.width-20, dims.height-20-50-50, width, height);
			setPanResponder(PanResponder.create({
				onMoveShouldSetPanResponder: () => true,
				onPanResponderGrant: () => {
					pan.setOffset({
						x: pan.x._value,
						y: Math.max(0, Math.min(pan.y._value, img_contain.height-img_contain.width))
					});
				},
				onPanResponderMove: (e, gstate)=>{
					Animated.event([null, { 
						dx: pan.x,
						dy: pan.y
					}], { useNativeDriver: false })(e, gstate);
				},
				onPanResponderRelease: () => {
					pan.flattenOffset();
				}
			}));
			setImageDimensions({ width, height });
			setContain(img_contain);
		}, err=>{
			Alert.alert('Error', 'Could not load image.');
			return props.navigation.goBack();
		});
	}, []);

	var styles = StyleSheet.create({
		viewfinder: { 
			backgroundColor: '#FFFFFF88',
			borderColor: colors.primary,
			borderWidth: 3,
			borderRadius: 2000,
			position: 'absolute'
		},
		instructions: {
			fontSize: 16,
			fontWeight: 'bold',
			color: colors.text,
			textAlign: 'center',
			paddingHorizontal: 20,
			height: 50,
		}
	})

	var savePicture = ()=>{
		var max_top = contain.height-contain.width;
		var top_crop = Math.max(Math.min(pan.y._value, max_top), 0);
		var real_top_crop = Math.floor((imageDimensions.height*top_crop)/contain.height);
		setSending(true);
		API.setProfilePicture(manga_id, real_top_crop).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('Success', 'Your profile picture has been set!');
			props.navigation.goBack();
		}).catch(err=>{
			setSending(false);
			return Alert.alert('Error', 'Unexpected error setting profile picture (LCL-1)');
		})
	}
		
	return (
		<View style={{ padding: 10 }}>
			<Text style={styles.instructions}>
				Drag the section of the cover that will be your profile picture.
			</Text>
			{contain ? (
				<View>
					<ImageBackground source={{ uri: manga_image }} style={{ width: contain.width, height: contain.height }} />
					<Animated.View style={[styles.viewfinder, {
						width: contain.width, 
						height: contain.width, 
						transform: [{ translateY: pan.y.interpolate({
							inputRange: [0, contain.height-contain.width],
							outputRange: [0, contain.height-contain.width],
							extrapolate: 'clamp'
						})}]
					}]} {...panResp.panHandlers} />
					<Button height={40} loading={sending} text={'Set profile picture'} style={{ marginTop: 10 }} onPress={savePicture} />
				</View>
			) : (
				<View />
			)}
		</View>
	)
}