import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View, Image, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme } from '@react-navigation/native';
import ImageFallback from './ImageFallback';

const BookItem = props=>{
	var { book, grayed } = props;
	var { colors, dark } = useTheme();

	const styles = StyleSheet.create({
		bookContainer: {
			backgroundColor: props.color || (props.grayed ? colors.background : colors.card),
			marginTop: 10,
			borderRadius: 9,
			padding: 10,
			flexDirection: 'row',
			alignItems: 'center'
		},
		cover: {
			width: 60*(props.imageScale || 1),
			height: (props.square ? 60 : 90)*(props.imageScale || 1),
			borderRadius: 4,
			marginRight: 15,
			backgroundColor: colors.border,
			overflow: 'hidden'
		},
		coverQuad: {
			width: 30*(props.imageScale || 1),
			height: 45*(props.imageScale || 1),
		},
		coverDuo: {
			width: 30*(props.imageScale || 1),
			height: 90*(props.imageScale || 1),
		},
		title: {
			color: props.grayed && props.grayedText ? colors.border : colors.text,
			fontSize: 20,
			fontWeight: 'bold',
			marginBottom: 2,
		},
		seriesMeta: {
			color: 'gray'
		},
		grayed: {
			position: 'absolute',
			opacity: 0.9,
			backgroundColor: dark ? colors.card : colors.border,
			width: 60*(props.imageScale || 1),
			height: (props.square ? 60 : 90)*(props.imageScale || 1),
			borderRadius: 4,
			marginRight: 15,
		},
		loading: {
			position: 'absolute',
			top: 0,
			width: 60,
			height: '100%',
			backgroundColor: '#000000AA',
			borderRadius: 4,
		},
		fallbackText: {
			fontSize: 50,
			fontWeight: 'bold',
			color: colors.text,
			textAlign: 'center',
			color: 'gray',
		},
	})

	if(book.metadata){
		book.metadata = book.metadata.filter(a=>!!a);
	}

	var fallbackText = !book ? '!' : (book.series_name ? book.series_name[0] : (book.name || book.manga_name)[0]);

	return (
		<TouchableOpacity onPress={props.onPress} onLongPress={props.onLongPress} >
			<View style={styles.bookContainer}>
				{book.images && Array.isArray(book.images) && book.images.length>1 ? (
					<View style={styles.cover}>
						{book.images.length==2 ? (
							<View style={{ flexDirection: 'row' }}>
								<Image source={{ uri: `${book.images[0]}/150.jpg` }} style={styles.coverDuo} />
								<Image source={{ uri: `${book.images[1]}/150.jpg` }} style={styles.coverDuo} />
							</View>
						) : book.images.length==3 ? (
							<View style={{ flexDirection: 'row' }}>
								<View style={{ borderBottomColor: colors.background, borderBottomWidth: StyleSheet.hairlineWidth }}>
									<Image source={{ uri: `${book.images[0]}/150.jpg` }} style={styles.coverQuad} />
									<Image source={{ uri: `${book.images[1]}/150.jpg` }} style={styles.coverQuad} />
								</View>
								<View>
									<Image source={{ uri: `${book.images[2]}/150.jpg` }} style={styles.coverDuo} />
								</View>
							</View>
						) : book.images.length>=3 ? (
							<View style={{ flexDirection: 'row' }}>
								<View style={{ borderBottomColor: colors.background, borderBottomWidth: StyleSheet.hairlineWidth }}>
									<Image source={{ uri: `${book.images[0]}/150.jpg` }} style={styles.coverQuad} />
									<Image source={{ uri: `${book.images[1]}/150.jpg` }} style={styles.coverQuad} />
								</View>
								<View>
									<Image source={{ uri: `${book.images[2]}/150.jpg` }} style={styles.coverQuad} />
									<Image source={{ uri: `${book.images[3]}/150.jpg` }} style={styles.coverQuad} />
								</View>
							</View>
						) : null}
						{grayed ? (
							<View style={styles.grayed} />
						) : null}
						{props.loading ? (
							<ActivityIndicator size={'large'} color={'white'} style={styles.loading} />
						) : null}
					</View>
				) : book.images || book.image ? (
					<View>
						<ImageFallback url={`${book.image || book.images[0]}/250.jpg`} style={styles.cover} fallbackText={fallbackText} />
						{grayed ? (
							<View style={styles.grayed} />
						) : null}
						{props.loading ? (
							<ActivityIndicator size={'large'} color={'white'} style={styles.loading} />
						) : null}
					</View>
				) : (
					<View style={[styles.cover, { justifyContent: 'center' }]}>
						<Text style={styles.fallbackText}>{fallbackText}</Text>
						{grayed ? (
							<View style={styles.grayed} />
						) : null}
						{props.loading ? (
							<ActivityIndicator size={'large'} color={'white'} style={styles.loading} />
						) : null}
					</View>
				)}
				<View style={{ flex: 1 }}>
					<Text style={styles.title} numberOfLines={props.numberOfLines || 2}>{book.volumes>1 ? book.series_name : (book.name || book.manga_name)}</Text>
					{book.volumes>1 || book.type=='boxset' ? (
						book.type=='boxset' && book.volumes<=0 ? (
							<Text style={styles.seriesMeta}>Not catalogued</Text>
						) : (
							<Text style={styles.seriesMeta}>{book.volumes} volumes</Text>
						)
					) : (props.series && book.volume_start) ? (
						<Text style={[styles.seriesMeta, { fontSize: 18 }]}>Volume{book.volume_end ? 's' : ''} {book.volume_start}{book.volume_end ? `-${book.volume_end}` : ''}</Text>
					) : null}
					{book.boxsets ? (
						<Text style={styles.seriesMeta}>{book.boxsets} boxset{book.boxsets==1 ? '' : 's'}</Text>
					) : null}
					{book.authors && book.authors.length>0 ? (
						<Text style={styles.seriesMeta} numberOfLines={1}>{book.authors.join(', ')}</Text>
					) : null}
					{props.successText ? (
						<Text style={[styles.seriesMeta, { color: 'green', paddingBottom: 5, fontWeight: 'bold'}, props.successTextStyle]}>
							{props.successText}
						</Text>
					) : null}
					{book.metadata && book.metadata.length>0 ? (
						<Text style={[styles.seriesMeta, { fontStyle: 'italic', color: 'gray', paddingBottom: 5 }, props.metadataStyle]}>
							{book.metadata.map((a,i,ar)=><Text key={`metadata-${book.manga_id}-${i}`}>{a}{i<(ar.length-1) ? '\n' : ''}</Text>)}
						</Text>
					) : null}
					{book.owned ? (
						<Text style={[styles.seriesMeta, { fontStyle: 'italic', color: colors.primary }]}>{props.owner ? `${props.owner} own${props.singular ? '' : 's'} this` : 'Owned'}</Text>
					) : null}
				</View>
			</View>
		</TouchableOpacity>
	)
}

BookItem.propTypes = {
	style: PropTypes.object,
	onPress: PropTypes.func,
	onLongPress: PropTypes.func,
	book: PropTypes.object,
	imageScale: PropTypes.number,
	grayed: PropTypes.bool,
	grayedText: PropTypes.bool,
	metadata: PropTypes.array,
	metadataStyle: PropTypes.object,
	loading: PropTypes.bool,
	numberOfLines: PropTypes.number,
	color: PropTypes.string,
	square: PropTypes.bool,
	successText: PropTypes.string,
	successTextStyle: PropTypes.any,
}

export default BookItem;
