import React, { useEffect, useState } from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import { Text, StyleSheet, ScrollView, View, RefreshControl, Alert, ActivityIndicator, Dimensions } from 'react-native';
import { IconButton, BookCarrousel, LoadingHeader, ReviewCarrousel, UserCard, HeaderScroll, StatsCarrousel } from '../component';
import { mixColors, RGBToHex } from '../Util';
import API from '../API';
import * as Updates from 'expo-updates';

export default (props)=>{
	var profileUserId = props.route && props.route.params ? props.route.params.user_id : null;
	var profileUsername = props.route && props.route.params ? props.route.params.username : null;
	var elementHeader = props.route && props.route.params ? props.route.params.elementHeader : false;

	var { colors, dark } = useTheme();
	var { width: ScreenWidth } = Dimensions.get('screen');
	var [user, setUser] = useState(null);
	var [stats, setStats] = useState(null);
	var [storedUser, setStoredUser] = useState(null);
	var [shelves, setShelves] = useState(null);
	var [reviews, setReviews] = useState(null);
	var [refreshing, setRefreshing] = useState(false);
	var [loadingError, setLoadingError] = useState(null);
	var [sending, setSending] = useState(false);
	var [hasUpdate, setHasUpdate] = useState(false);

	var refreshUser = (user_id, username=false, refresh=false)=>{
		if(refresh===true) setRefreshing(true);
		if(!user_id && !username) return;
		setLoadingError(null);
		
		var fn = user_id ? API.getUser(user_id || null, true, true, true) : API.getUsername(username, true, true);

		fn.then(res=>{
			setRefreshing(false);
			if(res.error) return setLoadingError(res.message_client);
			setUser(res.data.user);
			setStats(res.data.stats || {
				books: 0,
				series: 0,
				followers: 0
			});
			setShelves(res.data.shelves || null);
			setReviews(res.data.reviews || null);
		}).catch(err=>{
			setLoadingError('Unexpected error (LCL-SH-1)');
			setRefreshing(false);
		})
	}

	useEffect(()=>{
		API.getLogin().then(user=>{
			setStoredUser(user);
			if(profileUserId && user && user.user_id==profileUserId){
				Updates.checkForUpdateAsync().then(update=>{
					setHasUpdate(update.isAvailable);
				}).catch(err=>{});
			}
			refreshUser(profileUserId, profileUsername, false);
		});
	}, []);

	const styles = StyleSheet.create({
		title: {
			fontSize: 50,
			fontWeight: 'bold',
			textAlign: 'center'
		},
		subtitle: {
			fontSize: 20,
			textAlign: 'center'
		},
		shelfTitle: {
			color: colors.text,
			marginTop: 20,
			fontSize: 24,
			fontWeight: 'bold',
			marginBottom: 8,
		},
		loadingText: { 
			textAlign: 'center', 
			fontSize: 20, 
			fontWeight: 'bold', 
			color: 'gray', 
			marginTop: 5
		},
		buttonContainer: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginHorizontal: -3
		},
		message: {
			backgroundColor: mixColors(colors.primary, RGBToHex(colors.card), 30),
			borderRadius: 9,
			padding: 15,
			marginTop: 10
		},
		messageText: {
			color: colors.text,
			fontWeight: 'bold',
			fontSize: 16,
			textAlign: 'center'
		},
		container: {
			maxWidth: 600,
			width: '100%',
			alignSelf: 'center',
		}
	})

	if(!storedUser){
		return <LoadingHeader text="Loading profile..." />
	}

	var shownUser;
	if(user){
		shownUser = user;
	}else{
		if(profileUserId && storedUser.user_id==profileUserId){
			shownUser = storedUser
			shownUser.private = false;
		}else if(profileUsername){
			shownUser = {
				username: profileUsername
			}
		}
	}
	if(storedUser && storedUser.user_id==shownUser.user_id){
		shownUser.private = false;
	}

	var showSettings = ()=>{
		props.navigation.push('Settings', {
			onUsernameChange: (user_id, username)=>{
				if(user_id==shownUser.user_id){
					setUser({
						...user,
						username,
					})
				}
			}
		});
	}

	var showAdmin = ()=>{
		if(storedUser.user_type<50) return;
		props.navigation.push('AdminMenu');
	}

	var showLibrary = ()=>{
		props.navigation.push('Library', {
			user_id: shownUser.user_id,
			scan: false
		})
	}

	var avatarPressed = ()=>{
		API.openModal(<View style={{ padding: 15, paddingBottom: 30 }}>
			<LoadingHeader icon='user-circle' iconSolid text='Change profile picture' loading={false} />
			<Text style={{ color: colors.text, textAlign: 'center', fontSize: 16, marginTop: 5 }}>
				Check out any volume, press the <FontAwesome5 name="ellipsis-h" size={20} color={colors.primary} /> icon on the upper right and select "Set cover as profile picture".
			</Text>
		</View>)

	}

	var banUser = (reason)=>{
		setSending(true);
		API.banUser(shownUser.user_id, reason).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('User banned!', `Cya, ${shownUser.username}!`);
			setUser({
				...user,
				banned: 1
			});
		}).catch(err=>{
			setSending(false);
			Alert.alert('Error', 'Unexpected error banning user (LCL-1)');
		})
	}

	var unbanUser = ()=>{
		setSending(true);
		API.unbanUser(shownUser.user_id).then(res=>{
			setSending(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('User banned!', `Welcome back, ${shownUser.username}!`);
			refreshUser(shownUser.user_id);
		}).catch(err=>{
			setSending(false);
			Alert.alert('Error', 'Unexpected error banning user (LCL-1)');
		})
	}

	var viewUserOptions = ()=>{
		props.navigation.push('SelectionList', {
			values: [
				{ text: `${shownUser.banned ? 'Unban' : 'Ban'} ${shownUser.username}`, value: 1, icon: 'user-slash', onPress: (_, back)=>{
					if(shownUser.banned){
						Alert.alert(`Unban ${shownUser.username}?`, null, [
							{ style: 'cancel', text: 'Cancel' },
							{ style: 'default', text: 'Apply', onPress: unbanUser }
						]);
					}else{
						Alert.prompt(`Ban ${shownUser.username}`, 'Enter ban reason', text=>{
							if(text.length<8) return Alert.alert('Error', 'Reason must be at least 8 characters.');
							back();
							banUser(text);
						});
						return false;
					}
				}}
			]
		});
	}

	var CONTENTS = (
		<View style={styles.container}>
			<UserCard user={shownUser} navigation={props.navigation} picturePress={avatarPressed} stats={stats} statsPlaceholder />
			{storedUser && storedUser.user_type>50 && shownUser.banned ? (
				<View>
					<IconButton icon="user-cog" text="Mod" five onPress={viewUserOptions} />
				</View>
			) : (
				<View style={styles.buttonContainer}>
					{!shownUser.private && (shownUser && storedUser && shownUser.user_id!=storedUser.user_id) ? (
						<IconButton icon="book" text="Library" onPress={showLibrary} />
					) : null}
					{/* {shownUser && storedUser && shownUser.user_id!=storedUser.user_id ? (
						<IconButton icon="user-plus" text="Follow" onPress={showLibrary} />
					) : null} */}
					{shownUser && storedUser && shownUser.user_id==storedUser.user_id ? [
						storedUser.user_type>=100 ? <IconButton key={'btn-admin-profile'} icon="shield-alt" text="Admin" five onPress={showAdmin} /> : null,
						<IconButton key={'btn-settings-profile'} icon="cog" text="Settings" five onPress={showSettings} badge={hasUpdate ? '!' : null} />
					] : storedUser && storedUser.user_type>50 ? (
						<IconButton icon="user-cog" text="Mod" five onPress={viewUserOptions} />
					) : null}
				</View>
			)}
			{shownUser.banned ? (
				<View style={styles.message}>
					<Text style={styles.messageText}>{shownUser.username} is banned</Text>
				</View>
			) : shownUser.private ? (
				<LoadingHeader loading={false} icon={'eye-slash'} text={'This user\'s profile is private'} />
			) : shelves ? (
					shelves.map(a=>(
						<View key={'shelf-'+a.shelf_id}>
							<Text style={styles.shelfTitle}>{a.shelf_title}</Text>
							<BookCarrousel books={a.manga} onPress={a=>{
								props.navigation.navigate('Book', a);
							}} />
						</View>
					))
			) : (
				<LoadingHeader text={'Loading shelves'} error={loadingError} retry={true} onRetry={()=>refreshUser(shownUser && shownUser.user_id, shownUser.username, true)} />
			)}
			{stats && stats.books==0 && !shownUser.private ? (
				<View style={{ marginTop: 30 }}>
					<FontAwesome5 solid name="sad-tear" style={[styles.loadingText, { fontSize: 45 }]} />
					<Text style={styles.loadingText}>User has no manga</Text>
				</View>
			) : null}
			{reviews && reviews.length>0 ? (
				<View>
					<Text style={styles.shelfTitle}>User Reviews</Text>
					<ReviewCarrousel reviews={reviews} />
				</View>
			) : null}
			<View style={{ height: 30 }} />
		</View>
	)

	return <View style={StyleSheet.absoluteFill}>
		{elementHeader ? (
			<HeaderScroll
				headerText={shownUser ? shownUser.username : 'Profile'}
				containerTextShown={false}
				scrollMin={30}
				scrollMax={60}
				refreshControl={
					<RefreshControl refreshing={refreshing} onRefresh={()=>{
						refreshUser(shownUser && shownUser.user_id, shownUser.username, true);
					}} />
				}
			>
				{CONTENTS}
			</HeaderScroll>
		) : (
			<ScrollView style={[StyleSheet.absoluteFill, props.style]} contentContainerStyle={{ padding: 10 }} refreshControl={
				<RefreshControl refreshing={refreshing} onRefresh={()=>{
					refreshUser(shownUser && shownUser.user_id, shownUser.username, true);
				}} />
			}>
				{CONTENTS}		
			</ScrollView>	
		)}
		{sending ? (
			<View style={[StyleSheet.absoluteFill, { position: 'absolute', alignItems: 'center', justifyContent: 'center' }]}>
				<View style={{ width: 150, height: 150, backgroundColor: dark ? '#333333CC' : '#444444CC', alignSelf: 'center', justifyContent: 'center', borderRadius: 9 }}>
					<ActivityIndicator size={'large'} color={'white'} />
					<Text style={{ textAlign: 'center', marginTop: 10, color: 'white', fontSize: 20, fontWeight: 'bold' }}>Loading</Text>
				</View>
			</View>
		) : null}
	</View>
}