import { useTheme } from '@react-navigation/native';
import React from 'react';
import { Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import moment from 'moment';
import ImageFallback from './ImageFallback';
import { addCommas, randomNumber } from '../Util'

export default (props)=>{
	var { user } = props;
	var { colors, dark } = useTheme()
	if(!user) return null;

	const styles = StyleSheet.create({
		container: { 
			backgroundColor: colors.card, 
			borderRadius: 9, 
			paddingHorizontal: 15, 
			paddingVertical: 20,
			marginBottom: 5,
		},
		username: {
			fontSize: 26,
			fontWeight: 'bold',
			color: colors.text,
		},
		avatar_container: {
			borderRadius: 100,
			overflow: 'visible',
			borderWidth: 3,
			backgroundColor: colors.border, 
			borderColor: user.user_type>1 ? colors.primary : colors.border
		},
		avatar: {
			borderRadius: 100,
			width: 80,
			height: 80,
		},
		verified: {
			position: 'absolute',
			right: 0,
			bottom: 0,
			backgroundColor: colors.primary, 
			padding: 5, 
			borderRadius: 100,
		},
		tagline: {
			color: 'gray',
			marginTop: 2,
			paddingRight: 80,
		},
		placeholder: {
			backgroundColor: colors.border,
			width: 150,
			height: 14,
			marginTop: 2,
			borderRadius: 10
		},
		statsContainer: {
			marginTop: 5,
			flexDirection: 'row',
			marginRight: 20,
		},
		stat: {
			fontSize: 16,
			color: colors.text,
			fontWeight: 'bold',
			marginRight: 10,
		},
		statsPlaceholder: {
			width: 180,
			marginRight: 20,
			height: 20,
			marginTop: 5,
			borderRadius: 100,
			backgroundColor: colors.border
		}
	})

	return (
		<View>
			<View style={styles.container}>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					<TouchableOpacity style={styles.avatar_container} onPress={props.picturePress}>
						<ImageFallback style={[styles.avatar]} url={user.user_image} fallbackText={user.username[0].toUpperCase()} />
						{user.user_type>1 ? (
							<View style={styles.verified}>
								<FontAwesome name="check" color="white" size={16} style={{ height: 15, textAlign: 'center' }} />
							</View>
						) : null}
					</TouchableOpacity>
					<View style={{ marginLeft: 15 }}>
						<Text style={styles.username} numberOfLines={1}>{user.username}</Text>
						{user.tagline || user.date_created ? (
							<Text style={styles.tagline} numberOfLines={2}>
								{!user.tagline && user.date_created ? (
									<Text style={{ fontStyle: 'italic' }}>Member since {moment.unix(user.date_created).format('MMM DD, YYYY')}</Text>
								) : user.tagline}
							</Text>
						) : (
							<View style={styles.placeholder} />
						)}
						{props.stats ? (
							<View style={styles.statsContainer}>
								<Text style={styles.stat}>
									{addCommas(props.stats.books, false)} <Text style={{ fontWeight: 'normal' }}>Volumes</Text>
								</Text>
								<Text style={styles.stat}>
									{addCommas(props.stats.series, false)} <Text style={{ fontWeight: 'normal' }}>Series</Text>
								</Text>
							</View>
						) : props.statsPlaceholder ? (
							<View style={styles.statsPlaceholder} />
						) : null}
					</View>
				</View>
			</View>
		</View>
	)
}