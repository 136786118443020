import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import API from '../API';
import { BookItem, Button, HeaderScroll, ImageFallback, Input, LoadingHeader } from '../component';

export default props=>{
	var { colors, dark } = useTheme();
	var inputRef = useRef();
	var [searching, setSearching] = useState(null);
	var [searchInputFocus, setSearchInputFocus] = useState(false);
	var [searchValue, setSearchValue] = useState('');
	var [searchTimeout, setSearchTimeout] = useState(null);
	var [searchError, setSearchError] = useState(null);
	var [resultsUsers, setResultsUsers] = useState(null);
	var [resultsManga, setResultsManga] = useState(null);
	var [showMoreManga, setShowMoreManga] = useState(false);
	var [showMoreUsers, setShowMoreUsers] = useState(false);
	
	var styles = StyleSheet.create({
		searchInput: {
			borderRadius: 100,
			margin: 0,
			backgroundColor: dark ? colors.border : '#E0E0E0',
			height: 40,
			paddingHorizontal: 15
		},
		searchInput: {
			paddingHorizontal: 0,
			backgroundColor: colors.background,
			marginBottom: -5,
			marginTop: 5
		},
		searchInputContainer: {
			borderRadius: 100,
			height: 40
		},
		sectionHeader: {
			fontWeight: 'bold',
			fontSize: 16,
			color: colors.text
		},
		userContainer: {
			backgroundColor: colors.card,
			padding: 10,
			borderRadius: 12,
			marginTop: 5,
			flexDirection: 'row',
			alignItems: 'center'
		},
		userImage: {
			width: 50,
			height: 50,
			borderRadius: 100,
			borderColor: colors.border,
			backgroundColor: colors.card,
			borderWidth: 3,
			marginRight: 10
		},
		username: {
			fontWeight: 'bold',
			color: colors.text,
			fontSize: 20,
			marginTop: -4
		}
	})

	var search = (val=null)=>{
		if(!val) return;
		if(searchTimeout) clearTimeout(searchTimeout);
		if(val.length<3){
			setSearchError(null);
			if(resultsManga && resultsManga.length==0) setResultsManga(null);
			if(resultsUsers && resultsUsers.length==0) setResultsUsers(null);
			return setSearching(false);
		}
		setSearching(true);
		setSearchTimeout(setTimeout(()=>{
			API.searchAll(val).then(res=>{
				setSearching(false);
				if(res.error) return setSearchError(res.message_client);
				if(!res.data) return setSearchError('Unexpected error searching (LCL-SCH-2)');
				
				var { users, manga } = res.data;
				setSearchError(null);
				setResultsUsers(users);
				setResultsManga(manga);
				setShowMoreManga(users.length==0 ? true : false);
				setShowMoreUsers(manga.length==0 ? true : false);
			}).catch(err=>{
				setSearching(false);
				setSearchError('Unexpected error searching (LCL-SCH-1)');
			})
		}, 1000))
	}

	var clearResults = ()=>{
		setResultsUsers(null);
		setResultsManga(null);
	}

	var onSearch = (val)=>{
		setSearchValue(val);
		search(val)
	}
	
	return (
		<HeaderScroll
			headerText='Search'
			containerTextStyle={{ marginBottom: 0 }}
			root
		>
			<Input 
				style={styles.searchInput} 
				inputRef={inputRef} 
				loading={searching} 
				containerStyle={styles.searchInputContainer} 
				icon="search" 
				placeholder={`Search manga or users...`} 
				value={searchValue} 
				onChangeText={onSearch}
				onSubmitEditing={(v)=>{
					search(searchValue);
				}} 
				autoCorrect={false} 
				onFocus={()=>setSearchInputFocus(true)} 
				onBlur={()=>setSearchInputFocus(false)} 
				clearableBlurred={true} 
				onClear={()=>{
					inputRef.current?.focus()
					clearResults();
				}}
			/>
			{(resultsManga===null && resultsUsers===null) || searchError ? (
				<LoadingHeader icon={searchError ? 'exclamation-circle' : 'search'} text={searchError || 'Search Yama'} loading={false} style={{ marginTop: 30 }} />
				) : null}
			{!searchError && resultsManga && resultsUsers && resultsManga.length==0 && resultsUsers.length==0 ? (
				<LoadingHeader icon={'sad-tear'} text={'No results found'} loading={false} style={{ marginTop: 30 }} iconSolid />
			) : null}
			{!searchError && resultsManga && resultsManga.length>0 ? (
				<View style={{ marginTop: 10 }}>
					<Text style={styles.sectionHeader}>Manga</Text>
					{(showMoreManga ? resultsManga : resultsManga.slice(0, 4)).map(a=>(
						<BookItem key={'bk'+a.manga_id} book={a} imageScale={0.9} onPress={()=>{
							props.navigation.push('Book', {
								...a,
								mangaka_name: a.authors ? a.authors[0] : null
							})
						}} />
					))}
					{!showMoreManga && resultsManga.length>3 ? <Button text='Show more manga...' textColor={colors.text} color='transparent' onPress={()=>setShowMoreManga(true)} /> : null}
				</View>
			) : null}
			{!searchError && resultsUsers && resultsUsers.length>0 ? (
				<View style={{ marginTop: 10 }}>
					<Text style={styles.sectionHeader}>Users</Text>
					{(showMoreUsers ? resultsUsers : resultsUsers.slice(0, 4)).map(a=>(
						<TouchableOpacity key={'usr-'+a.user_id} onPress={()=>{
							props.navigation.push('Profile', {
								user_id: a.user_id,
								username: a.username
							})
						}}>
							<View style={styles.userContainer}>
								<ImageFallback style={styles.userImage} url={a.image} fallbackText={a.username[0].toUpperCase()} fallbackTextStyle={{ fontSize: 24, color: colors.border }} />
								<Text style={styles.username} numberOfLines={1}>{a.username}</Text>
							</View>
						</TouchableOpacity>
					))}
					{!showMoreUsers && resultsUsers.length>3 ? <Button text='Show more users...' textColor={colors.text} color='transparent' onPress={()=>setShowMoreUsers(true)} /> : null}
				</View>
			) : null}
			{resultsManga || resultsUsers ? (
				<View style={{ height: 50 }} />
			) : null}
		</HeaderScroll>
	)
}