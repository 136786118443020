import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert, Image, Dimensions, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { HeaderScroll, LoadingCover, LoadingHeader } from '../../component';
import API from '../../API';

var ChangeCover = props=>{
	var { colors, dark } = useTheme();
	var { manga_name, isbn } = props.route.params;
	var [images, setImages] = useState(null);
	var [changing, setChanging] = useState(false);

	var { height, width } = Dimensions.get('window');

	
	useEffect(()=>{
		API.getCoverImages(isbn).then(res=>{
			if(res.error){
				Alert.alert('Error', res.message_client);
				return props.navigation.goBack();
			}
			
			if(res.data.length==0){
				Alert.alert('Error', 'There\'s no images for this volume!');
				return props.navigation.goBack();
			}else{
				setImages(res.data);
			}
		}).catch(err=>{
			Alert.alert('Error', 'Error loading cover images.');
			return props.navigation.goBack();
		})
	}, []);
	
	var styles = StyleSheet.create({
		image: {
			width: (width/2)-20,
			margin: 5,
			height: ((width/2)-20)*1.5,
			borderRadius: 9
		},
		metadata: {
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: 20,
			color: colors.text,
		}
	});

	var changeCover = (url)=>{
		setChanging(true);

		API.changeCover(isbn, url).then(res=>{
			setChanging(false);
			if(res.error) return Alert.alert('Error', res.message_client);
			Alert.alert('Success', 'Cover has been changed! It might take some time updating the CDN cache.')
			props.navigation.goBack();
		}).catch(err=>{
			setChanging(false);
			Alert.alert('Error', 'Error loading cover images.');
		});
	}

	return (
		<View style={StyleSheet.absoluteFill}>
			<HeaderScroll
				headerText={manga_name || 'Change cover'}
			>
				{images ? (
					<View style={{ flex: 1, flexDirection: 'row' }}>
						{images.sort((b,a)=>(a.width*a.height)-(b.width*b.height)).map((a, i)=>(
							<TouchableOpacity key={`image-${i}`} onPress={()=>changeCover(a.url)}>
								<Image source={{ uri: a.url }} style={styles.image} />
								<Text style={styles.metadata}>{a.width}x{a.height}</Text>
							</TouchableOpacity>
						))}
					</View>
				) : (
					<LoadingHeader text='Loading images...' />
				)}
			</HeaderScroll>
			<LoadingCover loading={changing} />
		</View>
	)
}

export default ChangeCover;